import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
// import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useMediaQuery } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import {
	PaymentDialogComponent,
	SignatureDialog,
} from 'components/Dialog/PaymentDialogComponent';
import { BillFooter } from 'components/Footer/BillFooter';
import { OrderItemList } from 'components/Order/OrderItemList';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CreditCardType,
	GetOrderItemDocument,
	GetAllOrderItemDocument,
	OrderItemStatus,
	OrderStatus,
	PaymentClass,
	useCreateOnlinePaymentMutation,
	useCreatePaymentByQuantityMutation,
	useGetBillSettlementOrderItemLazyQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetDailyMenuCategoryQuery,
	useGetDefaultPaymentQuery,
	useGetOrderQuery,
	useGetOutletNameQuery,
	useGetReceiptPrinterLazyQuery,
	OrderDiscountStatus,
	useGetDiscountQuery,
	OrderItemDiscountStatus,
	usePostPrintDataMutation,
	useGetRoundingPolicyQuery,
	useGetStaffLazyQuery,
	useGetOfficerLazyQuery,
	useRefreshTableSubscription,
	GetTableDocument,
	useGetTableQuery,
	useGetTaxSchemeDetailQuery,
	useGetUsersByAccountAndSoftwareQuery,
	CommonStatus,
	useGetMenuXInhouseListQuery,
	useGetMenuXDebtorListQuery,
	ReceiptConnectType,
	useGetReceiptPrinterQuery,
} from 'generated/graphql';
import { amtNumStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import * as htmlToImage from 'html-to-image';
import React, {
	Reducer,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
	BillReceiptDisplay,
	printReceipt,
	printReceiptText,
} from './BillReceipt/BillReceiptFunctions';
import './billSettlement.scss';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';
import { WSclient } from 'POSClient';
import { printBillReceiptUSBPOS } from '../POSPrinter/BillReceipt/posBillReceiptPrinterUSB';
import { posBillReceiptPrinterNetwork } from '../POSPrinter/BillReceipt/posBillReceiptPrinterNetwork';

let paymentUrl;
switch (process.env.REACT_APP_ENV) {
	case 'swan':
		paymentUrl = `https://swan.menux.asia`;
		break;
	case 'swanuat':
		paymentUrl = `https://swan-uat.menux.asia`;
		break;
	case 'promenade':
		paymentUrl = `https://promenade.menux.asia`;
		break;
	case 'promenade-staging':
		paymentUrl = `https://promenade-uat.menux.asia`;
		break;
	case 'imperial':
		paymentUrl = `https://imperial.menux.asia`;
		break;
	case 'imperial-staging':
		paymentUrl = `https://imperial-uat.menux.asia`;
		break;
	case 'local':
		paymentUrl = `http://localhost:3250`;
		break;
	case 'dev':
		paymentUrl = `https://posx-dev-outlet.ifca.io`;
		break;
	case 'uat':
		paymentUrl = `https://menux-uat-outlet.menux.asia`;
		break;
	default:
		paymentUrl = `https://outlet.menux.asia`;
		break;
}

interface PaymentItemProps {
	key?: typeof String;
	receivedAmount: any;
	cardType: String;
	cardNo: Number;
	referenceNo: String;
	remark: String;
	patron: any;
}

interface FooterProps {
	discount: number;
	subtotal: number;
	serviceCharge: number;
	tax: number;
	total: number;
	rounding: number;
	totalPayment: number;
	billLedgerSubtotal: number;
}

interface FooterAction {
	type: string;
	info?: any;
	maxQuantity?: number;
}

export const BillSettlement = () => {
	useEffect(() => {
		console.log('BillSettlement Outlet App');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { [`tableID`]: tableID, outletID, billID } = useParams<
		Record<string, any>
	>();

	const { globalState }: any = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [openPayment, setOpenPayment] = useState<boolean>(false);
	const [openSignature, setOpenSignature] = useState<boolean>(false);
	const [openChange, setOpenChange] = useState<boolean>(false);

	const [billItem, setBillItem] = useState<Set<any>>(new Set());

	const [selectedOrderItems, setSelectedOrderItems] = useState({});
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [negOrderItem, setNegOrderItem] = useState({});
	const [radioError, setRadioError] = useState(false);
	const [mode, setMode] = useState('payment');

	const [minVal, setMinVal] = useState(0); //minimum payment
	const [changeAmount, setChangeAmount] = useState(0); //change amount
	const [receivedAmount, setReceivedAmount] = useState(0); //use to reroute change dialog
	const [billInput, setBillInput] = useState({} as any);
	const [paymentInput, setPaymentInput] = useState({} as any);

	const [selectedRoom, setSelectedRoom] = useState({} as any);
	const [selectedNonGuest, setSelectedNonGuest] = useState({} as any);
	const [selectedFolio, setSelectedFolio] = useState({} as any);
	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);
	const [selectedDebtor, setSelectedDebtor] = useState({} as any);
	const [selectedCredit, setSelectedCredit] = useState({} as any);
	const [defaultRoom, setDefaultRoom] = useState({} as any);
	const [defaultNonGuest, setDefaultNonGuest] = useState({} as any);
	const [defaultFolio, setDefaultFolio] = useState({} as any);
	const [defaultDebtor, setDefaultDebtor] = useState({} as any);
	const [defaultOfficer, setDefaultOfficer] = useState({} as any);
	const [defaultStaff, setDefaultStaff] = useState({} as any);
	const [receiptDataURL, setReceiptDataURL] = useState({} as any);
	const [baseImage, setBaseImage] = useState({} as any);
	const [officer, setOfficer] = useState({} as any);
	const [staff, setStaff] = useState({} as any);
	const [discountType, setDiscountType] = useState(false);
	const [qrEInvoiceImage, setQREInvoiceImage] = useState(null);
	const [outletImage, setOutletImage] = useState(null);
	const [ip, setIp]: any = useState(null); // Printer IP
	const [port, setPort]: any = useState(null); // Printer TCP port
	const [printerName, setPrinterName]: any = useState(null); // Printer TCP port

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});

			var xhr = new XMLHttpRequest();
			xhr.open('GET', `${getOutlet[0]?.logo?.fileURL}`);
			xhr.responseType = 'blob';
			xhr.send();

			var reader = new FileReader();
			xhr.addEventListener('load', function() {
				reader.readAsDataURL(xhr.response);
				reader.addEventListener('loadend', function() {
					setBaseImage(reader.result);
				});
			});
		},
	});

	const {
		loading: getDiscountLoading,
		called: getDiscountCalled,
		data: { getDiscount } = { getDiscount: [] },
	} = useGetDiscountQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const {
		called: orderCalled,
		loading: orderLoading,
		error: orderError,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			tableID: tableID,
			outletID,
			status: OrderStatus.Open,
		},
		onCompleted: ({ getOrder }) => {
			loadOfficer({
				variables: { ID: getOrder[0]?.patronAccountID },
			});
			loadStaff({
				variables: { ID: getOrder[0]?.patronAccountID },
			});
			let orderDiscountActive = getOrder[0]?.orderDiscount?.filter(
				x => x?.status === 'ACTIVE',
			)[0];
			let orderDiscountTypeENTOC =
				orderDiscountActive?.discountTypeInfo === 'OFFICER_CHECK' ||
				orderDiscountActive?.discountTypeInfo === 'ENTERTAINMENT'
					? true
					: false;
			setDiscountType(orderDiscountTypeENTOC);
		},
	});

	const {
		called: paymentCalled,
		loading: paymentLoading,
		error: paymentError,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'paymentClass' },
	});

	//getBillSettlementOrderItem
	const [itemArray, setItemArray] = useState([]);

	const [
		loadOrderItem,
		{
			data: { getBillSettlementOrderItem } = { getBillSettlementOrderItem: [] },
			loading: getBillSettlementOrderItemLoading,
		},
	] = useGetBillSettlementOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getBillSettlementOrderItem }) => {
			let unpaidOrderItem = getBillSettlementOrderItem?.filter(
				item => item?.billID === null,
			);

			let temp = {};
			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;
							let optionData;
							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});

			setNegOrderItem({ ...temp });

			handleCheckboxAll(
				true,
				unpaidOrderItem,
				// unpaidOrderItem?.filter(v => {
				// 	return v?.quantity - (temp[v?.ID] ?? 0) > 0;
				// }),
				temp,
			);

			setItemArray(
				unpaidOrderItem?.filter(
					list2 => list2.quantity - (temp?.[list2?.ID]?.quantity ?? 0) > 0,
				),
			);
		},
	});

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
		called: getRoundingPolicyCalled,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const {
		data: { getTaxSchemeDetail } = {
			getTaxSchemeDetail: null,
		},
		loading: getTaxSchemeDetailLoading,
	} = useGetTaxSchemeDetailQuery({
		fetchPolicy: 'no-cache',
		variables: {
			accountID: user?.accountID,
			taxSchemeID: getOutlet[0]?.taxSchemeID,
			orderID: getOrder[0]?.ID,
		},
	});

	const localPrinter = () => {
		let temp = document.getElementById('share-receipt');

		htmlToImage
			.toPng(temp, {
				style: { background: '#ffffff' },
				cacheBust: true,
			})
			.then(x => {
				const win = window.open('', '');
				win.document.write('<html><head>');
				win.document.write('</head><body >');
				win.document.write('<img id="print-image-element" src="' + x + '"/>');
				win.document.write(
					'<script>var img = document.getElementById("print-image-element"); img.addEventListener("load",function(){ window.focus(); window.print(); window.document.close(); window.close(); }); </script>',
				);
				win.document.write('</body></html>');
				win.document?.close();
				setTimeout(() => {
					win.window.print();
				}, 100);
			})
			.catch(error => {
				console.error('Printing failed', error);
			});
	};

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const {
		data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		loading: receiptPrinterLoading,
		called: receiptPrinterCalled,
	} = useGetReceiptPrinterQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
		onCompleted: ({ getReceiptPrinter }) => {
			setIp(getReceiptPrinter[0]?.deviceIP);
			setPort(getReceiptPrinter[0]?.devicePort);
			setPrinterName(getReceiptPrinter[0]?.name);
		},
	});

	const [
		createPaymentByQuantity,
		{ loading: paymentQuantityLoading },
	] = useCreatePaymentByQuantityMutation({
		onError: error => {
			console.log('ERROR', error?.message);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
		},
		onCompleted: ({ paymentByQuantity }) => {
			history.push(
				`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${selectedOrder?.ID}/bill-settlement/${paymentByQuantity?.ID}`,
			);
			setRadioError(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		createOnlinePayment,
		{ loading: createOnlinePaymentLoading },
	] = useCreateOnlinePaymentMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ createOnlinePayment }) => {},
	});

	const initialState: FooterProps = {
		discount: 0,
		subtotal: 0,
		serviceCharge: 0,
		tax: 0,
		total: 0,
		rounding: 0,
		totalPayment: 0,
		billLedgerSubtotal: 0,
	};

	const reducer: Reducer<FooterProps, FooterAction> = (state, action) => {
		const subtotalAmount = action?.info?.totalAmount / action?.maxQuantity;

		const serviceChargeAmount =
			action?.info?.totalServiceCharge / action?.maxQuantity;

		const taxAmount = action?.info?.totalTax / action?.maxQuantity;

		const totalAmount = action?.info?.totalAmount / action?.maxQuantity;

		switch (action.type) {
			case 'remove':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.minus(subtotalAmount)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.minus(serviceChargeAmount)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.minus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.minus(totalAmount)
						.minus(serviceChargeAmount)
						.minus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),
				};

			case 'add':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(subtotalAmount)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(serviceChargeAmount)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(totalAmount)
						.plus(serviceChargeAmount)
						.plus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),
				};
			case 'all':
				return {
					...state,
					discount: new BigNumber(state?.discount)
						.plus(action?.info?.discount)
						.decimalPlaces(2)
						.toNumber(),
					subtotal: new BigNumber(state?.subtotal)
						.plus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),

					rounding: new BigNumber(state?.rounding)
						.plus(action?.info?.rounding)
						.decimalPlaces(2)
						.toNumber(),

					totalPayment: new BigNumber(state?.totalPayment)
						.plus(action?.info?.totalPayment)
						.decimalPlaces(2)
						.toNumber(),

					billLedgerSubtotal: new BigNumber(state?.billLedgerSubtotal)
						.plus(action?.info?.billLedgerSubtotal)
						.decimalPlaces(2)
						.toNumber(),
				};

			//Handling for when a single item is unchecked

			case 'addItem':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),
					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			case 'removeItem':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.minus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),
					serviceCharge: new BigNumber(state?.serviceCharge)
						.minus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.minus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.minus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			default:
				return initialState;
		}
	};

	const [footerState, dispatch] = useReducer(reducer, initialState);

	//yupppp
	const yupSchema = yup.object().shape({
		// receivedAmount: yup
		// 	.number()
		// 	// .integer()
		// 	.min(footerState?.total, `Amount cannot be less than bill amount`)
		// 	.typeError('Amount cannot be empty')
		// 	.nullable(),
	});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
		getValues,
		reset,
	} = useForm<PaymentItemProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		if (!orderLoading) {
			if (getOrder?.length !== 0) {
				setSelectedOrder(getOrder[0]);
				loadOrderItem({
					variables: {
						orderID: getOrder[0]?.ID,
					},
				});
			} else if (getOrder?.length === 0) {
				history.push({
					pathname: `/menu/outlet-app/${outletID}/table/grid`,
				});
			}
		}
	}, [orderLoading]);

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== selectedOrder?.ID) {
			setSelectedOrder(getOrder?.filter(order => order?.ID === obj?.ID)[0]);
			handleCheckboxAll(false);
			loadOrderItem({
				variables: {
					orderID: obj?.ID,
				},
			});
		}
	};

	// const handleCheckBox = (e: any, orderItem, maxQuantity: number) => {
	// 	let tempSet = new Set();

	// 	tempSet = billItem;

	// 	if (e.target.checked) {
	// 		// orderItem.checkedStatus = true;
	// 		tempSet.add(orderItem?.ID);
	// 		selectedOrderItems[orderItem?.ID] = {
	// 			showQuantity: true,
	// 			paymentQuantity: maxQuantity,
	// 			maxQuantity: maxQuantity,
	// 		};
	// 	}

	// 	//Calculation
	// 	let subtotalFooter, serviceChargeFooter, taxFooter, totalFooter, ratio;

	// 	if (e.target.checked) {
	// 		subtotalFooter = Math.abs(
	// 			orderItem?.totalInfo?.totalAmount *
	// 				(maxQuantity / orderItem?.quantity) ||
	// 				orderItem?.totalInfo?.totalAmount,
	// 		);

	// 		serviceChargeFooter = Math.abs(
	// 			orderItem?.totalInfo?.totalServiceCharge *
	// 				(maxQuantity / orderItem?.quantity) ||
	// 				orderItem?.totalInfo?.totalServiceCharge,
	// 		);

	// 		taxFooter = Math.abs(
	// 			orderItem?.totalInfo?.totalTax *
	// 				(maxQuantity / orderItem?.quantity) ||
	// 				orderItem?.totalInfo?.totalTax,
	// 		);

	// 		totalFooter = subtotalFooter + serviceChargeFooter + taxFooter;
	// 		dispatch({
	// 			type: 'addItem',
	// 			info: {
	// 				subtotal: subtotalFooter,
	// 				serviceCharge: serviceChargeFooter,
	// 				tax: taxFooter,
	// 				total: totalFooter,
	// 			},
	// 		});
	// 		setBillItem(tempSet);
	// 	} else {
	// 		subtotalFooter = Math.abs(
	// 			orderItem?.totalInfo?.totalAmount *
	// 				(selectedOrderItems[orderItem?.ID].paymentQuantity /
	// 					orderItem?.quantity) ||
	// 				orderItem?.totalInfo?.totalAmount,
	// 		);

	// 		serviceChargeFooter = Math.abs(
	// 			orderItem?.totalInfo?.totalServiceCharge *
	// 				(selectedOrderItems[orderItem?.ID].paymentQuantity /
	// 					orderItem?.quantity) ||
	// 				orderItem?.totalInfo?.totalServiceCharge,
	// 		);

	// 		taxFooter = Math.abs(
	// 			orderItem?.totalInfo?.totalTax *
	// 				(selectedOrderItems[orderItem?.ID].paymentQuantity /
	// 					orderItem?.quantity) ||
	// 				orderItem?.totalInfo?.totalTax,
	// 		);

	// 		totalFooter = subtotalFooter + serviceChargeFooter + taxFooter;

	// 		dispatch({
	// 			type: 'removeItem',
	// 			info: {
	// 				subtotal: subtotalFooter,
	// 				serviceCharge: serviceChargeFooter,
	// 				tax: taxFooter,
	// 				total: totalFooter,
	// 				// ratio: ratio,
	// 			},
	// 		});

	// 		tempSet.delete(orderItem?.ID);
	// 		selectedOrderItems[orderItem?.ID] = {
	// 			showQuantity: false,
	// 		};
	// 	}
	// 	setBillItem(tempSet);
	// };

	//Check if voided order item exists

	const checkVoidedItem = (stateVariable, directVariable, ID) => {
		if (stateVariable[ID]) {
			return stateVariable[ID];
		} else {
			return directVariable[ID];
		}
	};

	const handleCheckboxAll = (e: any, nArr?: any[], tempVariable?: any) => {
		//calculations
		let subtotalFooter = 0,
			baseAmount = 0,
			serviceChargeFooter = 0,
			serviceCharge = 0,
			oidSC = 0,
			odiSC = 0,
			taxFooter = 0,
			tax = 0,
			oidTax = 0,
			odiTax = 0,
			totalFooter = 0,
			discount = 0,
			oid = 0,
			odi = 0,
			roundingAmount = 0,
			totalOptItm = 0,
			discOptItm = 0,
			optTaxAmount = 0,
			optServiceCharge = 0,
			discOptTaxAmount = 0,
			discOptServiceCharge = 0;
		let tempSet = billItem;

		function generateRoundingAmount(
			totalFooter: number,
			roundingPolicyAmount: number,
		): number {
			const tempCents = totalFooter - Math.floor(totalFooter); // 16.24 - 16.00 = 0.24

			const cents = Math.round((tempCents + Number.EPSILON) * 100) / 100; // 0.24

			const roundingMulti = Math.round(
				(cents * 100) / (roundingPolicyAmount! * 100), // (0.24 * 100) / (0.05 * 100) = 24 / 5 = Math.round(4.8) = 5
			);

			const roundedAmount =
				getRoundingPolicy[0]?.inUse === true
					? Math.floor(totalFooter) + roundingPolicyAmount! * roundingMulti
					: totalFooter; // 16.00 + 0.05 * 5 = 16.

			const roundingAmount = (totalFooter - roundedAmount) * -1; // 16.24 - 16.25 = 0.01

			return Number(roundingAmount.toFixed(2));
		}

		if (e?.target?.checked || e === true) {
			nArr?.map(v => {
				//Checkbox logic
				selectedOrderItems[v.ID] = {
					showQuantity: true,
					paymentQuantity:
						v?.quantity -
						(negOrderItem?.[v?.ID]?.quantity ??
							tempVariable[v?.ID]?.quantity ??
							0),
					maxQuantity:
						v?.quantity -
						(negOrderItem?.[v?.ID]?.quantity ??
							tempVariable[v?.ID]?.quantity ??
							0),
				};

				//Calculations

				//overall option item important field to sum up
				const sumOptionItemOverallField = (
					items,
				): {
					basePrice: number;
					taxAmount: number;
					discountBaseAmount: number;
					itemDiscountTaxAmount: number;
					discountItemTaxAmount: number;
				} => {
					let totalBasePriceOpt = 0;
					let totalTaxAmountOpt = 0;
					let totalDiscBaseAmountOpt = 0;
					let totalItemDiscTaxAmountOpt = 0;
					let totalDiscItemTaxAmountOpt = 0;
					for (const item of items) {
						if (item.orderItemOption) {
							for (const option of item.orderItemOption) {
								if (option.orderItemOptionItem) {
									for (const optionItem of option.orderItemOptionItem) {
										let totalTax = 0;
										optionItem?.orderItemOptionItemTax?.map(x => {
											totalTax += x?.taxAmount;
										});

										let totalOptItemOIDTax = 0;
										optionItem?.orderItemOptionItemDiscountTax?.map(x => {
											totalOptItemOIDTax += x?.taxAmount;
										});

										let totalOptItemODITax = 0;
										optionItem?.orderDiscountItemOptionTax?.map(v => {
											totalOptItemODITax += v?.taxAmount;
										});

										totalBasePriceOpt += optionItem.basePrice;
										totalTaxAmountOpt += totalTax;
										totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
										totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
										totalDiscItemTaxAmountOpt += totalOptItemODITax;
									}
								}
							}
						}
					}

					return {
						basePrice: totalBasePriceOpt,
						taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
						discountBaseAmount: totalDiscBaseAmountOpt,
						itemDiscountTaxAmount: parseFloat(
							totalItemDiscTaxAmountOpt.toFixed(2),
						),
						discountItemTaxAmount: parseFloat(
							totalDiscItemTaxAmountOpt.toFixed(2),
						),
					};
				};

				const totalOptItmOverallAmount = sumOptionItemOverallField(nArr);

				optTaxAmount = totalOptItmOverallAmount?.taxAmount;

				discOptTaxAmount =
					totalOptItmOverallAmount?.itemDiscountTaxAmount +
					totalOptItmOverallAmount?.discountItemTaxAmount;

				totalOptItm =
					totalOptItmOverallAmount?.basePrice +
					totalOptItmOverallAmount?.taxAmount;

				discOptItm =
					totalOptItmOverallAmount?.discountBaseAmount +
					totalOptItmOverallAmount?.itemDiscountTaxAmount +
					totalOptItmOverallAmount?.discountItemTaxAmount;

				//Discount
				const orderItemDiscount = v?.orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];
				oid += orderItemDiscount?.baseAmount || 0;
				odi += v?.orderDiscountItem?.baseAmount || 0;

				//Subtotal
				// subtotalFooter += v?.totalInfo?.totalAmount;
				// console.log(v, 'V');

				baseAmount += v?.amount;

				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalAmount,

				//Tax footer
				// taxFooter += v?.totalInfo?.totalTax;

				let totalTax = 0;
				v?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				let totalOIDTax = 0;
				orderItemDiscount?.orderItemDiscountTax?.map(x => {
					totalOIDTax += x?.taxAmount;
				});

				let totalODITax = 0;
				v?.orderDiscountItem?.orderDiscountItemTax?.map(x => {
					totalODITax += x?.taxAmount;
				});

				tax += totalTax;
				oidTax += totalOIDTax || 0;
				odiTax += totalODITax || 0;
				taxFooter = tax + optTaxAmount - oidTax - odiTax - discOptTaxAmount;

				discount =
					oid > 0
						? oid + oidTax + discOptItm
						: odi + odiTax + odiSC + discOptItm;
				subtotalFooter = baseAmount + tax + totalOptItm;

				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalTax,
				tempSet.add(v.ID);
			});
			totalFooter = subtotalFooter - discount;
			roundingAmount =
				getRoundingPolicy[0]?.inUse === true
					? generateRoundingAmount(
							totalFooter,
							getRoundingPolicy[0]?.smallestDenomination,
					  )
					: 0;

			setBillItem(new Set(Array.from(tempSet)));

			dispatch({
				type: 'all',
				info: {
					discount: discount,
					subtotal: subtotalFooter,
					tax: taxFooter,
					total: totalFooter + roundingAmount,
					rounding: roundingAmount,
					totalPayment: totalFooter,
					billLedgerSubtotal: baseAmount,
				},
			});
		} else {
			setSelectedOrderItems({});
			setBillItem(new Set());

			dispatch({
				type: 'reset',
			});
		}
	};

	//Signature Canvas

	//discount
	const orderDiscountAmt = selectedOrder?.totalDiscountAmount;

	const orderItemQty = selectedOrder?.orderItem?.reduce(
		(acc, currVal) => acc + currVal.quantity,
		0,
	);

	const {
		loading: outletDailyMenuCategoryLoading,
		called: outletDailyMenuCategoryCalled,
		data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
	} = useGetDailyMenuCategoryQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			mealPeriodID: currentMealPeriod?.ID,
		},
	});

	const handleQuantityChange = (
		mode: string,
		ID: any,
		maxQuantity: number, //max quantity after deducting negative items
		totalInfo: any,
	) => {
		let tempSet = new Set();
		tempSet = billItem;

		if (mode === 'add') {
			if (selectedOrderItems[ID]?.paymentQuantity < maxQuantity) {
				dispatch({
					type: 'add',
					info: totalInfo,
					maxQuantity: maxQuantity,
				});
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						paymentQuantity: selectedOrderItems[ID].paymentQuantity + 1,
						maxQuantity: maxQuantity,
					},
				});
			}
		} else {
			if (selectedOrderItems[ID].paymentQuantity === 0) {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: false,
						paymentQuantity: 0,
						maxQuantity: maxQuantity,
					},
				});
				tempSet.delete(ID);
			} else {
				dispatch({
					type: 'remove',
					info: totalInfo,
					maxQuantity: maxQuantity,
				});
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						paymentQuantity: selectedOrderItems[ID].paymentQuantity - 1,
						maxQuantity: maxQuantity,
					},
				});
			}
			setBillItem(tempSet);
		}
	};

	//Credit card input
	let cardType: any = watch('cardType');
	let cardNo: any = watch('cardReference');

	//voucher input
	let referenceNo: any = watch('referenceNo');

	let remark: any = watch('remark');

	//Calculations for payment input
	// let receivedAmount: any = parseFloat(
	// 	watch('receivedAmount') || footerState?.total,
	// );
	// let changeAmountCalc = new BigNumber(receivedAmount - footerState?.total)
	// 	.decimalPlaces(2)
	// 	.toNumber();
	// let changeAmount = changeAmountCalc >= 0 ? changeAmountCalc : 0;
	//e.g. total is 4.67, receivedAmt is rounded to 4.65, changeAmt should be zero

	const {
		loading: MenuXInhouseListLoading,
		data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
	} = useGetMenuXInhouseListQuery({
		fetchPolicy: 'network-only',
		variables: {
			hotelID: getOutlet[0]?.outletChannel?.hotelID,
			outletID: outletID,
		},
	});

	const {
		loading: MenuXDebtorListLoading,
		data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
	} = useGetMenuXDebtorListQuery({
		fetchPolicy: 'network-only',
		variables: {
			hotelID: getOutlet[0]?.outletChannel?.hotelID,
			outletID: outletID,
		},
	});

	const [hotelGuestInfo, setHotelGuestInfo] = useState({} as any);

	const HotelGuestInfo = (paymentClass, registrationID) => {
		if (
			paymentClass === PaymentClass?.Room ||
			paymentClass === PaymentClass?.NonGuest ||
			paymentClass === PaymentClass?.SuspendFolio
		) {
			return getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === registrationID,
			)[0];
		} else if (paymentClass === PaymentClass?.Debtor) {
			return getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
				x => x?.RegistrationID === registrationID,
			)[0];
		} else if (paymentClass === PaymentClass?.Credit) {
			return cardType;
		}
	};

	useEffect(() => {
		if (selectedRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedRoom);
		} else if (selectedNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedNonGuest);
		} else if (selectedDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedDebtor);
		} else if (selectedFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedFolio);
		} else if (defaultRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultRoom);
		} else if (defaultNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultNonGuest);
		} else if (defaultDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultDebtor);
		} else if (defaultFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultFolio);
		} else if (selectedCredit?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedCredit);
		}
	}, [
		selectedRoom,
		defaultRoom,
		selectedNonGuest,
		defaultNonGuest,
		defaultDebtor,
		selectedDebtor,
		defaultFolio,
		selectedFolio,
		selectedCredit,
	]);

	const selectedPaymentClass = () => {
		const name = getDefaultPayment?.filter(
			x => x?.ID === watch('defaultPaymentID'),
		)[0]?.paymentClass;
		return name;
	};
	//Payment by quantity input

	const getSelectedOrderItemIDs = () => {
		let temp = Object.keys(selectedOrderItems).filter(
			v => selectedOrderItems[v]?.showQuantity === true,
		);

		return temp?.map(x => {
			return {
				orderItemID: x,
				paymentQuantity: selectedOrderItems[x].paymentQuantity,
			};
		});
	};

	const creditCardEnum = data => {
		let credClass;
		if (data === 'American Express Card') {
			credClass = CreditCardType.AmericanExpressCard;
		} else if (data === 'Diners Card') {
			credClass = CreditCardType.DinersCard;
		} else if (data === 'JCB Card') {
			credClass = CreditCardType.JcbCard;
		} else if (data === 'Master Card') {
			credClass = CreditCardType.Mastercard;
		} else if (data === 'UNION PAY') {
			credClass = CreditCardType.UnionpayCard;
		} else if (data === 'Visa Card') {
			credClass = CreditCardType.VisaCard;
		} else if (data === 'Debit Card') {
			credClass = CreditCardType.DebitCard;
		} else if (data === 'E-Wallet') {
			credClass = CreditCardType.Ewallet;
		}
		return credClass;
	};

	const saveRounding = (defaultPayment, v, index, lastIndex) => {
		let roundingAmount;
		const gotCash =
			defaultPayment?.filter(x => x?.paymentClass === PaymentClass?.Cash)
				?.length > 0;

		if (gotCash && v?.paymentClass === PaymentClass.Cash) {
			roundingAmount = footerState?.rounding;
		}

		if (gotCash === false && index === lastIndex) {
			roundingAmount = footerState?.rounding;
		}

		return roundingAmount;
	};

	const [lastArray, setLastArray] = useState([]);

	let paymentInputArr = [];
	const onSubmit = async (data: any, files?) => {
		const ref = selectedOrder?.docNo;
		let billInput;
		let totalReceiveAmount = 0;

		console.log('data', data);

		const lastIndex =
			data?.defaultPayment?.filter(x => x?.selected === true)?.length - 1;

		data?.defaultPayment
			?.filter(x => x?.selected === true)
			?.map((v, index) => {
				billInput = {
					orderID: selectedOrder?.ID,
					outletID: outletID,
					...(v?.paymentClass === PaymentClass.Room ||
					v?.paymentClass === PaymentClass.Member ||
					v?.paymentClass === PaymentClass.NonGuest ||
					v?.paymentClass === PaymentClass.Debtor ||
					v?.paymentClass === PaymentClass.SuspendFolio
						? { file: files }
						: null),
				};

				let paymentInput = {
					outletID: outletID,
					amount:
						v?.paymentClass === PaymentClass?.Cash
							? receivedAmount
							: v?.amountPayment,
					creditCardType:
						v?.paymentClass === PaymentClass?.Credit
							? cardType?.GuestType !== null &&
							  cardType?.GuestType !== undefined
								? creditCardEnum(cardType?.GuestType)
								: cardType
							: null,
					cardNo: v?.cardRef,
					// paidAmount: v?.amountPayment,
					changeAmount:
						v?.paymentClass === PaymentClass?.Cash
							? changeAmount >= 0
								? Math.round((Math.abs(changeAmount) + Number.EPSILON) * 100) /
								  100
								: 0
							: 0,
					receivedAmount:
						v?.paymentClass === PaymentClass?.Cash
							? receivedAmount !== 0
								? receivedAmount
								: v?.amountPayment
							: 0,
					roundingAmount:
						saveRounding(data?.defaultPayment, v, index, lastIndex) ?? 0,
					defaultPaymentID: v?.defaultPaymentID,
					paymentClass: v?.paymentClass,
					patronAccountID:
						selectedOfficer?.ID !== (null || undefined) ||
						selectedStaff?.ID !== (null || undefined)
							? selectedOfficer?.ID ?? selectedStaff?.ID
							: defaultOfficer?.ID ?? defaultStaff?.ID,
					registrationID:
						v?.paymentClass === PaymentClass?.Room
							? selectedRoom?.RegistrationID ?? defaultRoom?.RegistrationID
							: v?.paymentClass === PaymentClass?.NonGuest
							? selectedNonGuest?.RegistrationID ??
							  defaultNonGuest?.RegistrationID
							: v?.paymentClass === PaymentClass?.SuspendFolio
							? selectedFolio?.RegistrationID ?? defaultFolio?.RegistrationID
							: v?.paymentClass === PaymentClass?.Debtor
							? selectedDebtor?.RegistrationID ?? defaultDebtor?.RegistrationID
							: cardType?.RegistrationID,

					hotelGuestInfo: {
						BookingNo: hotelGuestInfo?.BookingNo,
						FullName: hotelGuestInfo?.GuestName,
						RoomNo: hotelGuestInfo?.RoomNo,
						GuestType: hotelGuestInfo?.GuestType,
						ArrivalDate: hotelGuestInfo?.ArrivalDate,
						DepartureDate: hotelGuestInfo?.DepartureDate,
						DebtorAccount: hotelGuestInfo?.DebtorAccount,
						CompanyName: hotelGuestInfo?.CompanyName,
					},
					referenceNo: v?.referenceNo,
					remark: v?.remark,
					billLedgerInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						RegistrationID:
							selectedRoom?.RegistrationID ??
							selectedNonGuest?.RegistrationID ??
							selectedFolio?.RegistrationID ??
							defaultRoom?.RegistrationID ??
							defaultNonGuest?.RegistrationID ??
							defaultFolio?.RegistrationID,
						OutletCode: getOutlet[0]?.outletCode,
						IncidentalChargeID: '',
						Reason:
							v?.paymentClass === PaymentClass?.Room
								? 'MenuX - Charge To Room'
								: v?.paymentClass === PaymentClass?.NonGuest
								? 'MenuX - Charge To Non Guest'
								: v?.paymentClass === PaymentClass?.SuspendFolio
								? 'MenuX - Charge To Suspend Folio'
								: v?.paymentClass === PaymentClass?.Debtor
								? 'MenuX - Charge To Debtor'
								: v?.paymentClass === PaymentClass?.Credit
								? 'MenuX - Charge To Credit'
								: '',
						Description: `${getOutlet[0]?.outletCode} -  ${ref}`,
						TrxDate: selectedOrder?.businessDate, // new Date(),
						Qty: 1,
						UnitPrice: parseFloat(amtNumStr(v?.amountPayment)),
						TrxAmount: parseFloat(amtNumStr(v?.amountPayment)),
						BaseAmount: parseFloat(amtNumStr(v?.amountPayment)),
						ServiceCharge: parseFloat(amtNumStr(0)),
						TaxAmount: parseFloat(amtNumStr(0)),
						DiscountAmount: 0,
						IsChargeToRoom: true,
						ReferenceNo: ref,
						CreatedBy: user?.ID,
					},
					scheduleBillingInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						BillNo: ref,
						CardNo: v?.cardRef,
						BillDate: selectedOrder?.businessDate, // new Date(),
						BillAmount: v?.amountPayment,
						// BaseAmount: footerState?.subtotal,
						DebtorID:
							v?.paymentClass === PaymentClass?.Credit
								? cardType?.RegistrationID
								: v?.registrationID,
						CreatedBy: user?.ID,
						// RegistrationID:
						// 	defaultDebtor?.RegistrationID ??
						// 	selectedDebtor?.RegistrationID ??
						// 	cardType?.RegistrationID,
					},
				};
				paymentInputArr?.push(paymentInput);
			});

		paymentInputArr?.map(x => {
			totalReceiveAmount += Number(
				x?.paymentClass === PaymentClass.Cash ? x?.receivedAmount : x?.amount,
			);
		});

		console.log('paymentInputArr', paymentInputArr);

		setLastArray(paymentInputArr);

		setBillInput(billInput);
		setPaymentInput(paymentInput);

		if (selectedPaymentClass() === PaymentClass.Online) {
			createOnlinePayment({
				variables: {
					redirectUrl: `${paymentUrl}/menu/outlet-app/bill-settlement/online-payment/redirect`,
					payAmount: footerState?.total,
					detail: 'Detail',
					title: `Online Payment`,
					outletID: outletID,
				},
			}).then(i => {
				if (i?.data?.createOnlinePayment?.code === 'SUCCESS') {
					localStorage.setItem(
						'checkoutId',
						i?.data?.createOnlinePayment?.item?.checkoutId,
					);
					localStorage.setItem(
						'paymentInfo',
						JSON.stringify({
							paymentByQuantityInput: getSelectedOrderItemIDs(),
							billInput: billInput,
							paymentInput: paymentInputArr,
							tableID: tableID,
						}),
					);
					window.location.assign(i?.data?.createOnlinePayment?.item?.url);
					setOpenSnackBar(true);
					setSnackBarMsg(SystemMsgs.gatewayRedirect());
				} else {
					i?.data?.createOnlinePayment?.error?.code !== ''
						? setSnackBarMsg(i.data.createOnlinePayment?.error.message)
						: setSnackBarMsg(SystemMsgs.paymentFailed());
					setOpenSnackBar(true);
				}
			});
		} else {
			createPaymentByQuantity({
				variables: {
					paymentByQuantityInput: getSelectedOrderItemIDs(),
					billInput,
					paymentInput: paymentInputArr,
					// billLedgerInput,
					// scheduleBillingInput,
				},
				refetchQueries: [
					{
						query: GetOrderItemDocument,
						variables: {
							orderID: selectedOrder?.ID,
							status: OrderItemStatus.Confirmed,
						},
					},
					{
						query: GetAllOrderItemDocument,
						variables: { orderID: selectedOrder?.ID },
					},
				],
			});
		}
	};

	const handlePrinting = () => {
		loadReceiptPrinter({
			variables: {
				outletID: outletID,
				isDefault: true,
			},
		});
	};

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getStaff }) => {
			setStaff(getStaff);
		},
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOfficer }) => {
			setOfficer(getOfficer);
		},
	});

	const {
		data: { getTable } = { getTable: [] },
		loading: tableLoading,
		refetch: tableRefetch,
		fetchMore,
	} = useGetTableQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: tableID,
			outletID: outletID,
			orderByAsc: 'sequence',
			isActive: true,
		},
		onCompleted: () => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	//subscription //subscription //subscription
	const {
		loading: refreshTableLoading,
		data: { refreshTable } = { refreshTable: {} as any },
	} = useRefreshTableSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	useEffect(() => {
		if (refreshTable?.ID) {
			fetchMore({
				query: GetTableDocument,
				variables: {
					ID: tableID,
					outletID: outletID,
					orderByAsc: 'sequence',
					isActive: true,
				},
			});
			history.push({ pathname: `/menu/outlet-app/${outletID}/table/grid/` });
		}
	}, [refreshTable?.ID]);

	const [connectedPrinter, setConnectedPrinter] = useState<any | null>(null);
	const [loading, setLoading] = useState(false);

	// Retrieve the printer data from localStorage
	useEffect(() => {
		const storedPrinter = getReceiptPrinter[0]?.deviceInfo;
		if (storedPrinter !== null) {
			connectToPrinter(storedPrinter);
		}
	}, [getReceiptPrinter[0]?.ID]);

	const connectToPrinter = async (printerData: any) => {
		try {
			setLoading(true);

			// Get the list of devices that match the vendorId and productId
			const devices = await navigator.usb.getDevices();
			const matchedDevice = devices.find(
				device =>
					device.vendorId === printerData.vendorId &&
					device.productId === printerData.productId,
			);

			if (matchedDevice) {
				console.log('Printer found:', matchedDevice);

				// Open the device and select configuration
				await matchedDevice.open();
				await matchedDevice.selectConfiguration(1);
				console.log('Configuration selected:', matchedDevice.configuration);

				if (
					!matchedDevice.configuration ||
					!matchedDevice.configuration.interfaces
				) {
					throw new Error('No interfaces found for this configuration');
				}

				const interfaces = matchedDevice.configuration.interfaces;
				console.log('Interfaces:', interfaces);

				// Claim the first interface
				await matchedDevice.claimInterface(0);
				console.log('Interface claimed');

				// Store the device object for later use
				setConnectedPrinter(matchedDevice);
				console.log('Printer connected successfully!');
			} else {
				console.error('Printer not found in the list of connected devices');
				console.error('No matching printer found');
			}
		} catch (err) {
			console.error('Error connecting to the printer:', err.message);
			console.error('Failed to connect to the printer');
		} finally {
			setLoading(false);
		}
	};

	const sendReceiptToPrinter = async () => {
		if (getReceiptPrinter[0]?.connectType === ReceiptConnectType.Usb) {
			if (!connectedPrinter) {
				console.error('No printer connected. Please connect a printer first.');
				return;
			}

			console.log('Printer connected, starting receipt print process...');

			setLoading(true);

			try {
				console.log('Calling printReceipt...');
				await printBillReceiptUSBPOS(
					connectedPrinter,
					outletImage,
					qrEInvoiceImage,
					getOutlet[0],
					getOrder[0],
					'bill-settlement',
					itemArray,
					negOrderItem,
					footerState,
					getDiscount,
					[],
					[],
					[],
					getOutlet[0]?.currencyCode ?? '',
					[],
					selectedOrder?.patronName,
					getReceiptPrinter[0]?.name,
					'bill-settlement',
					false,
					'bill-receipt',
					getTaxSchemeDetail,
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					getUsersByAccountAndSoftware,
					[],
					[],
					[],
					getReceiptPrinter[0]?.deviceAlignmentCode,
				);
				console.log('Receipt printed and paper cut successfully!');
				console.log(
					'%cReceipt printed successfully!',
					'color: #12850b;',
					JSON.stringify(
						{
							productName: JSON.parse(localStorage.getItem('connectedPrinter'))
								?.productName,
							productId: JSON.parse(localStorage.getItem('connectedPrinter'))
								?.productId,
							vendorId: JSON.parse(localStorage.getItem('connectedPrinter'))
								?.vendorId,
						},
						null,
						'\t',
					),
				);
			} catch (error) {
				console.error('Failed to send data to the printer:', error);
				alert('Failed to print receipt!');
			} finally {
				setLoading(false);
			}
		} else if (
			getReceiptPrinter[0]?.connectType === ReceiptConnectType.Network
		) {
			const printData = await posBillReceiptPrinterNetwork(
				connectedPrinter,
				outletImage,
				qrEInvoiceImage,
				getOutlet[0],
				getOrder[0],
				'bill-settlement',
				itemArray,
				negOrderItem,
				footerState,
				getDiscount,
				null,
				null,
				null,
				getOutlet[0]?.currencyCode ?? '',
				null,
				selectedOrder?.patronName,
				getReceiptPrinter[0]?.name,
				'bill-settlement',
				false,
				'bill-receipt',
				getTaxSchemeDetail,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				getUsersByAccountAndSoftware,
				null,
				null,
				null,
				getReceiptPrinter[0]?.deviceAlignmentCode,
			);

			if (printData !== null) {
				try {
					const response = await fetch(
						`http://localhost:5000/${getReceiptPrinter[0]?.name}`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								ip,
								port,
								printerName,
								printData,
							}),
						},
					);

					if (response.status === 200) {
						console.log('Print job sent successfully');
					} else {
						console.error('Error sending print job');
					}
				} catch (error) {
					console.error('Error communicating with backend:', error);
				}
			}
		}
	};

	return (
		<>
			{outletLoading && <Loading />}
			{orderLoading && <Loading />}
			{paymentLoading && <Loading />}
			{getBillSettlementOrderItemLoading && <Loading />}
			{outletDailyMenuCategoryLoading && <Loading />}
			{paymentQuantityLoading && <Loading />}
			{createOnlinePaymentLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Table 03 (HARDCODE)' },
					{ name: 'Bill Settlement', current: true },
				]}
			/>
			<OrderSubheader
				mode={'dineIn'}
				getOrderLoading={orderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				handleChangeOrder={handleChangeOrder}
				outletID={outletID}
				path={`/menu/outlet-app/${outletID}/table/grid/${tableID}/bill-settlement`}
				isAuditDialog={true}
			/>

			<ContentWrapper float footer singleDynamicInfo style={{ zIndex: 40 }}>
				{!outletLoading &&
				!orderLoading &&
				!paymentLoading &&
				!getBillSettlementOrderItemLoading &&
				!outletDailyMenuCategoryLoading &&
				!getCurrentMealPeriodLoading &&
				(itemArray === undefined || itemArray?.length === 0) ? (
					<EmptyList
						title="No confirmed order item found"
						subtitle="Add a order item now."
					/>
				) : itemArray?.length !== 0 ? (
					<>
						<OrderItemList
							outletID={outletID}
							itemArray={itemArray}
							negOrderItem={negOrderItem}
							page="bill-settlement"
							currencyCode={getOutlet[0]?.currencyCode ?? ''}
						/>
					</>
				) : null}

				<BillFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 308px)'
								: isDesktop && !globalState.drawerOpen
								? 'calc(100% - 75px)'
								: '91%',
						left: !isDesktop && !globalState.drawerOpen ? 0 : null,
						zIndex: 40,
					}}
					page="bill-settlement"
					subtotal={footerState?.subtotal}
					serviceCharge={footerState?.serviceCharge}
					rounding={footerState?.rounding}
					// discount={orderDiscountAmt}
					discount={footerState?.discount}
					tax={footerState?.tax}
					total={footerState?.total}
					currencyCode={getOutlet[0]?.currencyCode ?? ''}
					outletID={outletID}
					taxSchemeInfo={selectedOrder?.taxSchemeInfo}
					taxSchemeFooterInfo={selectedOrder?.taxSchemeFooterInfo}
					orderID={selectedOrder?.ID}
				/>
			</ContentWrapper>

			<DynamicFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				options={[
					{
						name: 'Print',
						onClick: () => {
							// handlePrinting();
							sendReceiptToPrinter();
						},
						color:
							footerState?.total === 0 &&
							footerState.subtotal - footerState.discount === 0
								? 'primary'
								: footerState?.total === 0
								? '#faf2e8'
								: 'primary',
						disabled:
							footerState?.total === 0 &&
							footerState.subtotal - footerState.discount === 0
								? false
								: footerState?.total === 0
								? true
								: null,
					},
					{
						name: 'Make Payment',
						onClick: () => {
							if (orderItemQty !== 0) {
								setMode(mode);
								setOpenPayment(true);
								// setOpenChangeDialog(true);
							}
						},
						color:
							discountType === false
								? orderItemQty
									? 'primary'
									: '#faf2e8'
								: '#faf2e8',
						disabled: orderItemQty === 0 || discountType === true,
						props: {
							type: 'submit',
						},
					},
				]}
			/>

			<PaymentDialogComponent
				paymentType="settlement"
				openDialog={openPayment}
				setOpenDialog={setOpenPayment}
				getDefaultPayment={getDefaultPayment}
				errors={errors}
				control={control}
				register={register}
				watch={watch}
				setValue={setValue}
				getValue={getValues}
				reset={reset}
				hotelID={getOutlet[0]?.outletChannel?.hotelID}
				integrateCityLedger={getOutlet[0]?.integrateCityLedger}
				getOutlet={getOutlet}
				outletID={outletID}
				selectedOrder={selectedOrder}
				total={footerState?.total}
				radioError={radioError}
				setRadioError={setRadioError}
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				setOpenSignature={setOpenSignature}
				openSignature={openSignature}
				selectedPaymentClass={selectedPaymentClass}
				defaultNonGuest={defaultNonGuest}
				setDefaultNonGuest={setDefaultNonGuest}
				defaultRoom={defaultRoom}
				setDefaultRoom={setDefaultRoom}
				defaultFolio={defaultFolio}
				setDefaultFolio={setDefaultFolio}
				defaultOfficer={defaultOfficer}
				defaultStaff={defaultStaff}
				setDefaultOfficer={setDefaultOfficer}
				setDefaultStaff={setDefaultStaff}
				setDefaultDebtor={setDefaultDebtor}
				defaultDebtor={defaultDebtor}
				selectedRoom={selectedRoom}
				setSelectedRoom={setSelectedRoom}
				selectedFolio={selectedFolio}
				setSelectedFolio={setSelectedFolio}
				selectedNonGuest={selectedNonGuest}
				setSelectedNonGuest={setSelectedNonGuest}
				selectedOfficer={selectedOfficer}
				setSelectedOfficer={setSelectedOfficer}
				setSelectedDebtor={setSelectedDebtor}
				selectedDebtor={selectedDebtor}
				selectedStaff={selectedStaff}
				setSelectedStaff={setSelectedStaff}
				setHotelGuestInfo={setHotelGuestInfo}
				setChangeAmount={setChangeAmount}
				setReceivedAmount={setReceivedAmount}
				minVal={minVal}
				setMinVal={setMinVal}
				receivedAmount={receivedAmount}
				loading1={paymentQuantityLoading}
				loading2={createOnlinePaymentLoading}
				lastArray={lastArray}
				setSelectedCredit={setSelectedCredit}
				selectedCredit={selectedCredit}
			/>

			<SignatureDialog
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				total={footerState?.total}
				selectedOrder={selectedOrder}
				setOpenDialog={setOpenPayment}
				openSignature={openSignature}
				setOpenSignature={setOpenSignature}
				loading1={paymentQuantityLoading}
				loading2={createOnlinePaymentLoading}
			/>
		</>
	);
};
