import { convertDate24Hrs } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import {
	OrderItemDiscountStatus,
	OrderDiscountStatus,
	PaymentClass,
} from 'generated/graphql';
import BigNumber from 'bignumber.js';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { format } from 'date-fns';
import fetch from 'node-fetch';

const QRCode = require('qrcode');

const dateFormat = require('dateformat');

// Function to send text to the printer with Font A (monospaced) and size 1, with optional bold (0 or 1)
export const printText = (printer, text) => {
	const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
		ep => ep.direction === 'out',
	);

	if (!outEndpoint) {
		console.error('OUT endpoint not found!');
		alert('Failed to find OUT endpoint');
		return;
	}

	console.log('OUT endpoint found:', outEndpoint);
	console.log('Sending data to printer:', text);

	// ESC/POS command for selecting Font A (monospaced)
	const FONT_A = new Uint8Array([0x1b, 0x74, 0x00]); // ESC t 0x00 selects Font A (monospaced)

	// ESC/POS command for setting font size to 1 (normal size)
	const SIZE_1 = new Uint8Array([0x1b, 0x21, 0x00]); // ESC ! 0x00 sets font size to 1

	try {
		// Select Font A (monospaced)
		printer.transferOut(outEndpoint.endpointNumber, FONT_A);

		// Set font size to 1 (normal size)
		printer.transferOut(outEndpoint.endpointNumber, SIZE_1);

		// Send the actual text to the printer
		printer.transferOut(outEndpoint.endpointNumber, text);

		console.log('Data sent to printer successfully.');
	} catch (error) {
		console.error('Error while sending data to the printer:', error);
		alert('Failed to send data to the printer!');
	}
};

// Function to send text to the printer with Font A (monospaced) and size 1, with optional bold (0 or 1)
export const printTextBold = (printer, text) => {
	const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
		ep => ep.direction === 'out',
	);

	if (!outEndpoint) {
		console.error('OUT endpoint not found!');
		alert('Failed to find OUT endpoint');
		return;
	}

	console.log('OUT endpoint found:', outEndpoint);
	console.log('Sending data to printer:', text);

	// ESC/POS command for selecting Font A (monospaced)
	const FONT_A = new Uint8Array([0x1b, 0x74, 0x00]); // ESC t 0x00 selects Font A (monospaced)

	// ESC/POS command for setting font size to 1 (normal size)
	const SIZE_1 = new Uint8Array([0x1b, 0x21, 0x00]); // ESC ! 0x00 sets font size to 1

	// ESC/POS command for enabling bold text
	const BOLD_ON = new Uint8Array([0x1b, 0x45, 0x01]); // ESC E 0x01 enables bold

	// ESC/POS command for disabling bold text
	const BOLD_OFF = new Uint8Array([0x1b, 0x45, 0x00]); // ESC E 0x00 disables bold

	try {
		// Select Font A (monospaced)
		printer.transferOut(outEndpoint.endpointNumber, FONT_A);

		// Set font size to 1 (normal size)
		printer.transferOut(outEndpoint.endpointNumber, SIZE_1);

		// Apply bold text if isBold is 1
		printer.transferOut(outEndpoint.endpointNumber, BOLD_ON);

		// Send the actual text to the printer
		printer.transferOut(outEndpoint.endpointNumber, text);

		console.log('Data sent to printer successfully.');
	} catch (error) {
		console.error('Error while sending data to the printer:', error);
		alert('Failed to send data to the printer!');
	}
};

function printLineWithDashes(printer, lineLength) {
	// Create a string of dashes of the specified length
	const dashedLine = '-'.repeat(lineLength);

	// Encode the string into a format the printer can use
	printTextBold(printer, new TextEncoder().encode(`${dashedLine}\n`));
}

function createReceiptLine(
	leftText,
	rightText,
	printer,
	LINE_CHARS,
	isBold,
	newline,
) {
	let paddingForBill = LINE_CHARS - leftText.length - rightText.length;
	paddingForBill = ' '.repeat(paddingForBill);

	const printMethod = isBold === 1 ? printTextBold : printText;

	printMethod(
		printer,
		new TextEncoder().encode(
			`${leftText}${paddingForBill}${rightText}${newline}`,
		),
	);
}

export const printKitchenReceiptUSBPOS = async (
	printer,
	logo,
	qrEInvoice,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	printerName,
	mode,
	isCash = false,
	receiptType,
	getTaxSchemeDetail,
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
	getUsersByAccountAndSoftware,
	officer,
	staff,
	getTaxSummary,
	deviceAlignmentCode,
	qrCodeImage,
) => {
	if (!printer || !printer.configuration || !printer.configuration.interfaces) {
		console.error('Invalid printer configuration!');
		return;
	}

	console.log('Printer initialized and configured correctly.');

	// ESC/POS command for setting line spacing
	const ESC_POS_LINE_SPACING = new Uint8Array([0x1b, 0x33, 0x20]); // Set line spacing to a smaller value (0x20 is just an example)

	// Initialize Printer (ESC/POS Reset command)
	const ESC_POS_INIT = new Uint8Array([0x1b, 0x40]);
	printText(printer, ESC_POS_INIT); // Reset the printer

	// Print Store Name Center Aligned
	const ESC_POS_CENTER = new Uint8Array([0x1b, 0x61, 0x01]);
	const LINE_CHARS = deviceAlignmentCode;

	printTextBold(printer, ESC_POS_CENTER); // Align center

	if (orderMode === 'void') {
		printTextBold(printer, new TextEncoder().encode(`Void Item\n\n`));
	}

	printText(printer, ESC_POS_INIT); // Reset the printer

	printTextBold(printer, new TextEncoder().encode(`Outlet: ${outletName}\n`));
	createReceiptLine(
		`Order No: ${orderData?.docNo}`,
		`Cover: ${orderData?.pax}\n`,
		printer,
		LINE_CHARS,
		1,
		``,
	);

	const printerName1 = Array.from(
		new Set(orderItemData?.map(x => x?.menuItem?.kitchenPrinter?.name)),
	);

	if (orderMode === 'void') {
		createReceiptLine(
			`${printerName1}`,
			`Table: ${tableData?.prefix + tableData?.tableNumber}\n`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
	} else {
		createReceiptLine(
			`${printerName1}`,
			`Table: ${tableData?.prefix + tableData?.tableNumber}\n`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
	}

	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	if (orderMode === 'void') {
		createReceiptLine(
			`${loggedInUser?.name}`,
			`${convertDate24Hrs(new Date().toISOString())}\n`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
	} else {
		createReceiptLine(
			`${loggedInUser?.name}`,
			`${convertDate24Hrs(orderData?.createdTs)}\n`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
	}

	printLineWithDashes(printer, deviceAlignmentCode);

	{
		orderItemData.forEach(oi => {
			if (orderMode === 'void') {
				if (oi?.isTakeaway === true) {
					createReceiptLine(
						`${oi?.menuItem?.kitchenName}`,
						`(T) x${voidQuantity}\n`,
						printer,
						LINE_CHARS,
						0,
						``,
					);
				} else if (oi?.isTakeaway === false) {
					createReceiptLine(
						`${oi?.menuItem?.kitchenName}`,
						`x${voidQuantity}\n`,
						printer,
						LINE_CHARS,
						0,
						``,
					);
				}
			} else {
				if (oi?.isTakeaway === true) {
					createReceiptLine(
						`${oi?.menuItem?.kitchenName}`,
						`(T) x${oi?.quantity}\n`,
						printer,
						LINE_CHARS,
						0,
						``,
					);
				} else if (oi?.isTakeaway === false) {
					createReceiptLine(
						`${oi?.menuItem?.kitchenName}`,
						`x${oi?.quantity}\n`,
						printer,
						LINE_CHARS,
						0,
						``,
					);
				}
			}

			if (oi?.specialRequest) {
				createReceiptLine(
					`${oi?.specialRequest}`,
					`\n`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
			}

			if (oi?.orderItemOption?.length > 0) {
				oi.orderItemOption.forEach(oiOption => {
					oiOption.orderItemOptionItem.forEach(oiOptionItem => {
						createReceiptLine(
							`${oiOptionItem?.optionItem?.name}`,
							`\n`,
							printer,
							LINE_CHARS,
							0,
							``,
						);
					});
				});
			} else if (oi?.orderItemSetMenuOption?.length > 0) {
				oi.orderItemSetMenuOption.forEach(oiSetMenuOption => {
					oiSetMenuOption.orderItemSetMenuOptionItem.forEach(
						oiSetMenuOptionItem => {
							createReceiptLine(
								`${oiSetMenuOptionItem?.menuItem?.kitchenName}`,
								`\n`,
								printer,
								LINE_CHARS,
								0,
								``,
							);
						},
					);
				});
			}

			if (orderMode === 'void') {
				createReceiptLine(
					`Reason: ${reasonCode}`,
					`\n`,
					printer,
					LINE_CHARS,
					0,
					``,
				);

				if (remark) {
					createReceiptLine(
						`Remark: ${remark}`,
						`\n`,
						printer,
						LINE_CHARS,
						0,
						``,
					);
				}
			}
		});
	}
	printLineWithDashes(printer, deviceAlignmentCode);

	printText(printer, new TextEncoder().encode(`\n`));

	// Only cut the paper once all print jobs are completed
	const ESC_POS_CUT = new Uint8Array([0x1d, 0x56, 0x00]);
	await printText(printer, ESC_POS_CUT); // Send paper cut command here
};
