import { convertDate24Hrs } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import {
	OrderItemDiscountStatus,
	OrderDiscountStatus,
	PaymentClass,
} from 'generated/graphql';
import BigNumber from 'bignumber.js';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
const dateFormat = require('dateformat');

const ESC_INIT = [0x1b, 0x40]; // Initialize printer command
const ESC_BIT_IMAGE = [0x1b, 0x2a]; // ESC/POS Command to send image data
const DOTS_DENSITY = 24; // Image density for slicing
const LINE_FEED = 0x0a; // Line feed command
const LUMINANCE = { RED: 0.299, GREEN: 0.587, BLUE: 0.114 };

// Converts RGB pixel to grayscale luminance
function calculateLuminance(pixel) {
	return (
		LUMINANCE.RED * pixel[0] +
		LUMINANCE.GREEN * pixel[1] +
		LUMINANCE.BLUE * pixel[2]
	);
}

// Converts grayscale to black & white using a threshold
function convertImageToBinary(image) {
	const threshold = 127; // Adjust threshold if needed
	return image.map(row =>
		row.map(pixel => (calculateLuminance(pixel) < threshold ? 0 : 1)),
	);
}

// Properly collects 8-pixel stripes for ESC/POS format
function collectStripe(x, y, image) {
	let stripe = [0, 0, 0]; // 3 bytes per column (24 dots)
	for (let i = 0; i < 3; i++) {
		let slice = 0;
		for (let bit = 0; bit < 8; bit++) {
			let pixelY = y + i * 8 + bit;
			if (pixelY < image.length && x < image[0].length) {
				slice |= (image[pixelY][x] === 0 ? 1 : 0) << (6 - bit);
			}
		}
		stripe[i] = slice;
	}
	return stripe;
}

// Converts image to ESC/POS bitmap data
function manipulateImage(image) {
	let data = [];
	const imageWidth = image[0].length;

	for (let y = 0; y < image.length; y += DOTS_DENSITY) {
		data.push(
			...ESC_BIT_IMAGE,
			33,
			imageWidth & 0xff,
			(imageWidth >> 8) & 0xff,
		);
		for (let x = 0; x < imageWidth; x++) {
			data.push(...collectStripe(x, y, image));
		}
		data.push(LINE_FEED);
	}
	return data;
}

// Generates the final print-ready byte array
function printImage(image) {
	return new Uint8Array([...ESC_INIT, ...manipulateImage(image)]);
}

function scaleImage(
	image,
	maxWidth,
	trimLeft = 20,
	trimTop = 50,
	trimBottom = 50,
) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	// Calculate the scale factor for width
	const scaleFactor = maxWidth / image.width;

	// Calculate the new height to preserve aspect ratio
	const newHeight = Math.round(image.height * scaleFactor);

	// Create the canvas with the scaled width and height
	canvas.width = maxWidth & ~7; // Ensure width is a multiple of 8
	canvas.height = newHeight;

	// Draw the scaled image on the canvas
	ctx.drawImage(
		image,
		0,
		0,
		image.width,
		image.height,
		0,
		0,
		canvas.width,
		canvas.height,
	);

	// Now trim the top, bottom, and left after scaling
	const trimmedCanvas = document.createElement('canvas');
	const trimmedCtx = trimmedCanvas.getContext('2d');

	// Calculate the width and height of the trimmed canvas
	trimmedCanvas.width = canvas.width - trimLeft; // Subtract the left trim width
	trimmedCanvas.height = canvas.height - trimTop - trimBottom; // Subtract the top and bottom trim heights

	// Calculate the offset to center the image (horizontally and vertically)
	const offsetY = trimTop; // For vertical centering after trim
	const offsetX = trimLeft; // For horizontal centering after left trim

	// Draw the cropped image onto the new canvas with calculated offsets to center
	trimmedCtx.drawImage(
		canvas,
		trimLeft,
		trimTop,
		canvas.width - trimLeft,
		canvas.height - trimTop - trimBottom,
		0,
		0,
		trimmedCanvas.width,
		trimmedCanvas.height,
	);

	return trimmedCanvas;
}

// Converts a canvas image to pixel array
function convertImageToPixelData(canvas) {
	const ctx = canvas.getContext('2d');
	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

	return Array.from({ length: imageData.height }, (_, y) =>
		Array.from({ length: imageData.width }, (_, x) => {
			const index = (y * imageData.width + x) * 4;
			return [
				imageData.data[index],
				imageData.data[index + 1],
				imageData.data[index + 2],
			];
		}),
	);
}

function createReceiptLine(leftText, rightText, lineChars) {
	let padding = lineChars - leftText.length - rightText.length;
	padding = ' '.repeat(padding);
	return `${leftText}${padding}${rightText}\n`;
}

const handleCardType = cardType => {
	switch (cardType) {
		case 'VISA_CARD':
			return 'VISA';
		case 'MASTERCARD':
			return 'MASTERCARD';
		case 'AMERICAN_EXPRESS_CARD':
			return 'AMERICAN EXPRESS';
		case 'DINERS_CARD':
			return 'DINERS';
		case 'JCB_CARD':
			return 'JCB';
		case 'UNIONPAY_CARD':
			return 'UNIONPAY';
		case 'DEBIT_CARD':
			return 'DEBIT';
		default:
			return 'Credit';
	}
};

async function posBillReceiptPrinterNetwork(
	printer,
	logo,
	qrEInvoice,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	printerName,
	mode,
	isCash = false,
	receiptType,
	getTaxSchemeDetail,
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
	getUsersByAccountAndSoftware,
	officer,
	staff,
	getTaxSummary,
	deviceAlignmentCode,
) {
	const activeOrderDiscount = data?.order?.orderDiscount?.filter(
		y => y?.status === OrderDiscountStatus?.Active,
	)[0];

	const OCENT = discount?.filter(
		x => x?.ID === activeOrderDiscount?.discountID,
	)[0]?.discountType;

	const displayOCENT =
		OCENT === 'OFFICER_CHECK'
			? 'Officer Check'
			: OCENT === 'ENTERTAINMENT'
			? 'Entertainment'
			: OCENT === 'DISCOUNT'
			? 'Discount'
			: 'Promo';

	const company = data?.outlet?.company;
	const qrCodeUrl = data?.qrCodeUrl;

	const Einvoice = qrCodeUrl !== null && data?.is_einvoice === true;

	const condition =
		data?.is_einvoice === true &&
		company?.isEInvIntegrated === true &&
		dateFormat(data?.createdTs, 'yyyy-mm-dd') >=
			dateFormat(company?.eInvStartDate, 'yyyy-mm-dd') &&
		dateFormat(data?.createdTs, 'yyyy-mm-dd') <=
			dateFormat(company?.eInvEndDate, 'yyyy-mm-dd') &&
		qrCodeUrl !== null;

	const outletPoscode =
		outlet?.address?.postCode !== null ||
		outlet?.address?.postCode !== undefined
			? outlet?.address?.postCode + ','
			: '';

	const outletCity =
		outlet?.address?.city !== null || outlet?.address?.city !== undefined
			? outlet?.address?.city + ','
			: '';

	const outletState =
		outlet?.address?.state !== null || outlet?.address?.state !== undefined
			? outlet?.address?.state
			: '';
	const receipt = [String.fromCharCode(27) + '@'];
	try {
		// Convert base64 to image
		const img = new Image();
		img.src = logo;

		await new Promise((resolve, reject) => {
			img.onload = resolve;
			img.onerror = reject;
		});

		// Printer constraints
		const LINE_CHARS = 48;
		const maxCharWidth = 12;
		const maxWidth = Math.floor((LINE_CHARS * maxCharWidth) / 8) * 8;

		// Process image
		const scaledCanvas = scaleImage(img, maxWidth, 30, 150, 150);
		let pixelData = convertImageToPixelData(scaledCanvas);
		pixelData = convertImageToBinary(pixelData); // Apply thresholding

		// Convert to ESC/POS bitmap format
		const bitmap = printImage(pixelData);
		receipt.push(String.fromCharCode.apply(null, bitmap));
	} catch (error) {
		console.error('Error converting base64 to bitmap:', error);
	}

	// Set font size to 1 (default size in many printers)
	receipt.push(String.fromCharCode(27) + '!' + String.fromCharCode(0)); // Font size 1 (default size)

	// Center align the text (assuming printer supports \x1B\x61\x01 for center)
	receipt.push(String.fromCharCode(27) + '\x61\x01'); // Center alignment

	receipt.push(`${outlet?.address?.address}\n`);
	receipt.push(`${outletPoscode}${outletCity}${outletState}.\n`);
	receipt.push(`${outlet?.contactNo}\n`);
	receipt.push(`(SST Reg No: ${outlet?.taxRegistrationNo})\n\n`);

	receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON
	receipt.push(`${outlet?.name}\n\n`);

	// Reset alignment to left (default)
	receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)

	const LINE_CHARS = deviceAlignmentCode;

	receipt.push(
		`${createReceiptLine(
			`Bill No: ${data?.billNo}`,
			`${convertDate24Hrs(data?.createdTs)}`,
			LINE_CHARS,
		)}\n`,
	);

	receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(0)); // Bold OFF

	//patron
	const patronClass = data?.patron?.patronClass
		? data?.patron?.patronClass
		: data?.order?.patron?.patronClass;

	if (page === 'void-bill') {
		receipt.push(`Voided Bill\n`);
	} else {
		if (patronClass === 'Staff') {
			receipt.push(`Staff       : ${patronName}\n`);
		} else if (patronClass === 'Officer') {
			receipt.push(`Officer     : ${patronName}\n`);
		} else if (patronClass === 'Visitor') {
			receipt.push(`Visitor     : ${patronName ?? '-'}\n`);
		} else if (patronClass === 'Hotel Guest') {
			receipt.push(`Hotel Guest : ${patronName}\n`);
		} else if (patronClass === 'Member') {
			receipt.push(`Member\n`);
		}
	}

	//table
	if (page !== 'void-bill') {
		receipt.push(
			`Table       : ${
				page === 'bill-settlement'
					? data?.table?.prefix + data?.table?.tableNumber
					: data?.order?.table?.prefix + data?.order?.table?.tableNumber
			}\n`,
		);
	}

	// cover
	if (page !== 'void-bill' || data?.order?.serviceMode === 'DINE_IN') {
		receipt.push(
			`Cover       : ${
				page === 'bill-settlement' ? data?.pax : data?.order?.pax
			}\n`,
		);
	}

	//cashier
	if (page !== 'void-bill') {
		receipt.push(
			`Cashier     : ${
				getUsersByAccountAndSoftware?.filter(
					x => x?.ID === data?.modBy || x?.ID === data?.createdBy,
				)[0]?.name
			}\n\n`,
		);
	}

	// order item (oi)
	{
		orderItem.forEach(oi => {
			// oi name & quantity

			let totalOITax = 0;
			oi.orderItemTax.forEach(x => {
				totalOITax += x?.taxAmount;
			});

			if (oi?.isTakeaway === true) {
				receipt.push(
					`${createReceiptLine(
						`${oi?.openItemName ?? oi?.menuItem?.name} (T) x${oi?.quantity -
							(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
						`${currencyCode}${amtStr(
							new BigNumber(oi?.amount)
								.plus(totalOITax)
								.minus(
									(negOrderItem?.[oi?.ID]?.amount ?? 0) +
										(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
								)
								.toNumber(),
						)}`,
						LINE_CHARS,
					)}`,
				);
				receipt.push(`\n\n`);
			} else if (oi?.isTakeaway === false) {
				receipt.push(
					`${createReceiptLine(
						`${oi?.openItemName ?? oi?.menuItem?.name} x${oi?.quantity -
							(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
						`${currencyCode}${amtStr(
							new BigNumber(oi?.amount)
								.plus(totalOITax)
								.minus(
									(negOrderItem?.[oi?.ID]?.amount ?? 0) +
										(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
								)
								.toNumber(),
						)}`,
						LINE_CHARS,
					)}`,
				);
				receipt.push(`\n`);
			}

			if (oi?.orderItemOption?.length > 0) {
				// order item option (oio)
				oi.orderItemOption.forEach(oiOption => {
					oiOption.orderItemOptionItem.forEach(oiOptionItem => {
						// oio name and amount

						let totalTax = 0;
						oiOptionItem.orderItemOptionItemTax.forEach(x => {
							totalTax += x?.taxAmount;
						});

						receipt.push(
							`${createReceiptLine(
								`${oiOptionItem?.optionItem?.name}`,
								`${
									oiOptionItem?.price === 0
										? ''
										: currencyCode +
										  amtStr(
												Math.abs(
													(oiOptionItem?.basePrice + totalTax) *
														((oi?.quantity -
															(negOrderItem?.[oiOption?.orderItemID]
																?.quantity ?? 0)) /
															oi?.quantity),
												),
										  )
								}`,
								LINE_CHARS,
							)}`,
						);
						receipt.push(`\n`);
					});
				});
			} else if (oi?.orderItemSetMenuOption?.length > 0) {
				// order item set menu option (oismo)
				oi.orderItemSetMenuOption.forEach(oiSetMenuOption => {
					oiSetMenuOption.orderItemSetMenuOptionItem.forEach(
						// oismo name
						async oiSetMenuOptionItem => {
							receipt.push(
								`${oiSetMenuOptionItem?.setMenuOptionItem?.menuItem?.name}`,
							);
							receipt.push(`\n`);
						},
					);
				});
			}

			// order item discount (oid)
			const activeOrderItemDiscount = oi?.orderItemDiscount?.filter(
				y => y?.status === OrderItemDiscountStatus?.Active,
			)[0];

			let totalOIDTax = 0;
			oi.orderItemDiscountTax.forEach(x => {
				totalOIDTax += x?.taxAmount;
			});

			let sumNegOptDisc = 0;

			if (activeOrderItemDiscount) {
				receipt.push(
					`${createReceiptLine(
						`Discount: ${
							discount?.filter(
								x => x?.ID === activeOrderItemDiscount?.discountID,
							)[0]?.code
						}`,
						`(${currencyCode}${amtStr(
							activeOrderItemDiscount?.baseAmount +
								totalOIDTax -
								(negOrderItem?.[orderItem?.ID]?.orderItemDiscBaseAmount ?? 0) -
								(negOrderItem?.[orderItem?.ID]?.orderItemDiscTaxAmount ?? 0) +
								(totalOptItmPerOrderItem?.filter(
									x => x?.orderItemID === orderItem?.ID,
								)[0]?.discountBaseAmount ?? 0) +
								(totalOptItmPerOrderItem?.filter(
									x => x?.orderItemID === orderItem?.ID,
								)[0]?.itemDiscountTaxAmount ?? 0) -
								(totalOptItmPerOrderItem?.filter(
									x => x?.orderItemID === orderItem?.ID,
								)[0]?.discountItemTaxAmount ?? 0) -
								sumNegOptDisc,

							// calcDiscountAmount(orderItem),
						)})\n`,
						LINE_CHARS,
					)}`,
				);
				receipt.push(`\n`);
			}
		});
	}

	// Subtotal
	receipt.push(
		`${createReceiptLine(
			'Sub-Total',
			`${currencyCode + amtStr(footerToken?.subtotal)}`,
			LINE_CHARS,
		)}`,
	);

	// Discount
	const activeOrderDiscountFooter =
		page === 'bill-settlement'
			? data?.orderDiscount?.filter(
					y => y?.status === OrderDiscountStatus?.Active,
			  )[0]
			: data?.order?.orderDiscount?.filter(
					y => y?.status === OrderDiscountStatus?.Active,
			  )[0];
	receipt.push(
		`${createReceiptLine(
			`Discount: ${discount?.filter(
				x => x?.ID === activeOrderDiscountFooter?.discountID,
			)[0]?.code ?? ' '}`,
			`(${currencyCode + amtStr(footerToken?.discount)})`,
			LINE_CHARS,
		)}`,
	);

	// Rounding
	let roundAmount = 0;
	latestPayment.forEach(x => {
		roundAmount += x?.roundingAmount;
	});
	let rounding = page === 'bill-settlement' ? footerToken?.rounding : 0;
	const leftTextRounding = 'Rounding';
	const rightTextRoundingAbs = `(${currencyCode}${Math.abs(rounding).toFixed(
		2,
	)})`;
	const rightTextRounding = `${currencyCode}${amtStr(rounding)}`;
	let paddingRoundingAbs =
		LINE_CHARS - leftTextRounding.length - rightTextRoundingAbs.length;
	paddingRoundingAbs = ' '.repeat(paddingRoundingAbs);
	let paddingRounding =
		LINE_CHARS - leftTextRounding.length - rightTextRounding.length;
	paddingRounding = ' '.repeat(paddingRounding);
	if (page === 'bill-settlement') {
		if (rounding < 0) {
			receipt.push(
				`${createReceiptLine(
					'Rounding',
					`(${currencyCode}${Math.abs(rounding).toFixed(2)})`,
					LINE_CHARS,
				)}`,
			);
			receipt.push(`\n\n`);
		} else {
			receipt.push(
				`${createReceiptLine(
					'Rounding',
					`${currencyCode}${amtStr(rounding)}`,
					LINE_CHARS,
				)}`,
			);
			receipt.push(`\n\n`);
		}
	} else {
		if (roundAmount < 0) {
			receipt.push(
				`${createReceiptLine(
					'Rounding',
					`(${currencyCode}${Math.abs(rounding).toFixed(2)})`,
					LINE_CHARS,
				)}`,
			);
			receipt.push(`\n`);
		} else {
			receipt.push(
				`${createReceiptLine(
					'Rounding',
					`${currencyCode}${amtStr(rounding)}`,
					LINE_CHARS,
				)}`,
			);
			receipt.push(`\n`);
		}
	}

	// Total
	receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON
	if (page === 'bill-settlement') {
		receipt.push(
			`${createReceiptLine(
				'Total',
				`${currencyCode}${amtStr(
					new BigNumber(Math.abs(footerToken?.total)).toNumber(),
				)}`,
				LINE_CHARS,
			)}`,
		);
		receipt.push(`\n`);
	} else {
		receipt.push(
			`${createReceiptLine(
				'Total',
				`${currencyCode}${amtStr(
					new BigNumber(Math.abs(footerToken?.total))
						.plus(roundAmount ?? 0)
						.toNumber(),
				)}`,
				LINE_CHARS,
			)}`,
		);
		receipt.push(`\n`);
	}
	receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(0)); // Bold OFF

	// Tax
	const sortedTaxScheme = getTaxSchemeDetail?.sort((a, b) => {
		if (a.taxType < b.taxType) return -1;
		if (a.taxType > b.taxType) return 1;

		if (a.taxRate < b.taxRate) return -1;
		if (a.taxRate > b.taxRate) return 1;

		return 0;
	});
	const taxSchemeFooterInfo =
		mode === 'bill-settlement'
			? data?.taxSchemeFooterInfo
			: data?.order?.taxSchemeFooterInfo;
	const taxAmount = (taxType, taxRate) => {
		const tax = taxSchemeFooterInfo
			?.filter(x => x?.name === taxType)
			?.filter(v => parseInt(v?.rate) === taxRate)[0];

		return tax?.sum;
	};
	if (sortedTaxScheme?.length > 0) {
		sortedTaxScheme.forEach(x => {
			receipt.push(
				`${createReceiptLine(
					`${x?.taxCode} (${x?.taxRate}%)`,
					`${currencyCode}${amtStr(Number(taxAmount(x?.taxType, x?.taxRate)))}`,
					LINE_CHARS,
				)}`,
			);
		});
		receipt.push(`\n`);
	}

	// Bill Settlement Sign
	if (page === 'bill-settlement') {
		receipt.push(`\n`);

		// Name
		receipt.push(
			`${createReceiptLine(
				'Name : ___________________________________',
				``,
				LINE_CHARS,
			)}`,
		);
		receipt.push(`\n`);

		// Room/ACC
		receipt.push(
			`${createReceiptLine(
				'Room/ACC : _______________________________',
				``,
				LINE_CHARS,
			)}`,
		);
		receipt.push(`\n`);

		// Signature
		receipt.push(
			`${createReceiptLine(
				'Signature : ______________________________',
				``,
				LINE_CHARS,
			)}`,
		);
		receipt.push(`\n`);
	}

	if (page === 'void-bill' || page === 'bill-settlement') {
	} else {
		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON
		receipt.push(`Payment\n`);
		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(0)); // Bold OFF

		latestPayment.forEach(el => {
			if (el?.paymentClass === PaymentClass.UnsettledBill) {
				// Unsettled Bill
				receipt.push(
					`${createReceiptLine(
						`Unsettled Bill`,
						`${currencyCode}${amtStr(el?.amount)}`,
						LINE_CHARS,
					)}`,
				);
			} else if (el?.paymentClass === PaymentClass.Credit) {
				// Credit
				receipt.push(
					`${createReceiptLine(
						`${handleCardType(el?.creditCardType)}`,
						`${currencyCode}${amtStr(el?.receivedAmount)}`,
						LINE_CHARS,
					)}`,
				);
			} else if (el?.paymentClass === PaymentClass.Online) {
				// Online Payment
				receipt.push(
					`${createReceiptLine(
						`Online Payment`,
						`${currencyCode}${amtStr(el?.receivedAmount)}`,
						LINE_CHARS,
					)}`,
				);
			} else if (el?.paymentClass === PaymentClass.NonGuest) {
				// Non Guest
				receipt.push(
					`${createReceiptLine(
						`Non Guest`,
						`${currencyCode}${amtStr(el?.receivedAmount)}`,
						LINE_CHARS,
					)}`,
				);
			} else if (el?.paymentClass === PaymentClass.SuspendFolio) {
				// Suspend Folio
				receipt.push(
					`${createReceiptLine(
						`Suspend Folio`,
						`${currencyCode}${amtStr(el?.receivedAmount)}`,
						LINE_CHARS,
					)}`,
				);
			} else if (el?.paymentClass === PaymentClass.BankTt) {
				// Bank TT
				receipt.push(
					`${createReceiptLine(
						`Bank TT`,
						`${currencyCode}${amtStr(el?.receivedAmount)}`,
						LINE_CHARS,
					)}`,
				);
			} else {
				// Payment
				receipt.push(
					`${createReceiptLine(
						`${toProperCase(el?.paymentClass)}`,
						`${currencyCode}${amtStr(el?.receivedAmount)}`,
						LINE_CHARS,
					)}`,
				);
			}

			if (el?.paymentClass === PaymentClass.Room) {
				// Room
				receipt.push(
					`${createReceiptLine(
						`  Charged to`,
						`${el?.hotelGuestInfo?.RoomNo} | ${el?.hotelGuestInfo?.FullName}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (
				el?.paymentClass === PaymentClass.NonGuest ||
				el?.paymentClass === PaymentClass.SuspendFolio
			) {
				// NonGuest || SuspendFolio
				receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON
				receipt.push(`  Charged to:\n`);
				receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(0)); // Bold OFF
				receipt.push(`  ${el?.hotelGuestInfo?.FullName}\n`);
			}

			if (el?.paymentClass === PaymentClass.Debtor) {
				// Debtor
				receipt.push(
					`${createReceiptLine(
						`  Charged to`,
						`${el?.hotelGuestInfo?.GuestType} | ${el?.hotelGuestInfo?.FullName}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (el?.paymentClass === PaymentClass.Staff) {
				// Staff
				receipt.push(
					`${createReceiptLine(
						`  Charged to`,
						`${staff[0]?.designation} | ${staff[0]?.name}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (el?.paymentClass === PaymentClass.Officer) {
				// Officer
				receipt.push(
					`${createReceiptLine(
						`  Charged to`,
						`${officer[0]?.designation} | ${officer[0]?.name}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (
				el?.paymentClass === PaymentClass.BankTt ||
				el?.paymentClass === PaymentClass.Cheque ||
				el?.paymentClass === PaymentClass.Online ||
				el?.paymentClass === PaymentClass.Skipper ||
				el?.paymentClass === PaymentClass.Member
			) {
				// Remark
				receipt.push(
					`${createReceiptLine(
						`  Remark: `,
						`${el?.remark ?? '-'}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (el?.paymentClass === PaymentClass.Voucher) {
				// Code
				receipt.push(
					`${createReceiptLine(
						`  Code: `,
						`${el?.referenceNo ?? '-'}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (el?.paymentClass === PaymentClass.Others) {
				// Remark
				receipt.push(
					`${createReceiptLine(
						`  Remark: `,
						`${el?.referenceNo ?? '-'}  `,
						LINE_CHARS,
					)}`,
				);
			}

			if (el?.paymentClass === PaymentClass.Credit) {
				// CardRef
				receipt.push(
					`${createReceiptLine(
						`  Card ref: `,
						`${`****${el?.cardNo?.slice(-4)}` ?? '-'}  `,
						LINE_CHARS,
					)}`,
				);

				if (el?.hotelGuestInfo?.CompanyName !== undefined) {
					// Card Info
					receipt.push(
						`${createReceiptLine(
							`  Charged to: `,
							`${el?.hotelGuestInfo?.DebtorAccount}${' | '}${
								el?.hotelGuestInfo?.CompanyName
							}`,
							LINE_CHARS,
						)}`,
					);
				}
			}

			if (el?.paymentClass === PaymentClass.Cash && el?.changeAmount > 0) {
				// Cash Change
				receipt.push(
					`${createReceiptLine(
						`  Change: `,
						`${currencyCode}${amtStr(el?.changeAmount)}`,
						LINE_CHARS,
					)}`,
				);
			}
		});

		if (latestPayment?.length === 0) {
			//OC || ENT
			const OCENT = discount?.filter(
				x => x?.ID === activeOrderDiscount?.discountID,
			)[0]?.discountType;

			const displayOCENT =
				OCENT === 'OFFICER_CHECK'
					? 'Officer Check'
					: OCENT === 'ENTERTAINMENT'
					? 'Entertainment'
					: OCENT === 'DISCOUNT'
					? 'Discount'
					: 'Promo';

			receipt.push(
				`${createReceiptLine(
					`${displayOCENT}`,
					`${currencyCode}${amtStr(latestPayment?.amount)}`,
					LINE_CHARS,
				)}`,
			);
		}

		// Tax Summary
		const totalAmount = getTaxSummary?.reduce((a, b) => {
			return a + b?.amount;
		}, 0);

		const totalTaxAmount = getTaxSummary?.reduce((a, b) => {
			return a + b?.taxAmount;
		}, 0);

		receipt.push(`\n`);
		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON
		receipt.push(`SST Summary\n`);
		receipt.push(
			`${createReceiptLine(`Tax Cd`, `Amt Excl SST(RM) Tax(RM)`, LINE_CHARS)}`,
		);
		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(0)); // Bold OFF

		if (getTaxSummary?.length > 0) {
			getTaxSummary.forEach(x => {
				receipt.push(
					`${createReceiptLine(
						`${x?.code}`,
						`${amtStr(x?.amount)}       ${amtStr(x?.taxAmount)}`,
						LINE_CHARS,
					)}`,
				);
			});
		}

		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON
		receipt.push(
			`${createReceiptLine(
				`Total`,
				`${amtStr(totalAmount)}       ${amtStr(totalTaxAmount)}`,
				LINE_CHARS,
			)}`,
		);
		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(0)); // Bold OFF
	}

	receipt.push(`\n`);

	if (condition === true) {
		try {
			// Convert base64 to image
			const img = new Image();
			img.src = qrEInvoice;

			await new Promise((resolve, reject) => {
				img.onload = resolve;
				img.onerror = reject;
			});

			// Printer constraints
			const LINE_CHARS = 48;
			const maxCharWidth = 12;
			const maxWidth = Math.floor((LINE_CHARS * maxCharWidth) / 8) * 8;

			// Process image
			const scaledCanvas = scaleImage(img, maxWidth, 30, 200, 200);
			let pixelData = convertImageToPixelData(scaledCanvas);
			pixelData = convertImageToBinary(pixelData); // Apply thresholding

			// Convert to ESC/POS bitmap format
			const bitmap = printImage(pixelData);
			receipt.push(String.fromCharCode.apply(null, bitmap));
			receipt.push(`\n`);
			// Center align the text (assuming printer supports \x1B\x61\x01 for center)
			receipt.push(String.fromCharCode(27) + '\x61\x01'); // Center alignment
			receipt.push(`Scan within 72 hours if\n`);
			receipt.push(`e-Invoice is required\n`);
			receipt.push(`\n`);
			// Reset alignment to left (default)
			receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)
		} catch (error) {
			console.error('Error converting base64 to bitmap:', error);
		}
	}

	// Center align the text (assuming printer supports \x1B\x61\x01 for center)
	receipt.push(String.fromCharCode(27) + '\x61\x01'); // Center alignment
	receipt.push(`Thank you & we hope to see you again soon.\n`);
	// Reset alignment to left (default)
	receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)
	receipt.push(`\n\n`);

	receipt.push(
		String.fromCharCode(29) +
			'V' +
			String.fromCharCode(66) +
			String.fromCharCode(0),
	);
	return receipt.join('');
}

export { posBillReceiptPrinterNetwork };
