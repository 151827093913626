import { convertDate24Hrs } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import {
	OrderItemDiscountStatus,
	OrderDiscountStatus,
	PaymentClass,
} from 'generated/graphql';
import BigNumber from 'bignumber.js';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { format } from 'date-fns';
import fetch from 'node-fetch';

const QRCode = require('qrcode');

const dateFormat = require('dateformat');

// Function to send text to the printer with Font A (monospaced) and size 1, with optional bold (0 or 1)
export const printText = (printer, text) => {
	const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
		ep => ep.direction === 'out',
	);

	if (!outEndpoint) {
		console.error('OUT endpoint not found!');
		alert('Failed to find OUT endpoint');
		return;
	}

	console.log('OUT endpoint found:', outEndpoint);
	console.log('Sending data to printer:', text);

	// ESC/POS command for selecting Font A (monospaced)
	const FONT_A = new Uint8Array([0x1b, 0x74, 0x00]); // ESC t 0x00 selects Font A (monospaced)

	// ESC/POS command for setting font size to 1 (normal size)
	const SIZE_1 = new Uint8Array([0x1b, 0x21, 0x00]); // ESC ! 0x00 sets font size to 1

	try {
		// Select Font A (monospaced)
		printer.transferOut(outEndpoint.endpointNumber, FONT_A);

		// Set font size to 1 (normal size)
		printer.transferOut(outEndpoint.endpointNumber, SIZE_1);

		// Send the actual text to the printer
		printer.transferOut(outEndpoint.endpointNumber, text);

		console.log('Data sent to printer successfully.');
	} catch (error) {
		console.error('Error while sending data to the printer:', error);
		alert('Failed to send data to the printer!');
	}
};

// Function to send text to the printer with Font A (monospaced) and size 1, with optional bold (0 or 1)
export const printTextBold = (printer, text) => {
	const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
		ep => ep.direction === 'out',
	);

	if (!outEndpoint) {
		console.error('OUT endpoint not found!');
		alert('Failed to find OUT endpoint');
		return;
	}

	console.log('OUT endpoint found:', outEndpoint);
	console.log('Sending data to printer:', text);

	// ESC/POS command for selecting Font A (monospaced)
	const FONT_A = new Uint8Array([0x1b, 0x74, 0x00]); // ESC t 0x00 selects Font A (monospaced)

	// ESC/POS command for setting font size to 1 (normal size)
	const SIZE_1 = new Uint8Array([0x1b, 0x21, 0x00]); // ESC ! 0x00 sets font size to 1

	// ESC/POS command for enabling bold text
	const BOLD_ON = new Uint8Array([0x1b, 0x45, 0x01]); // ESC E 0x01 enables bold

	// ESC/POS command for disabling bold text
	const BOLD_OFF = new Uint8Array([0x1b, 0x45, 0x00]); // ESC E 0x00 disables bold

	try {
		// Select Font A (monospaced)
		printer.transferOut(outEndpoint.endpointNumber, FONT_A);

		// Set font size to 1 (normal size)
		printer.transferOut(outEndpoint.endpointNumber, SIZE_1);

		// Apply bold text if isBold is 1
		printer.transferOut(outEndpoint.endpointNumber, BOLD_ON);

		// Send the actual text to the printer
		printer.transferOut(outEndpoint.endpointNumber, text);

		console.log('Data sent to printer successfully.');
	} catch (error) {
		console.error('Error while sending data to the printer:', error);
		alert('Failed to send data to the printer!');
	}
};

const ESC_INIT = [0x1b, 0x40]; // Initialize printer command
const ESC_BIT_IMAGE = [0x1b, 0x2a]; // Command to send image data
const ESC_LINE_SPACING = [0x1b, 0x33, 0x00]; // Set line spacing to zero
const DOTS_DENSITY = 24; // Image density for slicing
const LUMINANCE = { RED: 0.299, GREEN: 0.587, BLUE: 0.114 };
const LINE_FEED = 0x0a; // Line feed command

// Function to calculate luminance of a pixel
function calculateLuminance(pixel) {
	return (
		LUMINANCE.RED * pixel[0] +
		LUMINANCE.GREEN * pixel[1] +
		LUMINANCE.BLUE * pixel[2]
	);
}

// Function to calculate an 8-bit slice for the image
function calculateSlice(x, y, image) {
	const threshold = 127;
	let slice = 0;

	for (let bit = 0; bit < 8; bit++) {
		if (y + bit >= image.length) continue;
		let luminance = calculateLuminance(image[y + bit][x]);
		slice |= (luminance < threshold ? 1 : 0) << (7 - bit);
	}

	return slice;
}

// Function to collect a stripe of slices for a given x and y
function collectStripe(x, y, image) {
	let slices = [];
	let z = y + DOTS_DENSITY;
	for (let i = 0; y < z && i < 3; i++, y += 8) {
		slices.push(calculateSlice(x, y, image));
	}
	return slices;
}

// Function to convert image into ESC/POS format
function manipulateImage(image) {
	let data = [];
	const imageWidth = image[0].length;

	for (let y = 0; y < image.length; y += DOTS_DENSITY) {
		data.push(
			...ESC_BIT_IMAGE,
			33,
			imageWidth & 0x00ff,
			(imageWidth & 0xff00) >> 8,
		);

		for (let x = 0; x < imageWidth; x++) {
			data.push(...collectStripe(x, y, image));
		}

		data.push(LINE_FEED);
	}

	return data;
}

// Function to print the image using ESC/POS commands
function printImage(image) {
	let transformedImage = [];
	transformedImage.push(...ESC_INIT); // Initialize printer
	transformedImage.push(...ESC_LINE_SPACING); // Set line spacing to 0
	transformedImage.push(...manipulateImage(image)); // Add manipulated image data
	return new Uint8Array(transformedImage);
}

// Function to scale the image to fit printer’s max width
function scaleImage(image, maxWidth) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const scaleFactor = maxWidth / image.width;
	const newHeight = Math.round(image.height * scaleFactor);

	canvas.width = maxWidth;
	canvas.height = newHeight;
	ctx.drawImage(image, 0, 0, maxWidth, newHeight);

	return canvas;
}

// Convert image to pixel data (2D array of [r, g, b])
function convertImageToPixelData(image) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	canvas.width = image.width;
	canvas.height = image.height;
	ctx.drawImage(image, 0, 0);

	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	const pixelData = [];

	for (let y = 0; y < imageData.height; y++) {
		const row = [];
		for (let x = 0; x < imageData.width; x++) {
			const index = (y * imageData.width + x) * 4;
			const r = imageData.data[index];
			const g = imageData.data[index + 1];
			const b = imageData.data[index + 2];
			row.push([r, g, b]);
		}
		pixelData.push(row);
	}

	return pixelData;
}

// Function to print QR/E-Invoice with centered alignment
async function printQRCodeBase64BitmapWithCentering(
	printer,
	base64Image,
	deviceAlignmentCode,
) {
	const img = new Image();
	img.onload = async () => {
		// Define the max width of the printer (48 character spaces)
		const LINE_CHARS = deviceAlignmentCode; // Number of character spaces in the paper width
		const maxCharWidth = 12; // Width of each character in px (can be adjusted based on your printer)
		const maxWidth = LINE_CHARS * maxCharWidth; // Max width of the printable area in pixels

		// Define the amount of the top and bottom to be cut (you can adjust these values)
		const topCut = 70; // Number of pixels to remove from the top
		const bottomCut = 70; // Number of pixels to remove from the bottom

		// Calculate the new height after removing top and bottom portions
		const newHeight = img.height - topCut - bottomCut;

		// Create a canvas to crop the image to keep only the middle portion
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		// Set the canvas size to match the cropped image
		canvas.width = img.width;
		canvas.height = newHeight;

		// Draw the middle portion of the image (cutting top and bottom)
		ctx.drawImage(
			img,
			0,
			topCut,
			img.width,
			newHeight,
			0,
			0,
			img.width,
			newHeight,
		);

		// Scale the cropped image to fit within the maxWidth
		const scaledImage = scaleImage(canvas, maxWidth);

		// Convert the scaled image to pixel data (2D array of [r, g, b] values)
		const pixelData = convertImageToPixelData(scaledImage);

		// Convert the pixel data to ESC/POS bitmap format
		const bitmap = printImage(pixelData);

		// Calculate the left padding to center the image
		const imageWidth = scaledImage.width; // Get the width of the scaled image
		const paddingLength = Math.floor((maxWidth - imageWidth) / 2); // Calculate left padding

		// Create an array of padding (empty space) to shift the image to the center
		const leftPadding = new Uint8Array(paddingLength).fill(0x00); // Padding with empty bytes (0x00)

		try {
			// Find the OUT endpoint for the printer
			const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
				ep => ep.direction === 'out',
			);

			if (!outEndpoint) {
				console.error('OUT endpoint not found!');
				return;
			}

			// Step 1: Reset Printer (ESC/POS Reset command)
			const ESC_POS_INIT = new Uint8Array([0x1b, 0x40]);
			await printer.transferOut(outEndpoint.endpointNumber, ESC_POS_INIT);
			console.log('Printer initialized.');

			// Step 2: Add the left padding (empty space) before the image
			await printer.transferOut(outEndpoint.endpointNumber, leftPadding);
			console.log(`Added left padding of length: ${paddingLength}`);

			// Step 3: Send the bitmap data (image)
			await printer.transferOut(
				outEndpoint.endpointNumber,
				new Uint8Array(bitmap),
			);
			console.log('Bitmap sent to printer.');
		} catch (error) {
			console.error('Error sending bitmap to printer:', error);
		}
	};
	img.src = base64Image;
}

function printLineWithDashes(printer, lineLength) {
	// Create a string of dashes of the specified length
	const dashedLine = '-'.repeat(lineLength);

	// Encode the string into a format the printer can use
	printTextBold(printer, new TextEncoder().encode(`${dashedLine}\n`));
}

export const printOrderReceiptUSBPOS = async (
	printer,
	logo,
	qrEInvoice,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	printerName,
	mode,
	isCash = false,
	receiptType,
	getTaxSchemeDetail,
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
	getUsersByAccountAndSoftware,
	officer,
	staff,
	getTaxSummary,
	deviceAlignmentCode,
	qrCodeImage,
) => {
	if (!printer || !printer.configuration || !printer.configuration.interfaces) {
		console.error('Invalid printer configuration!');
		return;
	}

	console.log('Printer initialized and configured correctly.');

	// ESC/POS command for setting line spacing
	const ESC_POS_LINE_SPACING = new Uint8Array([0x1b, 0x33, 0x20]); // Set line spacing to a smaller value (0x20 is just an example)

	// Initialize Printer (ESC/POS Reset command)
	const ESC_POS_INIT = new Uint8Array([0x1b, 0x40]);
	printText(printer, ESC_POS_INIT); // Reset the printer

	// Print Store Name Center Aligned
	const ESC_POS_CENTER = new Uint8Array([0x1b, 0x61, 0x01]);

	printTextBold(printer, ESC_POS_CENTER); // Align center
	printTextBold(
		printer,
		new TextEncoder().encode(`Welcome to ${outletName}\n`),
	);
	printTextBold(printer, ESC_POS_CENTER); // Align center
	printText(printer, ESC_POS_LINE_SPACING); // Set custom line spacing to reduce space
	printLineWithDashes(printer, deviceAlignmentCode);

	const printedOn = new Date();

	printText(printer, ESC_POS_INIT); // Reset the printer
	// printText(printer, ESC_POS_NORMAL_SPACING); // Reset to default line spacing
	// Adjust line spacing before printing the "Table: ..." line
	printTextBold(printer, new TextEncoder().encode(`Table: ${tableNo}\n`));
	printTextBold(
		printer,
		new TextEncoder().encode(
			`Printed on: ${format(printedOn, 'dd MMM yyyy, hh:mm a')}\n`,
		),
	);
	printTextBold(printer, new TextEncoder().encode(`Staff: ${userName}\n`));

	printTextBold(printer, ESC_POS_CENTER); // Align center
	printLineWithDashes(printer, deviceAlignmentCode);

	try {
		await printQRCodeBase64BitmapWithCentering(
			printer,
			qrCodeImage,
			deviceAlignmentCode,
		);
		await new Promise(resolve => setTimeout(resolve, 700)); // Adjust delay time as needed (500ms)
	} catch (error) {
		console.error('Error fetching and processing the image:', error);
	}
	printText(printer, ESC_POS_INIT); // Reset the printer

	printTextBold(printer, ESC_POS_CENTER); // Align center
	printTextBold(printer, new TextEncoder().encode(`Scan the QR to Order\n`));
	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, new TextEncoder().encode(`\n`));

	// Only cut the paper once all print jobs are completed
	const ESC_POS_CUT = new Uint8Array([0x1d, 0x56, 0x00]);
	await printText(printer, ESC_POS_CUT); // Send paper cut command here
};
