import { convertDate24Hrs } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import {
	OrderItemDiscountStatus,
	OrderDiscountStatus,
	PaymentClass,
} from 'generated/graphql';
import BigNumber from 'bignumber.js';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
const dateFormat = require('dateformat');

// Function to send text to the printer with Font A (monospaced) and size 1, with optional bold (0 or 1)
export const printText = (printer, text) => {
	const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
		ep => ep.direction === 'out',
	);

	if (!outEndpoint) {
		console.error('OUT endpoint not found!');
		alert('Failed to find OUT endpoint');
		return;
	}

	console.log('OUT endpoint found:', outEndpoint);
	console.log('Sending data to printer:', text);

	// ESC/POS command for selecting Font A (monospaced)
	const FONT_A = new Uint8Array([0x1b, 0x74, 0x00]); // ESC t 0x00 selects Font A (monospaced)

	// ESC/POS command for setting font size to 1 (normal size)
	const SIZE_1 = new Uint8Array([0x1b, 0x21, 0x00]); // ESC ! 0x00 sets font size to 1

	try {
		// Select Font A (monospaced)
		printer.transferOut(outEndpoint.endpointNumber, FONT_A);

		// Set font size to 1 (normal size)
		printer.transferOut(outEndpoint.endpointNumber, SIZE_1);

		// Send the actual text to the printer
		printer.transferOut(outEndpoint.endpointNumber, text);

		console.log('Data sent to printer successfully.');
	} catch (error) {
		console.error('Error while sending data to the printer:', error);
		alert('Failed to send data to the printer!');
	}
};

// Function to send text to the printer with Font A (monospaced) and size 1, with optional bold (0 or 1)
export const printTextBold = (printer, text) => {
	const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
		ep => ep.direction === 'out',
	);

	if (!outEndpoint) {
		console.error('OUT endpoint not found!');
		alert('Failed to find OUT endpoint');
		return;
	}

	console.log('OUT endpoint found:', outEndpoint);
	console.log('Sending data to printer:', text);

	// ESC/POS command for selecting Font A (monospaced)
	const FONT_A = new Uint8Array([0x1b, 0x74, 0x00]); // ESC t 0x00 selects Font A (monospaced)

	// ESC/POS command for setting font size to 1 (normal size)
	const SIZE_1 = new Uint8Array([0x1b, 0x21, 0x00]); // ESC ! 0x00 sets font size to 1

	// ESC/POS command for enabling bold text
	const BOLD_ON = new Uint8Array([0x1b, 0x45, 0x01]); // ESC E 0x01 enables bold

	// ESC/POS command for disabling bold text
	const BOLD_OFF = new Uint8Array([0x1b, 0x45, 0x00]); // ESC E 0x00 disables bold

	try {
		// Select Font A (monospaced)
		printer.transferOut(outEndpoint.endpointNumber, FONT_A);

		// Set font size to 1 (normal size)
		printer.transferOut(outEndpoint.endpointNumber, SIZE_1);

		// Apply bold text if isBold is 1
		printer.transferOut(outEndpoint.endpointNumber, BOLD_ON);

		// Send the actual text to the printer
		printer.transferOut(outEndpoint.endpointNumber, text);

		console.log('Data sent to printer successfully.');
	} catch (error) {
		console.error('Error while sending data to the printer:', error);
		alert('Failed to send data to the printer!');
	}
};

const ESC_INIT = [0x1b, 0x40]; // Initialize printer command
const ESC_BIT_IMAGE = [0x1b, 0x2a]; // Command to send image data
const ESC_LINE_SPACING = [0x1b, 0x33, 0x00]; // Set line spacing to zero
const DOTS_DENSITY = 24; // Image density for slicing
const LUMINANCE = { RED: 0.299, GREEN: 0.587, BLUE: 0.114 };
const LINE_FEED = 0x0a; // Line feed command

// Function to calculate luminance of a pixel
function calculateLuminance(pixel) {
	return (
		LUMINANCE.RED * pixel[0] +
		LUMINANCE.GREEN * pixel[1] +
		LUMINANCE.BLUE * pixel[2]
	);
}

// Function to calculate an 8-bit slice for the image
function calculateSlice(x, y, image) {
	const threshold = 127;
	let slice = 0;

	for (let bit = 0; bit < 8; bit++) {
		if (y + bit >= image.length) continue;
		let luminance = calculateLuminance(image[y + bit][x]);
		slice |= (luminance < threshold ? 1 : 0) << (7 - bit);
	}

	return slice;
}

// Function to collect a stripe of slices for a given x and y
function collectStripe(x, y, image) {
	let slices = [];
	let z = y + DOTS_DENSITY;
	for (let i = 0; y < z && i < 3; i++, y += 8) {
		slices.push(calculateSlice(x, y, image));
	}
	return slices;
}

// Function to convert image into ESC/POS format
function manipulateImage(image) {
	let data = [];
	const imageWidth = image[0].length;

	for (let y = 0; y < image.length; y += DOTS_DENSITY) {
		data.push(
			...ESC_BIT_IMAGE,
			33,
			imageWidth & 0x00ff,
			(imageWidth & 0xff00) >> 8,
		);

		for (let x = 0; x < imageWidth; x++) {
			data.push(...collectStripe(x, y, image));
		}

		data.push(LINE_FEED);
	}

	return data;
}

// Function to print the image using ESC/POS commands
function printImage(image) {
	let transformedImage = [];
	transformedImage.push(...ESC_INIT); // Initialize printer
	transformedImage.push(...ESC_LINE_SPACING); // Set line spacing to 0
	transformedImage.push(...manipulateImage(image)); // Add manipulated image data
	return new Uint8Array(transformedImage);
}

// Function to scale the image to fit printer’s max width
function scaleImage(image, maxWidth) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const scaleFactor = maxWidth / image.width;
	const newHeight = Math.round(image.height * scaleFactor);

	canvas.width = maxWidth;
	canvas.height = newHeight;
	ctx.drawImage(image, 0, 0, maxWidth, newHeight);

	return canvas;
}

// Convert image to pixel data (2D array of [r, g, b])
function convertImageToPixelData(image) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');
	canvas.width = image.width;
	canvas.height = image.height;
	ctx.drawImage(image, 0, 0);

	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
	const pixelData = [];

	for (let y = 0; y < imageData.height; y++) {
		const row = [];
		for (let x = 0; x < imageData.width; x++) {
			const index = (y * imageData.width + x) * 4;
			const r = imageData.data[index];
			const g = imageData.data[index + 1];
			const b = imageData.data[index + 2];
			row.push([r, g, b]);
		}
		pixelData.push(row);
	}

	return pixelData;
}

// Function to print QR/E-Invoice with centered alignment
async function printQREInvoiceBase64BitmapWithCentering(
	printer,
	base64Image,
	deviceAlignmentCode,
) {
	const img = new Image();
	img.onload = async () => {
		// Define the max width of the printer (48 character spaces)
		const LINE_CHARS = deviceAlignmentCode; // Number of character spaces in the paper width
		const maxCharWidth = 12; // Width of each character in px (can be adjusted based on your printer)
		const maxWidth = LINE_CHARS * maxCharWidth; // Max width of the printable area in pixels

		// Define the amount of the top and bottom to be cut (you can adjust these values)
		const topCut = 80; // Number of pixels to remove from the top
		const bottomCut = 80; // Number of pixels to remove from the bottom

		// Calculate the new height after removing top and bottom portions
		const newHeight = img.height - topCut - bottomCut;

		// Create a canvas to crop the image to keep only the middle portion
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		// Set the canvas size to match the cropped image
		canvas.width = img.width;
		canvas.height = newHeight;

		// Draw the middle portion of the image (cutting top and bottom)
		ctx.drawImage(
			img,
			0,
			topCut,
			img.width,
			newHeight,
			0,
			0,
			img.width,
			newHeight,
		);

		// Scale the cropped image to fit within the maxWidth
		const scaledImage = scaleImage(canvas, maxWidth);

		// Convert the scaled image to pixel data (2D array of [r, g, b] values)
		const pixelData = convertImageToPixelData(scaledImage);

		// Convert the pixel data to ESC/POS bitmap format
		const bitmap = printImage(pixelData);

		// Calculate the left padding to center the image
		const imageWidth = scaledImage.width; // Get the width of the scaled image
		const paddingLength = Math.floor((maxWidth - imageWidth) / 2); // Calculate left padding

		// Create an array of padding (empty space) to shift the image to the center
		const leftPadding = new Uint8Array(paddingLength).fill(0x00); // Padding with empty bytes (0x00)

		try {
			// Find the OUT endpoint for the printer
			const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
				ep => ep.direction === 'out',
			);

			if (!outEndpoint) {
				console.error('OUT endpoint not found!');
				return;
			}

			// Step 1: Reset Printer (ESC/POS Reset command)
			const ESC_POS_INIT = new Uint8Array([0x1b, 0x40]);
			await printer.transferOut(outEndpoint.endpointNumber, ESC_POS_INIT);
			console.log('Printer initialized.');

			// Step 2: Add the left padding (empty space) before the image
			await printer.transferOut(outEndpoint.endpointNumber, leftPadding);
			console.log(`Added left padding of length: ${paddingLength}`);

			// Step 3: Send the bitmap data (image)
			await printer.transferOut(
				outEndpoint.endpointNumber,
				new Uint8Array(bitmap),
			);
			console.log('Bitmap sent to printer.');
		} catch (error) {
			console.error('Error sending bitmap to printer:', error);
		}
	};
	img.src = base64Image;
}

async function printOUtletLogoBase64BitmapWithCentering(
	printer,
	base64Image,
	deviceAlignmentCode,
) {
	const img = new Image();
	img.onload = async () => {
		// Define the max width of the printer (48 character spaces)
		const LINE_CHARS = deviceAlignmentCode; // Number of character spaces in the paper width
		const maxCharWidth = 12; // Width of each character in px (can be adjusted based on your printer)
		const maxWidth = LINE_CHARS * maxCharWidth; // Max width of the printable area in pixels

		// Define the amount of the top and bottom to be cut (you can adjust these values)
		const topCut = 60; // Number of pixels to remove from the top
		const bottomCut = 60; // Number of pixels to remove from the bottom

		// Calculate the new height after removing top and bottom portions
		const newHeight = img.height - topCut - bottomCut;

		// Create a canvas to crop the image to keep only the middle portion
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		// Set the canvas size to match the cropped image
		canvas.width = img.width;
		canvas.height = newHeight;

		// Draw the middle portion of the image (cutting top and bottom)
		ctx.drawImage(
			img,
			0,
			topCut,
			img.width,
			newHeight,
			0,
			0,
			img.width,
			newHeight,
		);

		// Scale the cropped image to fit within the maxWidth
		const scaledImage = scaleImage(canvas, maxWidth);

		// Convert the scaled image to pixel data (2D array of [r, g, b] values)
		const pixelData = convertImageToPixelData(scaledImage);

		// Convert the pixel data to ESC/POS bitmap format
		const bitmap = printImage(pixelData);

		// Calculate the left padding to center the image
		const imageWidth = scaledImage.width; // Get the width of the scaled image
		const paddingLength = Math.floor((maxWidth - imageWidth) / 2); // Calculate left padding

		// Create an array of padding (empty space) to shift the image to the center
		const leftPadding = new Uint8Array(paddingLength).fill(0x00); // Padding with empty bytes (0x00)

		try {
			// Find the OUT endpoint for the printer
			const outEndpoint = printer.configuration.interfaces[0]?.alternates[0]?.endpoints.find(
				ep => ep.direction === 'out',
			);

			if (!outEndpoint) {
				console.error('OUT endpoint not found!');
				return;
			}

			// Step 1: Reset Printer (ESC/POS Reset command)
			const ESC_POS_INIT = new Uint8Array([0x1b, 0x40]);
			await printer.transferOut(outEndpoint.endpointNumber, ESC_POS_INIT);
			console.log('Printer initialized.');

			// Step 2: Add the left padding (empty space) before the image
			await printer.transferOut(outEndpoint.endpointNumber, leftPadding);
			console.log(`Added left padding of length: ${paddingLength}`);

			// Step 3: Send the bitmap data (image)
			await printer.transferOut(
				outEndpoint.endpointNumber,
				new Uint8Array(bitmap),
			);
			console.log('Bitmap sent to printer.');
		} catch (error) {
			console.error('Error sending bitmap to printer:', error);
		}
	};
	img.src = base64Image;
}

const handleCardType = cardType => {
	switch (cardType) {
		case 'VISA_CARD':
			return 'VISA';
		case 'MASTERCARD':
			return 'MASTERCARD';
		case 'AMERICAN_EXPRESS_CARD':
			return 'AMERICAN EXPRESS';
		case 'DINERS_CARD':
			return 'DINERS';
		case 'JCB_CARD':
			return 'JCB';
		case 'UNIONPAY_CARD':
			return 'UNIONPAY';
		case 'DEBIT_CARD':
			return 'DEBIT';
		default:
			return 'Credit';
	}
};

function createReceiptLine(
	leftText,
	rightText,
	printer,
	LINE_CHARS,
	isBold,
	newline,
) {
	let paddingForBill = LINE_CHARS - leftText.length - rightText.length;
	paddingForBill = ' '.repeat(paddingForBill);

	const printMethod = isBold === 1 ? printTextBold : printText;

	printMethod(
		printer,
		new TextEncoder().encode(
			`${leftText}${paddingForBill}${rightText}${newline}`,
		),
	);
}

export const printBillReceiptUSBPOS = async (
	printer,
	logo,
	qrEInvoice,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	printerName,
	mode,
	isCash = false,
	receiptType,
	getTaxSchemeDetail,
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
	getUsersByAccountAndSoftware,
	officer,
	staff,
	getTaxSummary,
	deviceAlignmentCode,
) => {
	if (!printer || !printer.configuration || !printer.configuration.interfaces) {
		console.error('Invalid printer configuration!');
		return;
	}

	console.log('Printer initialized and configured correctly.');

	const activeOrderDiscount = data[0]?.order?.orderDiscount?.filter(
		y => y?.status === OrderDiscountStatus?.Active,
	)[0];

	const OCENT = discount?.filter(
		x => x?.ID === activeOrderDiscount?.discountID,
	)[0]?.discountType;

	const displayOCENT =
		OCENT === 'OFFICER_CHECK'
			? 'Officer Check'
			: OCENT === 'ENTERTAINMENT'
			? 'Entertainment'
			: OCENT === 'DISCOUNT'
			? 'Discount'
			: 'Promo';

	let roundAmount = 0;
	latestPayment.forEach(x => {
		roundAmount += x?.roundingAmount;
	});
	const company = data?.outlet?.company;
	const qrCodeUrl = data?.qrCodeUrl;

	const Einvoice = qrCodeUrl !== null && data?.is_einvoice === true;

	const condition =
		data?.is_einvoice === true &&
		company?.isEInvIntegrated === true &&
		dateFormat(data?.createdTs, 'yyyy-mm-dd') >=
			dateFormat(company?.eInvStartDate, 'yyyy-mm-dd') &&
		dateFormat(data?.createdTs, 'yyyy-mm-dd') <=
			dateFormat(company?.eInvEndDate, 'yyyy-mm-dd') &&
		qrCodeUrl !== null;

	// Wait for the QR code image to finish printing
	await printOUtletLogoBase64BitmapWithCentering(
		printer,
		logo,
		deviceAlignmentCode,
	);

	// Deliberate delay before cutting the paper (to ensure all print jobs are completed)
	await new Promise(resolve => setTimeout(resolve, 300)); // Adjust delay time as needed (500ms)

	// Initialize Printer (ESC/POS Reset command)
	const ESC_POS_INIT = new Uint8Array([0x1b, 0x40]);
	printText(printer, ESC_POS_INIT); // Reset the printer
	console.log('Printer initialized successfully.');

	// Print Store Name Center Aligned
	const ESC_POS_CENTER = new Uint8Array([0x1b, 0x61, 0x01]);

	const outletPoscode =
		outlet?.address?.postCode !== null ||
		outlet?.address?.postCode !== undefined
			? outlet?.address?.postCode + ','
			: '';

	const outletCity =
		outlet?.address?.city !== null || outlet?.address?.city !== undefined
			? outlet?.address?.city + ','
			: '';

	const outletState =
		outlet?.address?.state !== null || outlet?.address?.state !== undefined
			? outlet?.address?.state
			: '';

	printText(printer, ESC_POS_CENTER); // Align center
	printText(printer, new TextEncoder().encode(`${outlet?.address?.address}\n`));

	printText(printer, ESC_POS_CENTER); // Align center
	printText(
		printer,
		new TextEncoder().encode(`${outletPoscode}${outletCity}${outletState}.\n`),
	);

	printText(printer, ESC_POS_CENTER); // Align center
	printText(printer, new TextEncoder().encode(`${outlet?.contactNo}\n`));

	printText(printer, ESC_POS_CENTER); // Align center
	printText(
		printer,
		new TextEncoder().encode(`(SST Reg No: ${outlet?.taxRegistrationNo})\n\n`),
	);

	printTextBold(printer, ESC_POS_CENTER); // Align center
	printTextBold(printer, new TextEncoder().encode(`${outlet?.name}\n\n`));

	const ESC_POS_LEFT = new Uint8Array([0x1b, 0x61, 0x00]); // Align left
	printText(printer, ESC_POS_LEFT);
	// Print Items with Left Alignment and Price Right Aligned
	const LINE_CHARS = deviceAlignmentCode;

	createReceiptLine(
		`Bill No: ${data?.billNo}`,
		`${convertDate24Hrs(data?.createdTs)}`,
		printer,
		LINE_CHARS,
		1,
		`\n\n`,
	);

	const patronClass = data?.patron?.patronClass
		? data?.patron?.patronClass
		: data?.order?.patron?.patronClass;

	if (page === 'void-bill') {
		printText(printer, new TextEncoder().encode(`Voided Bill\n`));
	} else {
		if (patronClass === 'Staff') {
			printText(
				printer,
				new TextEncoder().encode(`Staff       : ${patronName}\n`),
			);
		} else if (patronClass === 'Officer') {
			printText(
				printer,
				new TextEncoder().encode(`Officer     : ${patronName}\n`),
			);
		} else if (patronClass === 'Visitor') {
			printText(
				printer,
				new TextEncoder().encode(`Visitor     : ${patronName ?? '-'}\n`),
			);
		} else if (patronClass === 'Hotel Guest') {
			printText(
				printer,
				new TextEncoder().encode(`Hotel Guest : ${patronName}\n`),
			);
		} else if (patronClass === 'Member') {
			printText(printer, new TextEncoder().encode(`Member\n`));
		}
	}

	//table
	if (page !== 'void-bill') {
		printText(
			printer,
			new TextEncoder().encode(
				`Table       : ${
					page === 'bill-settlement'
						? data?.table?.prefix + data?.table?.tableNumber
						: data?.order?.table?.prefix + data?.order?.table?.tableNumber
				}\n`,
			),
		);
	}

	// cover
	if (page !== 'void-bill' || data?.order?.serviceMode === 'DINE_IN') {
		printText(
			printer,
			new TextEncoder().encode(
				`Cover       : ${
					page === 'bill-settlement' ? data?.pax : data?.order?.pax
				}\n`,
			),
		);
	}

	if (page !== 'void-bill') {
		printText(
			printer,
			new TextEncoder().encode(
				`Cashier     : ${
					getUsersByAccountAndSoftware?.filter(
						x => x?.ID === data?.modBy || x?.ID === data?.createdBy,
					)[0]?.name
				}\n\n`,
			),
		);
	}

	// order item (oi)
	{
		orderItem.forEach(oi => {
			// oi name & quantity

			let totalOITax = 0;
			oi.orderItemTax.forEach(x => {
				totalOITax += x?.taxAmount;
			});

			if (oi?.isTakeaway === true) {
				createReceiptLine(
					`${oi?.openItemName ?? oi?.menuItem?.name} (T) x${oi?.quantity -
						(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
					`${currencyCode}${amtStr(
						new BigNumber(oi?.amount)
							.plus(totalOITax)
							.minus(
								(negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
							)
							.toNumber(),
					)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n\n`));
			} else if (oi?.isTakeaway === false) {
				createReceiptLine(
					`${oi?.openItemName ?? oi?.menuItem?.name} x${oi?.quantity -
						(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
					`${currencyCode}${amtStr(
						new BigNumber(oi?.amount)
							.plus(totalOITax)
							.minus(
								(negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
							)
							.toNumber(),
					)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n\n`));
			}

			if (oi?.orderItemOption?.length > 0) {
				// order item option (oio)
				oi.orderItemOption.forEach(oiOption => {
					oiOption.orderItemOptionItem.forEach(oiOptionItem => {
						// oio name and amount

						let totalTax = 0;
						oiOptionItem.orderItemOptionItemTax.forEach(x => {
							totalTax += x?.taxAmount;
						});
						createReceiptLine(
							`${oiOptionItem?.optionItem?.name}`,
							`${
								oiOptionItem?.price === 0
									? ''
									: currencyCode +
									  amtStr(
											Math.abs(
												(oiOptionItem?.basePrice + totalTax) *
													((oi?.quantity -
														(negOrderItem?.[oiOption?.orderItemID]?.quantity ??
															0)) /
														oi?.quantity),
											),
									  )
							}`,
							printer,
							LINE_CHARS,
							0,
							``,
						);
						printText(printer, new TextEncoder().encode(`\n\n`));
					});
				});
			} else if (oi?.orderItemSetMenuOption?.length > 0) {
				// order item set menu option (oismo)
				oi.orderItemSetMenuOption.forEach(oiSetMenuOption => {
					oiSetMenuOption.orderItemSetMenuOptionItem.forEach(
						// oismo name
						async oiSetMenuOptionItem => {
							printText(
								printer,
								new TextEncoder().encode(
									`${oiSetMenuOptionItem?.setMenuOptionItem?.menuItem?.name}`,
								),
							);
							printText(printer, new TextEncoder().encode(`\n\n`));
						},
					);
				});
			}

			// order item discount (oid)
			const activeOrderItemDiscount = oi?.orderItemDiscount?.filter(
				y => y?.status === OrderItemDiscountStatus?.Active,
			)[0];

			let totalOIDTax = 0;
			oi.orderItemDiscountTax.forEach(x => {
				totalOIDTax += x?.taxAmount;
			});

			let sumNegOptDisc = 0;

			if (activeOrderItemDiscount) {
				createReceiptLine(
					`Discount: ${
						discount?.filter(
							x => x?.ID === activeOrderItemDiscount?.discountID,
						)[0]?.code
					}`,
					`(${currencyCode}${amtStr(
						activeOrderItemDiscount?.baseAmount +
							totalOIDTax -
							(negOrderItem?.[orderItem?.ID]?.orderItemDiscBaseAmount ?? 0) -
							(negOrderItem?.[orderItem?.ID]?.orderItemDiscTaxAmount ?? 0) +
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === orderItem?.ID,
							)[0]?.discountBaseAmount ?? 0) +
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === orderItem?.ID,
							)[0]?.itemDiscountTaxAmount ?? 0) -
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === orderItem?.ID,
							)[0]?.discountItemTaxAmount ?? 0) -
							sumNegOptDisc,

						// calcDiscountAmount(orderItem),
					)})\n`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n\n`));
			}
		});
	}

	// Subtotal
	createReceiptLine(
		'Sub-Total',
		`${currencyCode + amtStr(footerToken?.subtotal)}`,
		printer,
		LINE_CHARS,
		0,
		``,
	);
	printText(printer, new TextEncoder().encode(`\n`));

	// Discount
	const activeOrderDiscountFooter =
		page === 'bill-settlement'
			? data?.orderDiscount?.filter(
					y => y?.status === OrderDiscountStatus?.Active,
			  )[0]
			: data?.order?.orderDiscount?.filter(
					y => y?.status === OrderDiscountStatus?.Active,
			  )[0];
	createReceiptLine(
		`Discount: ${discount?.filter(
			x => x?.ID === activeOrderDiscountFooter?.discountID,
		)[0]?.code ?? ' '}`,
		`(${currencyCode + amtStr(footerToken?.discount)})`,
		printer,
		LINE_CHARS,
		0,
		``,
	);
	printText(printer, new TextEncoder().encode(`\n`));

	// Rounding
	let rounding = page === 'bill-settlement' ? footerToken?.rounding : 0;
	if (page === 'bill-settlement') {
		if (rounding < 0) {
			createReceiptLine(
				'Rounding',
				`(${currencyCode}${Math.abs(rounding).toFixed(2)})`,
				printer,
				LINE_CHARS,
				0,
				``,
			);
			printText(printer, new TextEncoder().encode(`\n\n`));
		} else {
			createReceiptLine(
				'Rounding',
				`${currencyCode}${amtStr(rounding)}`,
				printer,
				LINE_CHARS,
				0,
				``,
			);
			printText(printer, new TextEncoder().encode(`\n\n`));
		}
	} else {
		if (roundAmount < 0) {
			createReceiptLine(
				'Rounding',
				`(${currencyCode}${Math.abs(rounding).toFixed(2)})`,
				printer,
				LINE_CHARS,
				0,
				``,
			);
			printText(printer, new TextEncoder().encode(`\n\n`));
		} else {
			createReceiptLine(
				'Rounding',
				`${currencyCode}${amtStr(rounding)}`,
				printer,
				LINE_CHARS,
				0,
				``,
			);
			printText(printer, new TextEncoder().encode(`\n\n`));
		}
	}

	// Total
	if (page === 'bill-settlement') {
		createReceiptLine(
			'Total',
			`${currencyCode}${amtStr(
				new BigNumber(Math.abs(footerToken?.total)).toNumber(),
			)}`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
		printText(printer, new TextEncoder().encode(`\n\n`));
	} else {
		createReceiptLine(
			'Total',
			`${currencyCode}${amtStr(
				new BigNumber(Math.abs(footerToken?.total))
					.plus(roundAmount ?? 0)
					.toNumber(),
			)}`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
		printText(printer, new TextEncoder().encode(`\n\n`));
	}

	// Tax
	const sortedTaxScheme = getTaxSchemeDetail?.sort((a, b) => {
		if (a.taxType < b.taxType) return -1;
		if (a.taxType > b.taxType) return 1;

		if (a.taxRate < b.taxRate) return -1;
		if (a.taxRate > b.taxRate) return 1;

		return 0;
	});
	const taxSchemeFooterInfo =
		mode === 'bill-settlement'
			? data?.taxSchemeFooterInfo
			: data?.order?.taxSchemeFooterInfo;
	const taxAmount = (taxType, taxRate) => {
		const tax = taxSchemeFooterInfo
			?.filter(x => x?.name === taxType)
			?.filter(v => parseInt(v?.rate) === taxRate)[0];

		return tax?.sum;
	};
	if (sortedTaxScheme?.length > 0) {
		sortedTaxScheme.forEach(x => {
			createReceiptLine(
				`${x?.taxCode} (${x?.taxRate}%)`,
				`${currencyCode}${amtStr(Number(taxAmount(x?.taxType, x?.taxRate)))}`,
				printer,
				LINE_CHARS,
				0,
				``,
			);
			printText(printer, new TextEncoder().encode(`\n`));
		});
		printText(printer, new TextEncoder().encode(`\n`));
	}

	// Bill Settlement Sign
	if (page === 'bill-settlement') {
		printText(printer, new TextEncoder().encode(`\n`));
		// Name
		const leftTextName = `Name : ___________________________________`;
		const rightTextName = ``;
		let paddingName = LINE_CHARS - leftTextName.length - rightTextName.length;
		paddingName = ' '.repeat(paddingName);
		printText(
			printer,
			new TextEncoder().encode(`${leftTextName}${paddingName}${rightTextName}`),
		);
		printText(printer, new TextEncoder().encode(`\n\n`));

		// Room/ACC
		const leftTextRoom = `Room/ACC : _______________________________`;
		const rightTextRoom = ``;
		let paddingRoom = LINE_CHARS - leftTextName.length - rightTextName.length;
		paddingRoom = ' '.repeat(paddingRoom);
		printText(
			printer,
			new TextEncoder().encode(`${leftTextRoom}${paddingRoom}${rightTextRoom}`),
		);
		printText(printer, new TextEncoder().encode(`\n\n`));

		// Signature
		const leftTextSignature = `Signature : ______________________________`;
		const rightTextSignature = ``;
		let paddingSignature =
			LINE_CHARS - leftTextSignature.length - rightTextSignature.length;
		paddingSignature = ' '.repeat(paddingSignature);
		printText(
			printer,
			new TextEncoder().encode(
				`${leftTextSignature}${paddingSignature}${rightTextSignature}`,
			),
		);
		printText(printer, new TextEncoder().encode(`\n\n`));
	}

	if (page === 'void-bill' || page === 'bill-settlement') {
	} else {
		printTextBold(printer, new TextEncoder().encode(`Payment\n`));

		latestPayment.forEach(el => {
			if (el?.paymentClass === PaymentClass.UnsettledBill) {
				// Unsettled Bill
				createReceiptLine(
					`Unsettled Bill`,
					`${currencyCode}${amtStr(el?.amount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			} else if (el?.paymentClass === PaymentClass.Credit) {
				// Credit
				createReceiptLine(
					`${handleCardType(el?.creditCardType)}`,
					`${currencyCode}${amtStr(el?.receivedAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			} else if (el?.paymentClass === PaymentClass.Online) {
				// Online Payment
				createReceiptLine(
					`Online Payment`,
					`${currencyCode}${amtStr(el?.receivedAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			} else if (el?.paymentClass === PaymentClass.NonGuest) {
				// Non Guest
				createReceiptLine(
					`Non Guest`,
					`${currencyCode}${amtStr(el?.receivedAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			} else if (el?.paymentClass === PaymentClass.SuspendFolio) {
				// Suspend Folio
				createReceiptLine(
					`Suspend Folio`,
					`${currencyCode}${amtStr(el?.receivedAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			} else if (el?.paymentClass === PaymentClass.BankTt) {
				// Bank TT
				createReceiptLine(
					`Bank TT`,
					`${currencyCode}${amtStr(el?.receivedAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			} else {
				// Payment
				createReceiptLine(
					`${toProperCase(el?.paymentClass)}`,
					`${currencyCode}${amtStr(el?.receivedAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (el?.paymentClass === PaymentClass.Room) {
				// Room
				createReceiptLine(
					`  Charged to`,
					`${el?.hotelGuestInfo?.RoomNo} | ${el?.hotelGuestInfo?.FullName}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (
				el?.paymentClass === PaymentClass.NonGuest ||
				el?.paymentClass === PaymentClass.SuspendFolio
			) {
				// NonGuest || SuspendFolio
				printTextBold(printer, new TextEncoder().encode(`  Charged to:\n`));
				printText(
					printer,
					new TextEncoder().encode(`  ${el?.hotelGuestInfo?.FullName}\n`),
				);
			}

			if (el?.paymentClass === PaymentClass.Debtor) {
				// Debtor
				createReceiptLine(
					`  Charged to`,
					`${el?.hotelGuestInfo?.GuestType} | ${el?.hotelGuestInfo?.FullName}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (el?.paymentClass === PaymentClass.Staff) {
				// Staff
				createReceiptLine(
					`  Charged to`,
					`${staff[0]?.designation} | ${staff[0]?.name}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (el?.paymentClass === PaymentClass.Officer) {
				// Officer
				createReceiptLine(
					`  Charged to`,
					`${officer[0]?.designation} | ${officer[0]?.name}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (
				el?.paymentClass === PaymentClass.BankTt ||
				el?.paymentClass === PaymentClass.Cheque ||
				el?.paymentClass === PaymentClass.Online ||
				el?.paymentClass === PaymentClass.Skipper ||
				el?.paymentClass === PaymentClass.Member
			) {
				// Remark
				createReceiptLine(
					`  Remark: `,
					`${el?.remark ?? '-'}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (el?.paymentClass === PaymentClass.Voucher) {
				// Code
				createReceiptLine(
					`  Code: `,
					`${el?.referenceNo ?? '-'}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (el?.paymentClass === PaymentClass.Others) {
				// Remark
				createReceiptLine(
					`  Remark: `,
					`${el?.referenceNo ?? '-'}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}

			if (el?.paymentClass === PaymentClass.Credit) {
				// CardRef
				createReceiptLine(
					`  Card ref: `,
					`${`****${el?.cardNo?.slice(-4)}` ?? '-'}  `,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));

				if (el?.hotelGuestInfo?.CompanyName !== undefined) {
					// Card Info
					createReceiptLine(
						`  Charged to: `,
						`${el?.hotelGuestInfo?.DebtorAccount}${' | '}${
							el?.hotelGuestInfo?.CompanyName
						}`,
						printer,
						LINE_CHARS,
						0,
						``,
					);
					printText(printer, new TextEncoder().encode(`\n`));
				}
			}

			if (el?.paymentClass === PaymentClass.Cash && el?.changeAmount > 0) {
				// Cash Change
				createReceiptLine(
					`  Change: `,
					`${currencyCode}${amtStr(el?.changeAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			}
		});

		if (latestPayment?.length === 0) {
			//OC || ENT
			const OCENT = discount?.filter(
				x => x?.ID === activeOrderDiscount?.discountID,
			)[0]?.discountType;

			const displayOCENT =
				OCENT === 'OFFICER_CHECK'
					? 'Officer Check'
					: OCENT === 'ENTERTAINMENT'
					? 'Entertainment'
					: OCENT === 'DISCOUNT'
					? 'Discount'
					: 'Promo';

			createReceiptLine(
				`${displayOCENT}`,
				`${currencyCode}${amtStr(latestPayment?.amount)}`,
				printer,
				LINE_CHARS,
				0,
				``,
			);
			printText(printer, new TextEncoder().encode(`\n`));
		}

		// Tax Summary
		const totalAmount = getTaxSummary?.reduce((a, b) => {
			return a + b?.amount;
		}, 0);

		const totalTaxAmount = getTaxSummary?.reduce((a, b) => {
			return a + b?.taxAmount;
		}, 0);

		printText(printer, new TextEncoder().encode(`\n`));
		printTextBold(printer, new TextEncoder().encode(`SST Summary\n`));

		createReceiptLine(
			`Tax Cd`,
			`Amt Excl SST(RM) Tax(RM)`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
		printText(printer, new TextEncoder().encode(`\n`));

		if (getTaxSummary?.length > 0) {
			getTaxSummary.forEach(x => {
				createReceiptLine(
					`${x?.code}`,
					`${amtStr(x?.amount)}       ${amtStr(x?.taxAmount)}`,
					printer,
					LINE_CHARS,
					0,
					``,
				);
				printText(printer, new TextEncoder().encode(`\n`));
			});
		}

		createReceiptLine(
			`Total`,
			`${amtStr(totalAmount)}       ${amtStr(totalTaxAmount)}`,
			printer,
			LINE_CHARS,
			1,
			``,
		);
		printText(printer, new TextEncoder().encode(`\n`));
	}

	printText(printer, new TextEncoder().encode(`\n`));

	// Wait for the QR code image to finish printing
	if (condition === true) {
		await printQREInvoiceBase64BitmapWithCentering(
			printer,
			qrEInvoice,
			deviceAlignmentCode,
		);
		await new Promise(resolve => setTimeout(resolve, 700)); // Adjust delay time as needed (500ms)

		printText(printer, ESC_POS_INIT); // Reset the printer

		printText(printer, new TextEncoder().encode(`\n`));
		printText(printer, ESC_POS_CENTER); // Align center
		printText(printer, new TextEncoder().encode(`Scan within 72 hours if\n`));
		printText(printer, ESC_POS_CENTER); // Align center
		printText(printer, new TextEncoder().encode(`e-Invoice is required\n`));
	}

	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, ESC_POS_CENTER); // Align center
	printText(
		printer,
		new TextEncoder().encode(`Thank you & we hope to see you again soon.\n`),
	);
	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, new TextEncoder().encode(`\n`));
	printText(printer, new TextEncoder().encode(`\n`));

	// Only cut the paper once all print jobs are completed
	const ESC_POS_CUT = new Uint8Array([0x1d, 0x56, 0x00]);
	await printText(printer, ESC_POS_CUT); // Send paper cut command here
};
