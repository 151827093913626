import { convertDate24Hrs } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import {
	OrderItemDiscountStatus,
	OrderDiscountStatus,
	PaymentClass,
} from 'generated/graphql';
import BigNumber from 'bignumber.js';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { format } from 'date-fns';

const dateFormat = require('dateformat');

const ESC_INIT = [0x1b, 0x40]; // Initialize printer command
const ESC_BIT_IMAGE = [0x1b, 0x2a]; // ESC/POS Command to send image data
const DOTS_DENSITY = 24; // Image density for slicing
const LINE_FEED = 0x0a; // Line feed command
const LUMINANCE = { RED: 0.299, GREEN: 0.587, BLUE: 0.114 };

// Converts RGB pixel to grayscale luminance
function calculateLuminance(pixel) {
	return (
		LUMINANCE.RED * pixel[0] +
		LUMINANCE.GREEN * pixel[1] +
		LUMINANCE.BLUE * pixel[2]
	);
}

// Converts grayscale to black & white using a threshold
function convertImageToBinary(image) {
	const threshold = 127; // Adjust threshold if needed
	return image.map(row =>
		row.map(pixel => (calculateLuminance(pixel) < threshold ? 0 : 1)),
	);
}

// Properly collects 8-pixel stripes for ESC/POS format
function collectStripe(x, y, image) {
	let stripe = [0, 0, 0]; // 3 bytes per column (24 dots)
	for (let i = 0; i < 3; i++) {
		let slice = 0;
		for (let bit = 0; bit < 8; bit++) {
			let pixelY = y + i * 8 + bit;
			if (pixelY < image.length && x < image[0].length) {
				slice |= (image[pixelY][x] === 0 ? 1 : 0) << (6 - bit);
			}
		}
		stripe[i] = slice;
	}
	return stripe;
}

// Converts image to ESC/POS bitmap data
function manipulateImage(image) {
	let data = [];
	const imageWidth = image[0].length;

	for (let y = 0; y < image.length; y += DOTS_DENSITY) {
		data.push(
			...ESC_BIT_IMAGE,
			33,
			imageWidth & 0xff,
			(imageWidth >> 8) & 0xff,
		);
		for (let x = 0; x < imageWidth; x++) {
			data.push(...collectStripe(x, y, image));
		}
		data.push(LINE_FEED);
	}
	return data;
}

// Generates the final print-ready byte array
function printImage(image) {
	return new Uint8Array([...ESC_INIT, ...manipulateImage(image)]);
}

function scaleImage(
	image,
	maxWidth,
	trimLeft = 20,
	trimTop = 50,
	trimBottom = 50,
) {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	// Calculate the scale factor for width
	const scaleFactor = maxWidth / image.width;

	// Calculate the new height to preserve aspect ratio
	const newHeight = Math.round(image.height * scaleFactor);

	// Create the canvas with the scaled width and height
	canvas.width = maxWidth & ~7; // Ensure width is a multiple of 8
	canvas.height = newHeight;

	// Draw the scaled image on the canvas
	ctx.drawImage(
		image,
		0,
		0,
		image.width,
		image.height,
		0,
		0,
		canvas.width,
		canvas.height,
	);

	// Now trim the top, bottom, and left after scaling
	const trimmedCanvas = document.createElement('canvas');
	const trimmedCtx = trimmedCanvas.getContext('2d');

	// Calculate the width and height of the trimmed canvas
	trimmedCanvas.width = canvas.width - trimLeft; // Subtract the left trim width
	trimmedCanvas.height = canvas.height - trimTop - trimBottom; // Subtract the top and bottom trim heights

	// Calculate the offset to center the image (horizontally and vertically)
	const offsetY = trimTop; // For vertical centering after trim
	const offsetX = trimLeft; // For horizontal centering after left trim

	// Draw the cropped image onto the new canvas with calculated offsets to center
	trimmedCtx.drawImage(
		canvas,
		trimLeft,
		trimTop,
		canvas.width - trimLeft,
		canvas.height - trimTop - trimBottom,
		0,
		0,
		trimmedCanvas.width,
		trimmedCanvas.height,
	);

	return trimmedCanvas;
}

// Converts a canvas image to pixel array
function convertImageToPixelData(canvas) {
	const ctx = canvas.getContext('2d');
	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

	return Array.from({ length: imageData.height }, (_, y) =>
		Array.from({ length: imageData.width }, (_, x) => {
			const index = (y * imageData.width + x) * 4;
			return [
				imageData.data[index],
				imageData.data[index + 1],
				imageData.data[index + 2],
			];
		}),
	);
}

function createReceiptLine(leftText, rightText, lineChars) {
	let padding = lineChars - leftText.length - rightText.length;
	padding = ' '.repeat(padding);
	return `${leftText}${padding}${rightText}\n`;
}

function printLineWithDashes(dashChar, lineLength) {
	// Create a string of dashes (or any other character) of the specified length
	return dashChar.repeat(lineLength) + '\n'; // Adds a newline at the end for proper formatting
}

async function posOrderReceiptPrinterNetwork(
	printer,
	logo,
	qrEInvoice,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	printerName,
	mode,
	isCash = false,
	receiptType,
	getTaxSchemeDetail,
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
	getUsersByAccountAndSoftware,
	officer,
	staff,
	getTaxSummary,
	deviceAlignmentCode,
	qrCodeImage,
) {
	console.log(deviceAlignmentCode, 'deviceAlignmentCode');
	const receipt = [String.fromCharCode(27) + '@'];

	// Set font size to 1 (default size in many printers)
	receipt.push(String.fromCharCode(27) + '!' + String.fromCharCode(0)); // Font size 1 (default size)

	// Center align the text (assuming printer supports \x1B\x61\x01 for center)
	receipt.push(String.fromCharCode(27) + '\x61\x01'); // Center alignment

	receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON

	receipt.push(`Welcome to ${outletName}\n`);

	receipt.push(printLineWithDashes('-', deviceAlignmentCode));

	const printedOn = new Date();

	// Reset alignment to left (default)
	receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)

	receipt.push(`Table: ${tableNo}\n`);
	receipt.push(`Printed on: ${format(printedOn, 'dd MMM yyyy, hh:mm a')}\n`);
	receipt.push(`Staff: ${userName}\n`);

	// Center align the text (assuming printer supports \x1B\x61\x01 for center)
	receipt.push(String.fromCharCode(27) + '\x61\x01'); // Center alignment
	receipt.push(printLineWithDashes('-', deviceAlignmentCode));

	// Reset alignment to left (default)
	receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)

	try {
		// Convert base64 to image
		const img = new Image();
		img.src = qrCodeImage;

		await new Promise((resolve, reject) => {
			img.onload = resolve;
			img.onerror = reject;
		});

		// Printer constraints
		const LINE_CHARS = 48;
		const maxCharWidth = 12;
		const maxWidth = Math.floor((LINE_CHARS * maxCharWidth) / 8) * 8;

		// Process image
		const scaledCanvas = scaleImage(img, maxWidth, 40, 190, 190);
		let pixelData = convertImageToPixelData(scaledCanvas);
		pixelData = convertImageToBinary(pixelData); // Apply thresholding

		// Convert to ESC/POS bitmap format
		const bitmap = printImage(pixelData);
		receipt.push(String.fromCharCode.apply(null, bitmap));
		// receipt.push(`\n`);
		receipt.push(String.fromCharCode(27) + '\x45' + String.fromCharCode(1)); // Bold ON

		// Center align the text (assuming printer supports \x1B\x61\x01 for center)
		receipt.push(String.fromCharCode(27) + '\x61\x01'); // Center alignment
		receipt.push(`Scan the QR to Order\n`);
		// Reset alignment to left (default)
		receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)
	} catch (error) {
		console.error('Error converting base64 to bitmap:', error);
	}

	// Reset alignment to left (default)
	receipt.push(String.fromCharCode(27) + '\x61\x00'); // Left alignment (reset)
	// receipt.push(`\n\n`);

	receipt.push(
		String.fromCharCode(29) +
			'V' +
			String.fromCharCode(66) +
			String.fromCharCode(0),
	);
	return receipt.join('');
}

export { posOrderReceiptPrinterNetwork };
