import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	FormControlLabel,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetKitchenPrinterDocument,
	KitchenConnectType,
	useConnectDeviceKitchenPrinterMutation,
	useCreateKitchenPrinterMutation,
	useDeleteKitchenPrinterMutation,
	useGetKitchenPrinterQuery,
	useGetOutletQuery,
	useUpdateKitchenPrinterMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import { uppercaseAndRemoveSpace } from 'helpers/hooks/stringNumberFunction/stringConverter';

// const dialogFormFieldsInitialState: DialogFormFields = {
// 	name: '',
// };

interface KitchenPrinterProps {
	name: string;
	// address: string;
	description: string;
	connectType: string;
	deviceIP: string;
	devicePort: string;
	deviceAlignmentCode: number;
}

export const KitchenPrinterList = (props: any) => {
	const history = useHistory();
	const { outletID }: any = useParams();
	const location = useLocation();

	const { pathname } = useLocation();

	//
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [editDialog, setEditDialog] = useState(false);
	const [double, setDouble] = useState(false);
	const [connectionType, setConnectionType] = useState(null);

	const { mode } = props;
	const editData = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const {
		data,
		called: kitchenCalled,
		loading: kitchenLoading,
	} = useGetKitchenPrinterQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'name' },
	});

	const [
		submitKitchenPrinter,
		{ data: createdKitchenPrinterData, loading: submitKitchenPrinterLoading },
	] = useCreateKitchenPrinterMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/kitchen-printer`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
		// fetchPolicy: 'network-only',
	});

	const [
		updateKitchenPrinter,
		{ data: updatedKitchenPrinterData, loading: updateKitchenPrinterLoading },
	] = useUpdateKitchenPrinterMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/kitchen-printer`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});

	const [
		deleteKitchenPrinter,
		{ loading: deleteKitchenPrinterLoading },
	] = useDeleteKitchenPrinterMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const [
		connectDeviceKitchenPrinter,
		{
			data: connectDeviceReceiptPrinterData,
			loading: connectDeviceReceiptPrinterLoading,
		},
	] = useConnectDeviceKitchenPrinterMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/kitchen-printer`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});

	//USEFORM

	const existingKitchenPrinterNames = data?.getKitchenPrinter?.map(
		m => m?.name,
	);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.typeError('Name cannot be empty')
			.test('name', 'Kitchen printer name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingKitchenPrinterNames?.includes(value);
				}
				return true;
			}),

		description: yup
			.string()
			.required(SystemMsgs.description())
			.typeError('Description cannot be empty')
			.trim(),
		deviceAlignmentCode: yup
			.string()
			.required('Printer Alignment Code is required')
			.typeError('Printer Alignment Code cannot be empty')
			.trim(),
	});

	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		formState,
	} = useForm<KitchenPrinterProps>({
		defaultValues: {
			name: editData?.name,
			// address: editData?.address,
			description: editData?.description,
			connectType: editData?.connectType,
			deviceIP: editData?.deviceIP,
			devicePort: editData?.devicePort,
			deviceAlignmentCode: editData?.deviceAlignmentCode,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	//ONSUBMIT

	const onSubmit = (data: any) => {
		console.log(data, 'data');
		isEdit === false
			? submitKitchenPrinter({
					variables: {
						input: {
							outletID: outletID,
							name: uppercaseAndRemoveSpace(data.name),
							description: data.description,
							// address: data.address,
							connectType: data?.connectType || connectionType,
							deviceIP:
								connectionType === KitchenConnectType.Network
									? data?.deviceIP
									: null,
							devicePort:
								connectionType === KitchenConnectType.Network
									? data?.devicePort
									: null,
							deviceAlignmentCode: data?.deviceAlignmentCode,
						},
					},
					refetchQueries: [
						{
							query: GetKitchenPrinterDocument,
							variables: { outletID, orderByAsc: 'name' },
						},
					],
			  })
			: updateKitchenPrinter({
					variables: {
						input: {
							ID: menu?.obj?.ID,
							name: uppercaseAndRemoveSpace(data.name),
							description: data.description,
							// address: data.address,
							connectType: data?.connectType || connectionType,
							deviceIP:
								connectionType === KitchenConnectType.Network
									? data?.deviceIP
									: null,
							devicePort:
								connectionType === KitchenConnectType.Network
									? data?.devicePort
									: null,
							deviceAlignmentCode:
								typeof data?.deviceAlignmentCode === 'string'
									? parseFloat(data?.deviceAlignmentCode) // Convert string to number
									: data?.deviceAlignmentCode, // Keep it as-is if it's already a number
						},
					},
					refetchQueries: [
						{
							query: GetKitchenPrinterDocument,
							variables: { outletID, orderByAsc: 'name' },
						},
					],
			  });
		setOpenDialog(false);
		clearErrors();
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		clearErrors();
	};

	// Connect to USB device

	const [device, setDevice] = useState<any | null>(null);
	const [loading, setLoading] = useState(false);

	const connectToUSBDevice = async () => {
		try {
			setLoading(true);

			// Request the USB device
			const selectedDevice = await navigator.usb.requestDevice({
				filters: [], // Use filters to narrow down the list of devices if necessary
			});

			console.log('Device selected:', selectedDevice);

			// Open the device
			await selectedDevice.open();
			console.log('Device opened:', selectedDevice.opened);

			// Select configuration (configuration 1 is the common one)
			await selectedDevice.selectConfiguration(1);
			console.log('Configuration selected:', selectedDevice.configuration);

			if (
				!selectedDevice.configuration ||
				!selectedDevice.configuration.interfaces
			) {
				throw new Error('No interfaces found for this configuration');
			}

			const interfaces = selectedDevice.configuration.interfaces;
			console.log('Interfaces:', interfaces);

			// Claim the first interface
			await selectedDevice.claimInterface(0);
			console.log('Interface claimed');

			const cleanProductName = selectedDevice.productName.replace(
				/[\u0000-\u001F\u007F]/g,
				'',
			);

			// Store only essential properties in localStorage
			const deviceData = {
				productName: cleanProductName,
				vendorId: selectedDevice.vendorId,
				productId: selectedDevice.productId,
			};

			// Save the device data in localStorage
			localStorage.setItem('connectedPrinter', JSON.stringify(deviceData));

			connectDeviceKitchenPrinter({
				variables: {
					input: {
						ID: menu?.obj?.ID,
						outletID: outletID,
						deviceInfo: deviceData,

						// isActive: data.isActive,
					},
				},
				refetchQueries: [
					{
						query: GetKitchenPrinterDocument,
						variables: { outletID, orderByAsc: 'name' },
					},
				],
			});

			setDevice(selectedDevice);
			// setIsModalOpen(true); // Show the printer list after device connection
			alert('Printer connected!');
		} catch (err) {
			console.error('Error connecting to USB device:', err.message);
		} finally {
			setLoading(false);
		}
	};

	// return <>qro</>;

	return (
		<>
			{updateKitchenPrinterLoading && <Loading />}
			{kitchenLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitKitchenPrinterLoading && <Loading />}
			{deleteKitchenPrinterLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Kitchen Printer', current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!kitchenLoading &&
						!outletLoading &&
						(data?.getKitchenPrinter?.length === 0 ? (
							<EmptyList
								title="No Record found"
								subtitle="Please add kitchen printer to continue"
							/>
						) : (
							data?.getKitchenPrinter?.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{el?.name}</span>
												<span className="xxTitle highlight-text text-separator"></span>
											</>
										}
										secondary={
											<>
												<span className="desc">
													<span className="desc">{el?.description}</span>
												</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el?.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
						))}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
							setConnectionType(menu?.obj?.connectType);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					{menu?.obj?.connectType === KitchenConnectType.Usb ? (
						<>
							<MenuItem
								onClick={() => {
									connectToUSBDevice();
								}}
							>
								<span className="">Connect</span>
							</MenuItem>
						</>
					) : null}
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={openDialog}
						onClose={() => handleCloseDialog()}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="title ">{`${
													isEdit ? 'Edit' : 'New'
												} Kitchen Printer`}</div>
												{/* <div className="flex session">
													<div className="title flex-space">
														New Kitchen Printer
													</div>
												</div> */}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="content-container">
										<Controller
											as={
												<RadioGroup
													aria-label="example"
													row
													style={{ width: '100%' }}
													className="place-content-center"
													defaultValue={isEdit ? menu?.obj?.connectType : null}
												>
													<FormControlLabel
														//   key={index}
														color="primary"
														value={KitchenConnectType.Usb}
														className="left "
														control={<Radio color="primary" />}
														label={<span>{'USB'}</span>}
														onClick={() => {
															setConnectionType(KitchenConnectType.Usb);
														}}
														// name="everyday"
														// ref={register}
													/>

													<FormControlLabel
														//   key={index}
														color="primary"
														value={KitchenConnectType.Network}
														className="right"
														control={<Radio color="primary" />}
														label={'Network'}
														onClick={() => {
															setConnectionType(KitchenConnectType.Network);
														}}
													/>

													{/* ))} */}
												</RadioGroup>
											}
											name="connectType"
											control={control}
											ref={register}
											helperText={errors?.connectType?.message}
											error={errors?.connectType ? true : false}
										/>
										<Controller
											as={TextField}
											name="name"
											label="Kitchen Printer Name"
											autoComplete="off"
											multiline={true}
											defaultValue={isEdit ? menu?.obj?.name : null}
											fullWidth
											ref={register}
											control={control}
											required
											helperText={errors?.name?.message}
											error={errors?.name ? true : false}
											onInput={() => {
												setDouble(false);
											}}
										/>
										<Controller
											as={TextField}
											name="description"
											label="Description"
											autoComplete="off"
											multiline={true}
											defaultValue={isEdit ? menu?.obj?.description : null}
											fullWidth
											ref={register}
											control={control}
											required
											helperText={errors?.description?.message}
											error={errors?.description ? true : false}
											onInput={() => {
												setDouble(false);
											}}
										></Controller>
										{/* <Controller
											as={TextField}
											name="address"
											label="Printer Address"
											autoComplete="off"
											multiline={true}
											defaultValue={isEdit ? menu?.obj?.address : null}
											fullWidth
											ref={register}
											control={control}
											required
											helperText={errors?.address?.message}
											error={errors?.address ? true : false}
											onInput={() => {
												setDouble(false);
											}}
										></Controller> */}
										{connectionType === KitchenConnectType.Network ? (
											<>
												<Controller
													as={TextField}
													name="deviceIP"
													label="Device IP"
													autoComplete="off"
													multiline={true}
													defaultValue={isEdit ? menu?.obj?.deviceIP : null}
													fullWidth
													ref={register}
													control={control}
													required
													helperText={errors?.name?.message}
													error={errors?.name ? true : false}
													onInput={() => {
														setDouble(false);
													}}
												/>
												<Controller
													as={TextField}
													name="devicePort"
													label="Device Port"
													autoComplete="off"
													multiline={true}
													defaultValue={isEdit ? menu?.obj?.devicePort : null}
													fullWidth
													ref={register}
													control={control}
													required
													helperText={errors?.name?.message}
													error={errors?.name ? true : false}
													onInput={() => {
														setDouble(false);
													}}
												/>
											</>
										) : null}
										<Controller
											as={
												<TextField
													name="deviceAlignmentCode"
													select
													fullWidth
													// margin="normal"
													label="Printer Alignment Code"
													autoComplete="off"
													multiline={true}
												>
													{Array.from(
														{ length: 11 },
														(_, index) => index + 40,
													).map(number => (
														<MenuItem key={number} value={number}>
															{number}
														</MenuItem>
													))}
												</TextField>
											}
											name="deviceAlignmentCode"
											// margin="normal"
											label="Printer Alignment Code"
											fullWidth
											autoComplete="off"
											select
											control={control}
											ref={register}
											required
											helperText={errors?.deviceAlignmentCode?.message}
											error={errors?.deviceAlignmentCode ? true : false}
											defaultValue={
												isEdit ? menu?.obj?.deviceAlignmentCode || 42 : 42
											} // Set default here for react-hook-form
										/>
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												handleCloseDialog();
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											//onClick: () => handleSubmit(onSubmit)(),
											variant: 'contained',
											form: 'submit-form',
											// color: existingKitchenPrinterNames?.includes(watch('name'))
											// 	? 'inherit'
											// 	: 'primary',
											// type: 'submit',
											// onClick: () => {
											// 	if (!existingKitchenPrinterNames?.includes(watch('name'))) {
											// 		handleSubmit(onSubmit)();
											// 	}
											// },
											// onClick: () => {
											// 	handleSubmit(onSubmit)();
											// },
											color: 'primary',
											// disabled: double,

											onClick: () => {
												if (errors?.name ? false : true) {
													handleSubmit(onSubmit)();
													setDouble(true);
												}
											},
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, orderByAsc: 'name' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteKitchenPrinter}
				refetchDocument={GetKitchenPrinterDocument}
			/>

			<FloatButton
				// disabled={value !== 0 ? true : false}
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
// function yupResolver(
// 	VOSchema: any,
// ): import('react-hook-form').Resolver<KitchenPrinterProps, object> | undefined {
// 	throw new Error('Function not implemented.');
// }
