import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { ProfileIcon } from 'assets';
import BigNumber from 'bignumber.js';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CommonStatus,
	GetTableDocument,
	ReceiptConnectType,
	ServiceMode,
	useCreateOrderMutation,
	useCreateQrMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerQuery,
	useGetOutletCustomerPolicyQuery,
	useGetOutletQuery,
	useGetPatronQuery,
	useGetReceiptPrinterLazyQuery,
	useGetStaffQuery,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { isId } from 'helpers/isId';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { countryAlphaCodes } from 'utils/countryAlphaCodes';
import { PrintedQR } from './PrintedQR';
import * as htmlToImage from 'html-to-image';
import {
	printReceipt,
	printReceiptText,
} from '../BillSettlement/BillReceipt/BillReceiptFunctions';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';
import { printOrderReceiptUSBPOS } from '../POSPrinter/OrderReceipt/posOrderReceiptPrinterUSB';
import QRCode from 'qrcode';
import { posOrderReceiptPrinterNetwork } from '../POSPrinter/OrderReceipt/posOrderReceiptPrinterNetwork';

interface NewOrderProps {
	tableNumber: string;
	tableID: string;
	pax: number;
	serviceMode: ServiceMode;
	patronName: string;
	contactNo: string;
	email: string;
	patronAccountID: string;
	patronID: string;
	pickupTime: string;
	address: string;
	staffID?: string;
	officerID?: string;
}

export const NewOrder = (props: any) => {
	useEffect(() => {
		console.log('NewOrder');
	}, []);
	const history = useHistory();
	const location = useLocation();
	const { mode } = props;
	const {
		tableID,
		outletID,
		// qrID
	} = useParams<any>();
	const tableData = location.state as any;
	const [visitorTextField, setVisitorTextField] = useState(false);
	const [memberSearchField, setMemberSearchField] = useState(false);
	const [hotelSearchField, setHotelSearchField] = useState(false);
	const [staffComponent, setStafComponent] = useState(false);
	const [officerComponent, setOfficerComponent] = useState(false);
	const [staffState, setStaffState] = useState('');
	const [officerState, setOfficerState] = useState('');
	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);

	const [selectedGuest, setSelectedGuest] = useState({} as any);
	const [pickup, setPickup] = useState(false);
	const [delivery, setDelivery] = useState(false);
	const [disable, setDisable] = useState(true);

	const [tempState, setTempState] = useState('');
	const [hotelTempState, setHotelTempState] = useState('');
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [countryCode, setCountryCode] = useState('my');

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	// const currentMealPeriod = JSON?.parse(
	// 	localStorage?.getItem('currentMealPeriod'),
	// )[outletID][0];

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			const countryCodes = Object.keys(countryAlphaCodes);
			countryCodes.forEach(code => {
				if (countryAlphaCodes[code] === getOutlet[0]?.address?.country) {
					setCountryCode(code.toLowerCase());
				}
			});
			loadReceiptPrinter({
				variables: {
					outletID: outletID,
					isDefault: true,
				},
			});
		},
	});

	const {
		data: { getPatron } = { getPatron: [] },
		loading: patronLoading,
	} = useGetPatronQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, isActive: true },
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [receiptDataURL, setReceiptDataURL] = useState(null);
	const [printerName1, setPrinterName1] = useState(null);

	const [
		loadReceiptPrinter,
		{
			loading: receiptPrinterLoading,
			called: receiptPrinterCalled,
			data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		},
	] = useGetReceiptPrinterLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getReceiptPrinter }) => {
			// setPrinterName(getReceiptPrinter[0].name);
			// printReceipt(receiptDataURL, getReceiptPrinter[0].name, 'qr');
			setIp(getReceiptPrinter[0]?.deviceIP);
			setPort(getReceiptPrinter[0]?.devicePort);
			setPrinterName(getReceiptPrinter[0]?.name);
		},
	});

	// console.log(
	// 	'data',
	// 	getReceiptPrinter,
	// 	receiptPrinterLoading,
	// 	receiptPrinterCalled,
	// );

	const handlePrinting = () => {
		loadReceiptPrinter({
			variables: {
				outletID: outletID,
				isDefault: true,
			},
		});
	};

	const print = async () => {
		handlePrinting();
	};

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	useEffect(() => {
		if (printerName1 !== null) {
			handleSubmit(data => submitOrder(data, qrID))();
			// printReceipt(receiptDataURL, printerName, 'qr');
			// printReceiptText(
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	printerName,
			// 	'qr',
			// 	null,
			// 	'new-order',
			// 	null,
			// 	getOutlet[0]?.name,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	null,
			// 	`${tableData?.prefix}${tableData?.tableNumber}`,
			// 	user?.name,
			// 	outletID,
			// 	qrID,
			// 	user?.accountID,
			// 	getUsersByAccountAndSoftware,
			// 	null,
			// 	null,
			// 	null,
			// );
		}
	}, [printerName1]);

	const [createOrder, { loading: createOrderLoading }] = useCreateOrderMutation(
		{
			onError: error => {
				console.log('ERROR', error);
				setOpenSnackBar(true);
				if (error?.message?.includes('mealPeriod')) {
					setSnackBarMsg('No applicable meal period');
				} else {
					setSnackBarMsg(SystemMsgs.createNewRecordFail());
				}
			},
			onCompleted: ({ createOrder }) => {
				console.log(createOrder, 'createOrder');
				if (mode === 'dineIn') {
					// console.log('1');
					// if (
					// 	getReceiptPrinter?.length > 0
					// 	// receiptPrinterLoading === false &&
					// 	// receiptPrinterCalled === true
					// ) {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${createOrder?.ID}`,
						// state: { qrID: qrID, receiptDataURL: receiptDataURL },
					});
					// }
				} else if (mode === 'takeAway') {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/order/${createOrder?.ID}/takeaway`,
					});
				}
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.createNewRecord());
				// print();
			},
		},
	);

	const yupSchema = yup.object().shape({
		serviceMode:
			mode === 'takeAway'
				? yup.string().required('Service Type is required')
				: null,
		patronID: yup.string().required('Customer Type is required!'),
		patronName:
			visitorTextField &&
			mode === 'takeAway' &&
			CommonYupValidation.requireField(SystemMsgs.name()),
		contactNo:
			visitorTextField &&
			mode === 'takeAway' &&
			CommonYupValidation.requireField(SystemMsgs.contactNo()),
		pickupTime:
			mode === 'takeAway' && pickup
				? yup.string().required('Pick up time is required!')
				: null,
		address:
			mode === 'takeAway' && delivery
				? yup.string().required('Address is required')
				: null,
	});

	const { control, register, handleSubmit, errors, watch, setValue } = useForm<
		NewOrderProps
	>({
		defaultValues: {
			tableNumber: tableData?.tableNumber,
			pax: tableData?.reservationInfo?.reserved
				? new BigNumber(tableData?.reservationInfo?.pax).toNumber()
				: new BigNumber(tableData?.orderInfo?.totalPax).toNumber() >=
				  new BigNumber(tableData?.seatCount).toNumber()
				? 0
				: new BigNumber(tableData?.seatCount)
						.minus(tableData?.orderInfo?.totalPax ?? 0)
						.toNumber(),
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const [
		loadMenuXInhouseList,
		{
			loading: MenuXInhouseListLoading,
			called: MenuXInhouseListCalled,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const hotelGuest = getMenuXInhouseList?.data?.MenuXInhouseList;

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (hotelGuest && hotelGuest.length > 0) {
			setOriginalListing(hotelGuest);
		}
	}, [hotelGuest]);

	const submitOrder = (data, qrIDSubmit) => {
		const selectedCond =
			Object.keys(selectedGuest).length > 0 ||
			Object.keys(selectedStaff).length > 0 ||
			Object.keys(selectedOfficer).length > 0;
		if (mode === 'dineIn') {
			createOrder({
				variables: {
					input: {
						tableID: tableID,
						outletID: outletID,
						qrID: qrIDSubmit
							? qrIDSubmit
							: '00000000-0000-0000-0000-000000000000',
						pax: parseFloat(data.pax),
						patronID: data.patronID,
						mealPeriodID: currentMealPeriod?.ID,
						patronAccountID:
							selectedGuest?.RegistrationID ??
							selectedStaff?.ID ??
							selectedOfficer?.ID,
						patronName:
							visitorTextField === true
								? data?.patronName
								: staffComponent === true
								? `${selectedStaff?.designation} | ${selectedStaff?.name}`
								: officerComponent === true
								? `${selectedOfficer?.designation} | ${selectedOfficer?.name}`
								: hotelSearchField === true
								? `${
										selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''
								  } ${selectedGuest?.GuestName}`
								: null,
						contactNo: data.contactNo,
						email: data.email,
						registrationID:
							hotelSearchField === true ? selectedGuest?.RegistrationID : null,
						// staffID: selectedStaff.ID,
						// officerID: selectedOfficer.ID,
					},
				},
				refetchQueries: [
					{ query: GetTableDocument, variables: { tableID, outletID } },
				],
			});
		} else if (mode === 'takeAway') {
			createOrder({
				variables: {
					input: {
						outletID: outletID,
						qrID: '00000000-0000-0000-0000-000000000000',
						serviceMode: data?.serviceMode,
						patronID: data.patronID,
						mealPeriodID: currentMealPeriod?.ID,
						patronAccountID:
							selectedGuest?.RegistrationID ??
							selectedStaff?.ID ??
							selectedOfficer?.ID,
						patronName:
							visitorTextField === true
								? data?.patronName
								: staffComponent === true
								? `${selectedStaff?.designation} | ${selectedStaff?.name}`
								: officerComponent === true
								? `${selectedOfficer?.designation} | ${selectedOfficer?.name}`
								: hotelSearchField === true
								? `${
										selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''
								  } ${selectedGuest?.GuestName}`
								: null,
						contactNo: data.contactNo,
						email: data.email,
						pickupTime: data?.pickupTime,
						address: data?.address,
						registrationID:
							hotelSearchField === true ? selectedGuest?.RegistrationID : null,
					},
				},
			});
		}
	};

	const [qrID, setQrID] = useState('');

	const [
		submitQR,
		{ data: createdQRData, loading: submitQRLoading },
	] = useCreateQrMutation({
		// fetchPolicy: 'network-only',
		onCompleted: ({ createQR }) => {
			// handleSubmit(data => submitOrder(data, createQR.ID))();
			setQrID(createQR.ID);
			sendReceiptToPrinter();

			setPrinterName1(getReceiptPrinter[0].name);
		},
	});

	const onSubmit = (data: any) => {
		submitQR({
			variables: {
				input: {
					outletID: outletID,
					code: tableData?.prefix.concat(tableData?.tableNumber) ?? '',
				},
			},
		});
	};

	const {
		loading: getOutletCustomerPolicyLoading,
		data: { getOutletCustomerPolicy } = { getOutletCustomerPolicy: [] },
	} = useGetOutletCustomerPolicyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	useEffect(() => {
		if (qrID !== '' && getOutletCustomerPolicy[0]?.qrCodeGenerate === true) {
			print();
		}
	}, [qrID]);

	const handleSelectedGuest = guest => {
		setSelectedGuest(guest);
	};

	//-------------------------- STAFF --------------------------//

	const {
		loading: getStaffLoading,
		data: { getStaff } = { getStaff: [] },
	} = useGetStaffQuery({
		fetchPolicy: 'network-only',
		variables: {
			isActive: true,
		},
		// onCompleted: ({ getStaff }) => {
		// 	setDefaultStaff(getStaff?.filter(x => x?.ID === selectedOrder?.staffID));
		// },
	});

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	useEffect(() => {
		if (getStaff && getStaff?.length > 0) {
			setOriginalListing1(getStaff);
		}
	}, [getStaff]);

	const handleSelectedStaff = staff => {
		setSelectedStaff(staff);
	};

	//-------------------------- OFFICER --------------------------//

	const {
		loading: getOfficerLoading,
		data: { getOfficer } = { getOfficer: [] },
	} = useGetOfficerQuery({
		fetchPolicy: 'network-only',
		variables: {
			isActive: true,
		},
		// onCompleted: ({ getOfficer }) => {
		// 	setDefaultOfficer(
		// 		getOfficer?.filter(x => x?.ID === selectedOrder?.officerID),
		// 	);
		// },
	});

	const {
		filteredList: filteredList2,
		handleSearch: handleSearch2,
		setOriginalListing: setOriginalListing2,
	} = useFuseSearch();

	useEffect(() => {
		if (getOfficer && getOfficer?.length > 0) {
			setOriginalListing2(getOfficer);
		}
	}, [getOfficer]);

	const handleSelectedOfficer = officer => {
		setSelectedOfficer(officer);
	};

	//////////////// DISABLE BUTTON FUNCTION //////////////////
	useEffect(() => {
		let p = getPatron
			?.filter(x => x?.ID === watch('patronID'))
			?.map(a => a?.patronClass);
		if (p[0] === 'Hotel Guest') {
			if (
				mode == 'takeAway'
					? Object?.values(selectedGuest ?? {})?.length <= 0 ||
					  watch('patronID') === undefined ||
					  watch('serviceMode') === undefined
					: Object?.values(selectedGuest ?? {})?.length <= 0 ||
					  watch('patronID') === undefined
			) {
				return setDisable(true), setSelectedOfficer(0), setSelectedStaff(0);
			} else {
				return setDisable(false);
			}
		} else if (p[0] === 'Officer') {
			if (
				mode == 'takeAway'
					? Object?.values(selectedOfficer ?? {})?.length <= 0 ||
					  watch('patronID') === undefined ||
					  watch('serviceMode') === undefined
					: Object?.values(selectedOfficer ?? {})?.length <= 0 ||
					  watch('patronID') === undefined
			) {
				return setDisable(true), setSelectedGuest(0), setSelectedStaff(0);
			} else {
				return setDisable(false);
			}
		} else if (p[0] === 'Staff') {
			if (
				mode == 'takeAway'
					? Object?.values(selectedStaff ?? {})?.length <= 0 ||
					  watch('patronID') === undefined ||
					  watch('serviceMode') === undefined
					: Object?.values(selectedStaff ?? {})?.length <= 0 ||
					  watch('patronID') === undefined
			) {
				return setDisable(true), setSelectedGuest(0), setSelectedOfficer(0);
			} else {
				return setDisable(false);
			}
		} else if (p[0] === 'Member' || 'Visitor') {
			if (
				mode == 'takeAway'
					? watch('patronID') === undefined ||
					  watch('serviceMode') === undefined
					: watch('patronID') === undefined
			) {
				return (
					setDisable(true),
					setSelectedGuest(0),
					setSelectedOfficer(0),
					setSelectedStaff(0)
				);
			} else {
				return setDisable(false);
			}
		}
	});

	console.log('tableData >>>', tableData);
	console.log('watch >>>', watch());

	const [baseImage, setBaseImage] = useState(null);
	const [qrCodeImage, setQRCodeImage] = useState(null);
	const [ip, setIp]: any = useState(null); // Printer IP
	const [port, setPort]: any = useState(null); // Printer TCP port
	const [printerName, setPrinterName]: any = useState(null); // Printer TCP port

	console.log(qrCodeImage, 'qrCodeImage');

	let qrUrl;
	switch (process.env.REACT_APP_ENV) {
		case 'local':
			qrUrl = `http://localhost:3500/menu/patron-app/outlet/${outletID}/qr-code/${qrID}/${user?.accountID}`;
			break;
		case 'dev':
			qrUrl = `https://posx-dev-customer.ifca.io/menu/patron-app/outlet/${outletID}/qr-code/${qrID}/${user?.accountID}`;
			break;
		case 'uat':
			qrUrl = `https://menux-uat-customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code/${qrID}/${user?.accountID}`;
			break;
		default:
			qrUrl = `https://customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code/${qrID}/${user?.accountID}`;
			break;
	}
	// Function to generate QR Code and convert it to Base64
	const generateQRCodeBase64 = async url => {
		try {
			const base64QR = await QRCode.toDataURL(url); // Generates QR code as a base64 string
			console.log('Base64 QR Code:', base64QR); // This is your base64 QR code image
			setBaseImage(base64QR); // Assuming you want to save it to state or use it in an img tag
		} catch (error) {
			console.error('Error generating QR Code:', error);
		}
	};
	// Call the function to generate and get the QR code as base64
	generateQRCodeBase64(qrUrl);

	// BASE64 FOR QR EINVOICE PRINT RECEIPT USED ONLY
	const getBase64FromUrlForQRCodeWithPadding = async (
		url: string,
		padding: number = 200,
		targetSize: number = 196,
	): Promise<string> => {
		// Fetch the image data from the URL
		const data = await fetch(url);
		const blob = await data.blob();

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64Image = reader.result as string;

				// Once the image is loaded, process it to add padding and resize
				addUniformPaddingAndShrinkImage(base64Image, padding, targetSize)
					.then(resizedImageBase64 => {
						resolve(resizedImageBase64); // Return the final base64 image
					})
					.catch(error => {
						reject(
							`Error processing the image with padding and resizing: ${error}`,
						);
					});
			};
		});
	};

	// Example Usage:
	getBase64FromUrlForQRCodeWithPadding(`${baseImage}`, 200, 250)
		.then(resizedImageBase64 => {
			console.log('Resized base64 image with padding:', resizedImageBase64);
			setQRCodeImage(resizedImageBase64); // Use the resized base64 image as needed
		})
		.catch(error => {
			console.error('Error fetching and processing the image:', error);
		});

	// Function to add padding and resize the image (as previously defined)
	function addUniformPaddingAndShrinkImage(
		base64Image: string,
		padding: number = 200,
		targetSize: number = 196,
	): Promise<string> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');

				if (!ctx) {
					reject('Failed to get canvas context.');
					return;
				}

				// Calculate new canvas dimensions with padding
				const canvasWidth = img.width + 2 * padding;
				const canvasHeight = img.height + 2 * padding;
				canvas.width = canvasWidth;
				canvas.height = canvasHeight;

				// Set the background color to white
				ctx.fillStyle = 'white';
				ctx.fillRect(0, 0, canvasWidth, canvasHeight);

				// Draw the image with padding
				ctx.drawImage(img, padding, padding);

				// Create a new canvas for resizing the image
				const resizedCanvas = document.createElement('canvas');
				const resizedCtx = resizedCanvas.getContext('2d');

				if (!resizedCtx) {
					reject('Failed to get resized canvas context.');
					return;
				}

				// Resize the canvas to the target size (196x196)
				resizedCanvas.width = targetSize;
				resizedCanvas.height = targetSize;

				// Draw the image (with padding) onto the resized canvas
				resizedCtx.drawImage(
					canvas,
					0,
					0,
					canvasWidth,
					canvasHeight,
					0,
					0,
					targetSize,
					targetSize,
				);

				// Convert the resized image to base64 format
				const newBase64Image = resizedCanvas.toDataURL('image/png');
				resolve(newBase64Image); // Return the final base64 image
			};

			img.onerror = () => reject('Error loading image');
			img.src = base64Image;
		});
	}

	const [connectedPrinter, setConnectedPrinter] = useState<any | null>(null);
	const [loading, setLoading] = useState(false);

	// Retrieve the printer data from localStorage
	useEffect(() => {
		const storedPrinter = getReceiptPrinter[0]?.deviceInfo;
		if (storedPrinter !== null) {
			connectToPrinter(storedPrinter);
		}
	}, [getReceiptPrinter[0]?.ID]);

	const connectToPrinter = async (printerData: any) => {
		try {
			setLoading(true);

			// Get the list of devices that match the vendorId and productId
			const devices = await navigator.usb.getDevices();
			const matchedDevice = devices.find(
				device =>
					device.vendorId === printerData.vendorId &&
					device.productId === printerData.productId,
			);

			if (matchedDevice) {
				console.log('Printer found:', matchedDevice);

				// Open the device and select configuration
				await matchedDevice.open();
				await matchedDevice.selectConfiguration(1);
				console.log('Configuration selected:', matchedDevice.configuration);

				if (
					!matchedDevice.configuration ||
					!matchedDevice.configuration.interfaces
				) {
					throw new Error('No interfaces found for this configuration');
				}

				const interfaces = matchedDevice.configuration.interfaces;
				console.log('Interfaces:', interfaces);

				// Claim the first interface
				await matchedDevice.claimInterface(0);
				console.log('Interface claimed');

				// Store the device object for later use
				setConnectedPrinter(matchedDevice);
				console.log('Printer connected successfully!');
			} else {
				console.error('Printer not found in the list of connected devices');
				console.error('No matching printer found');
			}
		} catch (err) {
			console.error('Error connecting to the printer:', err.message);
			console.error('Failed to connect to the printer');
		} finally {
			setLoading(false);
		}
	};

	const sendReceiptToPrinter = async () => {
		if (getReceiptPrinter[0]?.connectType === ReceiptConnectType.Usb) {
			if (!connectedPrinter) {
				console.error('No printer connected. Please connect a printer first.');
				return;
			}

			console.log('Printer connected, starting receipt print process...');

			setLoading(true);

			try {
				console.log('Calling printReceipt...');
				await printOrderReceiptUSBPOS(
					connectedPrinter,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					getReceiptPrinter[0]?.name,
					'qr',
					null,
					'new-order',
					null,
					getOutlet[0]?.name,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					`${tableData?.prefix}${tableData?.tableNumber}`,
					user?.name,
					outletID,
					qrID,
					user?.accountID,
					getUsersByAccountAndSoftware,
					null,
					null,
					null,
					getReceiptPrinter[0]?.deviceAlignmentCode,
					qrCodeImage,
				);
				console.log('Receipt printed and paper cut successfully!');
				console.log(
					'%cReceipt printed successfully!',
					'color: #12850b;',
					JSON.stringify(
						{
							productName: JSON.parse(localStorage.getItem('connectedPrinter'))
								?.productName,
							productId: JSON.parse(localStorage.getItem('connectedPrinter'))
								?.productId,
							vendorId: JSON.parse(localStorage.getItem('connectedPrinter'))
								?.vendorId,
						},
						null,
						'\t',
					),
				);
			} catch (error) {
				console.error('Failed to send data to the printer:', error);
				alert('Failed to print receipt!');
			} finally {
				setLoading(false);
			}
		} else if (
			getReceiptPrinter[0]?.connectType === ReceiptConnectType.Network
		) {
			const printData = await posOrderReceiptPrinterNetwork(
				connectedPrinter,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				getReceiptPrinter[0]?.name,
				'qr',
				null,
				'new-order',
				null,
				getOutlet[0]?.name,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				`${tableData?.prefix}${tableData?.tableNumber}`,
				user?.name,
				outletID,
				qrID,
				user?.accountID,
				getUsersByAccountAndSoftware,
				null,
				null,
				null,
				getReceiptPrinter[0]?.deviceAlignmentCode as number,
				qrCodeImage,
			);

			if (printData !== null) {
				try {
					const response = await fetch(
						`http://localhost:5000/${getReceiptPrinter[0]?.name}`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								ip,
								port,
								printerName,
								printData,
							}),
						},
					);

					if (response.status === 200) {
						console.log('Print job sent successfully');
					} else {
						console.error('Error sending print job');
					}
				} catch (error) {
					console.error('Error communicating with backend:', error);
				}
			}
		}
	};

	return (
		<>
			{outletLoading && <Loading />}
			{patronLoading && <Loading />}
			{MenuXInhouseListLoading && <Loading />}
			{createOrderLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{receiptPrinterLoading && <Loading />}
			{submitQRLoading && <Loading />}
			{getStaffLoading && <Loading />}
			{getOfficerLoading && <Loading />}
			{submitQRLoading && <Loading />}
			{getOutletCustomerPolicyLoading && <Loading />}

			<MainHeader
				onClick={() => {
					// history.push({
					// 	pathname: `/menu/outlet-app/${outletID}/table/grid`,
					// });
					if (mode === 'dineIn') {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/table/grid`,
							// state: selectedTable,
						});
					} else {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/takeaway`,
						});
					}
				}}
				mainBtn="close"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + tableData?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				routeSegments={[
					{ name: 'Table Layout' },
					{
						name:
							mode === 'dineIn'
								? 'Table ' + `${tableData?.prefix}${tableData?.tableNumber}`
								: 'Take Away',
						current: true,
					},
				]}
				rightRouteSegments={[
					{ name: `$${amtStr(tableData?.orderInfo?.totalAmount || 0)}` },
				]}
			/>
			<ContentWrapper footer>
				{
					<CardContents>
						{mode === 'dineIn' ? (
							<>
								{/* <Controller
									as={TextField}
									name="tableNumber"
									label="Table No."
									required
									autoComplete="off"
									disabled
									control={control}
									multiline={true}
									fullWidth
									margin="dense"
									className="left"
									ref={register}
									defaultValue={tableData?.tableNumber}
								/> */}
								<Controller
									as={
										<NumberFormat
											customInput={TextField}
											// allowNegative={false}
											fullWidth
											autoComplete="off"
											isNumericString
											fixedDecimalScale
											required
											// className="right"
											margin="dense"
											onValueChange={(value: any) => {
												const { floatValue } = value;
											}}
										/>
									}
									name="pax"
									label="No. of Pax"
									style={{ marginTop: '10px' }}
									control={control}
									ref={register}
									defaultValue={
										tableData?.reservationInfo?.reserved
											? new BigNumber(
													tableData?.reservationInfo?.pax,
											  ).toNumber()
											: new BigNumber(
													tableData?.orderInfo?.totalPax,
											  ).toNumber() >=
											  new BigNumber(tableData?.seatCount).toNumber()
											? 0
											: new BigNumber(tableData?.seatCount)
													.minus(tableData?.orderInfo?.totalPax ?? 0)
													.toNumber()
									}
									helperText={
										new BigNumber(watch('pax'))
											.plus(tableData?.orderInfo?.totalPax)
											.toNumber() >
										new BigNumber(tableData?.seatCount).toNumber()
											? 'Exceed total seat count'
											: null
									}
									error={
										new BigNumber(watch('pax'))
											.plus(tableData?.orderInfo?.totalPax)
											.toNumber() > tableData?.seatCount
									}
								/>
							</>
						) : (
							<FormControl
								component="fieldset"
								margin="dense"
								fullWidth
								required
							>
								<FormLabel component="legend" className="mdDesc">
									Service Type
								</FormLabel>
								<Controller
									as={
										<RadioGroup
											aria-label="ServiceType"
											style={{
												color:
													errors?.serviceMode &&
													watch('sericeMode') === undefined
														? 'rgb(244, 67, 54)'
														: null,
											}}
											className={
												errors?.serviceMode &&
												watch('serviceMode') === undefined
													? 'error-no-patron'
													: ''
											}
										>
											<Grid container>
												<Grid item xs={6} className="right">
													<FormControlLabel
														value={ServiceMode.Pickup}
														name="serviceMode"
														control={<Radio color="primary" />}
														label="Pick Up"
														onClick={() => {
															setPickup(true);
															setDelivery(false);
														}}
													/>
												</Grid>
												<Grid item xs={6} className="left">
													<FormControlLabel
														value={ServiceMode.Delivery}
														name="serviceMode"
														control={<Radio color="primary" />}
														label="Delivery"
														onClick={() => {
															setDelivery(true);
															setPickup(false);
														}}
													/>
												</Grid>
											</Grid>
										</RadioGroup>
									}
									name="serviceMode"
									control={control}
									ref={register}
								/>
								{watch('patronID') === undefined && errors?.serviceMode && (
									<span
										style={{
											margin: '0px',
											color: '#f44336',
											fontSize: '0.75rem',
											marginTop: '3px',
											textAlign: 'left',
											fontFamily:
												['Poppins', 'sans-serif'].join(',') + ' !important',
											fontWeight: 'normal',
											lineHeight: '1.66',
										}}
									>
										{errors?.serviceMode?.message}
									</span>
								)}
							</FormControl>
						)}
						{pickup && (
							// {pickup && (
							<>
								<Controller
									as={TextField}
									name="pickupTime"
									label="Pick Up Time"
									type="time" //defaultValue="14:00"
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300, // 5 min
									}}
									required
									fullWidth
									ref={register}
									control={control}
									margin="normal"
									autoComplete="off"
									helperText={errors?.pickupTime?.message}
									error={errors?.pickupTime ? true : false}
								></Controller>
							</>
						)}
						{delivery && (
							// {delivery && (
							<>
								<Controller
									as={TextField}
									name="address"
									label="Address"
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="dense"
									ref={register}
									style={{ marginBottom: '30px' }}
									helperText={errors?.address?.message}
									error={errors?.address ? true : false}
								/>
							</>
						)}
						<FormControl
							component="fieldset"
							margin="dense"
							fullWidth
							required
							style={{ marginBottom: '0px' }}
						>
							<FormLabel component="legend" className="mdDesc">
								Customer Type
							</FormLabel>

							<RadioGroup
								aria-label="patronType"
								name="patronRadio"
								style={{
									width: '100%',
									color:
										errors?.patronID && watch('patronID') === undefined
											? 'rgb(244, 67, 54)'
											: null,
								}}
								// className="place-content-center"
								className={`place-content-center ${
									errors?.patronID && watch('patronID') === undefined
										? 'error-no-patron'
										: ''
								}`}
								onChange={e => {
									register('patronID');
									setValue('patronID', e.target.value);
								}}
							>
								{getPatron?.map((v, index) => {
									return (
										<>
											<FormControlLabel
												key={index}
												value={v.ID}
												control={<Radio color="primary" name="patronID" />}
												label={toProperCase(v.patronClass)}
												onClick={() => {
													if (v?.patronClass == 'Visitor') {
														setVisitorTextField(true);
														setMemberSearchField(false);
														setHotelSearchField(false);
														setStafComponent(false);
														setOfficerComponent(false);
													} else if (v?.patronClass == 'Member') {
														// setMemberSearchField(true);
														// setVisitorTextField(false);
														// setHotelSearchField(false);
														// setPickup(false);
														// setDelivery(false);
														// loadPatronAccount({
														// 	variables: {
														// 		isMember: true,
														// 	},
														// });
														setVisitorTextField(false);
														setMemberSearchField(false);
														setHotelSearchField(false);
														// setPickup(false);
														// setDelivery(false);
														setStafComponent(false);
														setOfficerComponent(false);
													} else if (v?.patronClass == 'Hotel Guest') {
														setVisitorTextField(false);
														setMemberSearchField(false);
														setHotelSearchField(true);
														if (
															getOutlet[0]?.outletChannel?.hotelID &&
															getMenuXInhouseList?.length === 0 &&
															!MenuXInhouseListCalled
														) {
															loadMenuXInhouseList({
																variables: {
																	hotelID: getOutlet[0]?.outletChannel?.hotelID,
																	outletID: outletID,
																},
															});
														}
														setStafComponent(false);
														setOfficerComponent(false);
													} else if (v?.patronClass == 'Staff') {
														setStafComponent(true);
														setVisitorTextField(false);
														setMemberSearchField(false);
														setHotelSearchField(false);
														// setPickup(false);
														// setDelivery(false);
														setOfficerComponent(false);
													} else if (v?.patronClass == 'Officer') {
														setOfficerComponent(true);
														setStafComponent(false);
														setVisitorTextField(false);
														setMemberSearchField(false);
														setHotelSearchField(false);
														// setPickup(false);
														// setDelivery(false);
													} else {
														setVisitorTextField(false);
														setMemberSearchField(false);
														setHotelSearchField(false);
														// setPickup(false);
														// setDelivery(false);
														setStafComponent(false);
														setOfficerComponent(false);
													}
												}}
											/>

											{/* {v?.patronClass == 'Member' && memberSearchField ? (
												<>
													<TextField
														style={{ marginTop: '5px', marginBottom: '10px' }}
														onChange={(e) => {
															handleSearch(e?.target?.value, [`name`]);
															setTempState(e.target.value);
														}}
														label="Search Member No."
														variant="standard"
														// helperText={
														// 	radioError && watch('name') === undefined
														// 		? 'Must select at least one option'
														// 		: null
														// }
														// error={radioError && watch('name') === undefined}
													></TextField>
												</>
											) : null} */}

											{/* {v?.patronClass == 'Member' &&
											memberSearchField &&
											tempState ? (
												<List>
													{filteredList?.map((x, index) => {
														return (
															<ListItem
																key={index}
																alignItems="flex-start"
																button
																onClick={(e) => {
																	handleSelectedGuest(x?.ID, index);
																}}
																selected={
																	selectedGuest.selectedAccountNo === x?.ID
																}
															>
																<Grid container>
																	<ListItemIcon>
																		<Grid
																			item
																			xs={2}
																			style={{ paddingTop: '5px' }}
																		>
																			<div>
																				<img
																					className="icon-svg"
																					style={{
																						height: '40px',
																						width: '40px',
																						borderRadius: '30px',
																						marginRight: '10px',
																					}}
																					src={ProfileIcon}
																				/>
																			</div>
																			<div>
																				<span
																					className="desc"
																					style={{ color: '#000' }}
																				>
																					{x?.status}
																				</span>
																			</div>
																		</Grid>
																	</ListItemIcon>

																	<ListItemText
																		primary={
																			<Grid
																				item
																				xs={10}
																				style={{ paddingLeft: '10px' }}
																			>
																				<div>
																					<span
																						className="desc"
																						style={{ color: '#0000008A' }}
																					>
																						Member Info
																					</span>
																				</div>
																				<div>
																					<span>{x?.name}</span>
																				</div>
																				<div>
																					<span className="desc">
																						Credit Balance:
																						<span className="highlight-text">
																							&nbsp;{x?.creditBalance}
																						</span>
																					</span>
																				</div>
																			</Grid>
																		}
																	/>
																</Grid>
															</ListItem>
														);
													})}
												</List>
											) : null} */}

											{v?.patronClass == 'Staff' && staffComponent === true ? (
												<>
													<TextField
														style={{
															marginTop: '5px',
															marginBottom: '10px',
														}}
														onChange={e => {
															handleSearch1(`'${e?.target?.value}`, [`name`]);
															setStaffState(e.target.value);
														}}
														label="Search Staff"
														variant="standard"
														// defaultValue={
														// 	selectedOrder?.patronAccountID
														// 		? defaultRoom?.GuestName
														// 		: null
														// }
													></TextField>
												</>
											) : null}

											{v?.patronClass == 'Officer' &&
											officerComponent === true ? (
												<>
													<TextField
														style={{
															marginTop: '5px',
															marginBottom: '10px',
														}}
														onChange={e => {
															handleSearch2(`'${e?.target?.value}`, [`name`]);
															setOfficerState(e.target.value);
														}}
														label="Search Officer"
														variant="standard"
														// defaultValue={
														// 	selectedOrder?.patronAccountID
														// 		? defaultRoom?.GuestName
														// 		: null
														// }
													></TextField>
												</>
											) : null}

											{v?.patronClass == 'Hotel Guest' && hotelSearchField ? (
												<>
													<TextField
														style={{ marginTop: '5px', marginBottom: '10px' }}
														onChange={e => {
															handleSearch(`'"${e?.target?.value}"`, [
																`GuestName`,
																`RoomNo`,
															]);
															setHotelTempState(e.target.value);
														}}
														label="Search Hotel Guest"
														variant="standard"
														// helperText={
														// 	radioError && watch('name') === undefined
														// 		? 'Must select at least one option'
														// 		: null
														// }
														// error={radioError && watch('name') === undefined}
													></TextField>
												</>
											) : null}

											{v?.patronClass == 'Hotel Guest' &&
											hotelSearchField &&
											hotelTempState ? (
												<List>
													{filteredList
														?.filter(v => v?.GuestType === 'RoomGuest')
														?.map((x, index) => {
															return (
																<ListItem
																	key={index}
																	alignItems="flex-start"
																	button
																	onClick={e => {
																		handleSelectedGuest(x);
																	}}
																	selected={
																		selectedGuest?.RegistrationID ===
																		x?.RegistrationID
																	}
																>
																	<Grid container>
																		<ListItemIcon>
																			<Grid
																				item
																				xs={2}
																				style={{ paddingTop: '5px' }}
																			>
																				<div>
																					<img
																						className="icon-svg"
																						style={{
																							height: '40px',
																							width: '40px',
																							borderRadius: '30px',
																							marginRight: '10px',
																						}}
																						src={ProfileIcon}
																					/>
																				</div>
																				<div>
																					<span
																						className="desc"
																						style={{
																							color: '#000',
																							textAlign: 'center',
																							paddingLeft: '10px',
																						}}
																					>
																						{x?.RoomNo}
																					</span>
																				</div>
																			</Grid>
																		</ListItemIcon>

																		<ListItemText
																			primary={
																				<Grid
																					item
																					xs={10}
																					style={{ paddingLeft: '10px' }}
																				>
																					<div>
																						<span
																							className="desc"
																							style={{ color: '#0000008A' }}
																						>
																							Hotel Guest Info
																						</span>
																					</div>
																					<div>
																						<span>{x?.GuestName}</span>
																					</div>
																					<div>
																						<span className="desc">
																							Credit Balance:
																							<span className="highlight-text">
																								&nbsp;{x?.CreditBalance}
																							</span>
																						</span>
																					</div>
																				</Grid>
																			}
																		/>
																	</Grid>
																</ListItem>
															);
														})}
												</List>
											) : null}
											{v?.patronClass == 'Staff' &&
											staffComponent === true &&
											staffState ? (
												<List>
													{filteredList1?.map((staff, index) => (
														<ListItem
															key={index}
															alignItems="flex-start"
															button
															onClick={e => {
																handleSelectedStaff(staff);
															}}
															selected={selectedStaff.ID === staff?.ID}
														>
															<Grid container>
																<ListItemIcon>
																	<Grid
																		item
																		xs={2}
																		style={{ paddingTop: '5px' }}
																	>
																		<div>
																			<img
																				className="icon-svg"
																				style={{
																					height: '40px',
																					width: '40px',
																					borderRadius: '30px',
																					marginRight: '10px',
																				}}
																				src={ProfileIcon}
																			/>
																		</div>
																	</Grid>
																</ListItemIcon>

																<ListItemText
																	primary={
																		<Grid
																			item
																			xs={10}
																			style={{
																				paddingLeft: '10px',
																			}}
																		>
																			<div>
																				<span
																					className="desc"
																					style={{
																						color: '#0000008A',
																					}}
																				>
																					Staff Info
																				</span>
																			</div>
																			<div>
																				<span>{staff?.name}</span>
																			</div>
																			<div>
																				<span className="desc">
																					{staff?.designation}
																				</span>
																			</div>
																		</Grid>
																	}
																/>
															</Grid>
														</ListItem>
													))}
												</List>
											) : null}

											{v?.patronClass == 'Officer' &&
											officerComponent === true &&
											officerState ? (
												<List>
													{filteredList2?.map((officer, index) => (
														<ListItem
															key={index}
															alignItems="flex-start"
															button
															onClick={e => {
																handleSelectedOfficer(officer);
															}}
															selected={selectedOfficer.ID === officer?.ID}
														>
															<Grid container>
																<ListItemIcon>
																	<Grid
																		item
																		xs={2}
																		style={{ paddingTop: '5px' }}
																	>
																		<div>
																			<img
																				className="icon-svg"
																				style={{
																					height: '40px',
																					width: '40px',
																					borderRadius: '30px',
																					marginRight: '10px',
																				}}
																				src={ProfileIcon}
																			/>
																		</div>
																	</Grid>
																</ListItemIcon>

																<ListItemText
																	primary={
																		<Grid
																			item
																			xs={10}
																			style={{
																				paddingLeft: '10px',
																			}}
																		>
																			<div>
																				<span
																					className="desc"
																					style={{
																						color: '#0000008A',
																					}}
																				>
																					Officer Info
																				</span>
																			</div>
																			<div>
																				<span>{officer?.name}</span>
																			</div>
																			<div>
																				<span className="desc">
																					{officer?.designation}
																				</span>
																			</div>
																		</Grid>
																	}
																/>
															</Grid>
														</ListItem>
													))}
												</List>
											) : null}
										</>
									);
								})}
								{watch('serviceMode') === undefined && errors?.patronID && (
									<span
										style={{
											margin: '0px',
											color: '#f44336',
											fontSize: '0.75rem',
											marginTop: '3px',
											textAlign: 'left',
											fontFamily:
												['Poppins', 'sans-serif'].join(',') + ' !important',
											fontWeight: 'normal',
											lineHeight: '1.66',
										}}
									>
										{errors?.patronID?.message}
									</span>
								)}
							</RadioGroup>

							{visitorTextField && (
								<>
									<Controller
										as={TextField}
										name="patronName"
										label={mode === 'takeAway' ? 'Name *' : 'Name'}
										autoComplete="off"
										control={control}
										multiline={true}
										fullWidth
										margin="dense"
										ref={register}
										// style={{ marginBottom: '30px' }}
										helperText={errors?.patronName?.message}
										error={errors?.patronName ? true : false}
									/>

									<Controller
										as={TextField}
										name="email"
										label={'Email'}
										autoComplete="off"
										control={control}
										multiline={true}
										fullWidth
										margin="dense"
										ref={register}
										style={{ marginBottom: '30px' }}
										helperText={errors?.email?.message}
										error={errors?.email ? true : false}
									/>

									<Controller
										as={
											<PhoneNumInput
												country={countryCode}
												label={
													mode === 'takeAway' ? 'Contact No. *' : 'Contact No.'
												}
												fullWidth
												inputProps={{
													style: {
														borderColor: errors?.contactNo ? '#E53935' : null,
													},
												}}
											/>
										}
										customInput={TextField}
										name="contactNo"
										required
										marginTop
										marginBottom
										type="tel"
										autoComplete="off"
										control={control}
										ref={register}
										helperText={errors?.contactNo?.message}
										error={errors?.contactNo ? true : false}
									/>

									<div style={{ marginTop: '15px' }} />

									{/* {errors?.contactNo && (
										<div
											style={{
												color: '#E53935',
												fontWeight: 400,
												margin: '5px 0px 20px',
											}}
											className="mdDesc"
										>
											{errors?.contactNo?.message}
										</div>
									)} */}
								</>
							)}

							{/* {mode === 'takeaway' && (
								<Controller
									as={
										<TextField
											select
											variant="outlined"
											size="small"
											label="Major"
										>
											ABCDEFG
										</TextField>
									}
									name="patronName"
									label="Name"
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="dense"
									ref={register}
									style={{ marginBottom: '30px' }}
								/>
							)} */}
						</FormControl>
					</CardContents>
				}
			</ContentWrapper>
			<DynamicFooter
				options={[
					{
						name: 'Cancel',
						onClick: () => {
							// handleSubmit(onSubmit)();
							// alert('cancelled');
							history.push(`/menu/outlet-app/${outletID}/table/grid`);
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
					{
						name: 'Next',
						onClick: () => {
							if (getOutletCustomerPolicy[0]?.qrCodeGenerate === true) {
								handleSubmit(onSubmit)();
								// sendReceiptToPrinter();
							} else if (getOutletCustomerPolicy[0]?.qrCodeGenerate === false) {
								handleSubmit(data => submitOrder(data, qrID))();
								// sendReceiptToPrinter();
							}
						},
						color:
							disable === true ||
							createOrderLoading ||
							watch('pax') === 0 ||
							watch('pax').toString() === '' ||
							new BigNumber(watch('pax'))
								.plus(tableData?.orderInfo?.totalPax)
								.toNumber() > new BigNumber(tableData?.seatCount).toNumber()
								? 'inherit'
								: 'primary',

						props: {
							type: 'submit',
						},
						disabled:
							disable ||
							createOrderLoading ||
							watch('pax') === 0 ||
							watch('pax').toString() === '' ||
							new BigNumber(watch('pax'))
								.plus(tableData?.orderInfo?.totalPax)
								.toNumber() > new BigNumber(tableData?.seatCount).toNumber()
								? true
								: false,
					},
				]}
			/>
		</>
	);
};
