import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Switch,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetReceiptPrinterDocument,
	ReceiptConnectType,
	useConnectDeviceReceiptPrinterMutation,
	useCreateReceiptPrinterMutation,
	useDeleteReceiptPrinterMutation,
	useGetOutletQuery,
	useGetReceiptPrinterQuery,
	useUpdateReceiptPrinterMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import { uppercaseAndRemoveSpace } from 'helpers/hooks/stringNumberFunction/stringConverter';

// const dialogFormFieldsInitialState: DialogFormFields = {
// 	name: '',
// };

interface ReceiptPrinterProps {
	name: string;
	isDefault: boolean;
	connectType: string;
	deviceIP: string;
	devicePort: string;
	deviceAlignmentCode: number;
	// isActive: boolean;
}

export const ReceiptPrinterList = (props: any) => {
	const history = useHistory();
	const { outletID }: any = useParams();
	const location = useLocation();

	const { pathname } = useLocation();

	//
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [editDialog, setEditDialog] = useState(false);
	const [double, setDouble] = useState(false);
	const [connectionType, setConnectionType] = useState(null);

	const { mode } = props;
	const editData = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [isDefault, setIsDefault] = useState(menu?.obj?.isDefault);

	useEffect(() => {
		setIsDefault(menu?.obj?.isDefault);
	}, [menu]);
	// const [isActive, setIsActive] = useState(false);

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const {
		data,
		// data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		called: receiptPrinterCalled,
		loading: receiptPrinterLoading,
	} = useGetReceiptPrinterQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'name' },
	});

	const [
		submitReceiptPrinter,
		{ data: createdReceiptPrinterData, loading: submitReceiptPrinterLoading },
	] = useCreateReceiptPrinterMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/receipt-printer`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
		// fetchPolicy: 'network-only',
	});

	const [
		updateReceiptPrinter,
		{ data: updatedReceiptPrinterData, loading: updateReceiptPrinterLoading },
	] = useUpdateReceiptPrinterMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/receipt-printer`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});

	const [
		deleteReceiptPrinter,
		{ loading: deleteReceiptPrinterLoading },
	] = useDeleteReceiptPrinterMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const [
		connectDeviceReceiptPrinter,
		{
			data: connectDeviceReceiptPrinterData,
			loading: connectDeviceReceiptPrinterLoading,
		},
	] = useConnectDeviceReceiptPrinterMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/receipt-printer`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});

	//USEFORM

	const existingReceiptPrinterNames = data?.getReceiptPrinter?.map(
		m => m?.name,
	);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.typeError('Name cannot be empty')
			.test('name', 'Receipt printer name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingReceiptPrinterNames?.includes(value);
				}
				return true;
			}),
	});

	// const testSchema = yup.object().shape({
	// 	name: yup.string().required(SystemMsgs.name()),

	// 	description: yup.string().required(SystemMsgs.description()),
	// 	address: yup
	// 		.string()
	// 		.required(SystemMsgs.address())
	// 		.trim(),
	// });

	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		formState,
	} = useForm<ReceiptPrinterProps>({
		defaultValues: {
			name: editData?.name,
			isDefault: editData?.isDefault,
			connectType: editData?.connectType,
			deviceIP: editData?.deviceIP,
			devicePort: editData?.devicePort,
			deviceAlignmentCode: editData?.deviceAlignmentCode,
			// isActive: editData?.isActive,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	//ONSUBMIT

	const onSubmit = (data: any) => {
		console.log(data, 'receipt');
		isEdit === false
			? submitReceiptPrinter({
					variables: {
						input: {
							outletID: outletID,
							name: uppercaseAndRemoveSpace(data.name),
							isDefault:
								isDefault === undefined || isDefault === false ? false : true,
							connectType: data?.connectType || connectionType,
							deviceIP:
								connectionType === ReceiptConnectType.Network
									? data?.deviceIP
									: null,
							devicePort:
								connectionType === ReceiptConnectType.Network
									? data?.devicePort
									: null,
							deviceAlignmentCode: data?.deviceAlignmentCode,
							// isActive: data.isActive,
						},
					},
					refetchQueries: [
						{
							query: GetReceiptPrinterDocument,
							variables: { outletID, orderByAsc: 'name' },
						},
					],
			  })
			: updateReceiptPrinter({
					variables: {
						input: {
							ID: menu?.obj?.ID,
							outletID: outletID,
							name: uppercaseAndRemoveSpace(data.name),
							isDefault:
								isDefault === undefined || isDefault === false ? false : true,
							connectType: data?.connectType || connectionType,
							deviceIP:
								connectionType === ReceiptConnectType.Network
									? data?.deviceIP
									: null,
							devicePort:
								connectionType === ReceiptConnectType.Network
									? data?.devicePort
									: null,
							deviceAlignmentCode: data?.deviceAlignmentCode,
							// isActive: data.isActive,
						},
					},
					refetchQueries: [
						{
							query: GetReceiptPrinterDocument,
							variables: { outletID, orderByAsc: 'name' },
						},
					],
			  });
		setOpenDialog(false);
		setIsDefault(false);
		clearErrors();
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setIsDefault(false);
		clearErrors();
	};

	// Connect to USB device

	const [device, setDevice] = useState<any | null>(null);
	const [loading, setLoading] = useState(false);

	const connectToUSBDevice = async () => {
		try {
			setLoading(true);

			// Request the USB device
			const selectedDevice = await navigator.usb.requestDevice({
				filters: [], // Use filters to narrow down the list of devices if necessary
			});

			console.log('Device selected:', selectedDevice);

			// Open the device
			await selectedDevice.open();
			console.log('Device opened:', selectedDevice.opened);

			// Select configuration (configuration 1 is the common one)
			await selectedDevice.selectConfiguration(1);
			console.log('Configuration selected:', selectedDevice.configuration);

			if (
				!selectedDevice.configuration ||
				!selectedDevice.configuration.interfaces
			) {
				throw new Error('No interfaces found for this configuration');
			}

			const interfaces = selectedDevice.configuration.interfaces;
			console.log('Interfaces:', interfaces);

			// Claim the first interface
			await selectedDevice.claimInterface(0);
			console.log('Interface claimed');

			const cleanProductName = selectedDevice.productName.replace(
				/[\u0000-\u001F\u007F]/g,
				'',
			);

			// Store only essential properties in localStorage
			const deviceData = {
				productName: cleanProductName,
				vendorId: selectedDevice.vendorId,
				productId: selectedDevice.productId,
			};

			// Save the device data in localStorage
			localStorage.setItem('connectedPrinter', JSON.stringify(deviceData));

			connectDeviceReceiptPrinter({
				variables: {
					input: {
						ID: menu?.obj?.ID,
						outletID: outletID,
						deviceInfo: deviceData,

						// isActive: data.isActive,
					},
				},
				refetchQueries: [
					{
						query: GetReceiptPrinterDocument,
						variables: { outletID, orderByAsc: 'name' },
					},
				],
			});

			setDevice(selectedDevice);
			// setIsModalOpen(true); // Show the printer list after device connection
			alert('Printer connected!');
		} catch (err) {
			console.error('Error connecting to USB device:', err.message);
		} finally {
			setLoading(false);
		}
	};

	// return <>qro</>;

	return (
		<>
			{updateReceiptPrinterLoading && <Loading />}
			{receiptPrinterLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitReceiptPrinterLoading && <Loading />}
			{deleteReceiptPrinterLoading && <Loading />}
			{connectDeviceReceiptPrinterLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Receipt Printer', current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!receiptPrinterLoading &&
					!outletLoading &&
					// (getReceiptPrinter === undefined ||
					data?.getReceiptPrinter?.length === 0 ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add receipt printer to continue"
						/>
					) : (
						data?.getReceiptPrinter
							// ?.sort((a, b) => {
							// 	return a.name.localeCompare(b.name);
							// })
							?.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="smTitle flex-space">{el?.name}</span>
												<span className="xxTitle highlight-text text-separator"></span>
											</>
										}
										secondary={
											<>
												<span className="desc">
													<span className="desc">
														{el?.isDefault ? 'Default' : null}
													</span>
												</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el?.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
							setConnectionType(menu?.obj?.connectType);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					{menu?.obj?.connectType === ReceiptConnectType.Usb &&
					menu?.obj?.isDefault === true ? (
						<>
							<MenuItem
								onClick={() => {
									connectToUSBDevice();
								}}
							>
								<span className="">Connect</span>
							</MenuItem>
						</>
					) : null}
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={openDialog}
						onClose={() => {
							handleCloseDialog();
							setConnectionType(null);
						}}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="title ">{`${
													isEdit ? 'Edit' : 'New'
												} Receipt Printer`}</div>
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="content-container">
										{/* <FormControlLabel
											control={
												<Checkbox
													checked={isActive}
													onChange={e => {
														setIsActive(e.target.checked);
													}}
													name="active"
													color="primary"
												/>
											}
											label="Active"
										/> */}
										<Controller
											as={
												<RadioGroup
													aria-label="example"
													row
													style={{ width: '100%' }}
													className="place-content-center"
													defaultValue={isEdit ? menu?.obj?.connectType : null}
												>
													<FormControlLabel
														//   key={index}
														color="primary"
														value={ReceiptConnectType.Usb}
														className="left "
														control={<Radio color="primary" />}
														label={<span>{'USB'}</span>}
														onClick={() => {
															setConnectionType(ReceiptConnectType.Usb);
														}}
														// name="everyday"
														// ref={register}
													/>

													<FormControlLabel
														//   key={index}
														color="primary"
														value={ReceiptConnectType.Network}
														className="right"
														control={<Radio color="primary" />}
														label={'Network'}
														onClick={() => {
															setConnectionType(ReceiptConnectType.Network);
														}}
													/>

													{/* ))} */}
												</RadioGroup>
											}
											name="connectType"
											control={control}
											ref={register}
											// helperText={errors?.calcType?.message}
											// error={errors?.calcType ? true : false}
										/>
										<Controller
											as={TextField}
											name="name"
											label="Receipt Printer Name"
											autoComplete="off"
											multiline={true}
											defaultValue={isEdit ? menu?.obj?.name : null}
											fullWidth
											ref={register}
											control={control}
											required
											helperText={errors?.name?.message}
											error={errors?.name ? true : false}
											onInput={() => {
												setDouble(false);
											}}
										/>

										<div
											className="full"
											style={{
												justifyContent: 'space-between',
												display: 'flex',
											}}
										>
											<span style={{ marginTop: '7px' }} className="flex-space">
												Set Default
											</span>

											<Switch
												name="isDefault"
												checked={isDefault}
												// defaultChecked={menu?.obj?.isDefault}
												onChange={e => {
													setIsDefault(e.target.checked);
												}}
												color="primary"
											/>
										</div>
									</div>
									{connectionType === ReceiptConnectType.Network ? (
										<>
											<Controller
												as={TextField}
												name="deviceIP"
												label="Device IP"
												autoComplete="off"
												multiline={true}
												defaultValue={isEdit ? menu?.obj?.deviceIP : null}
												fullWidth
												ref={register}
												control={control}
												required
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
											/>
											<Controller
												as={TextField}
												name="devicePort"
												label="Device Port"
												autoComplete="off"
												multiline={true}
												defaultValue={isEdit ? menu?.obj?.devicePort : null}
												fullWidth
												ref={register}
												control={control}
												required
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
											/>
										</>
									) : null}
									<Controller
										as={
											<TextField
												name="deviceAlignmentCode"
												defaultValue={
													isEdit ? menu?.obj?.deviceAlignmentCode : null
												}
												select
												fullWidth
												margin="normal"
												label="Printer Alignment Code"
												autoComplete="off"
												multiline={true}
											>
												{Array.from(
													{ length: 11 },
													(_, index) => index + 40,
												).map(number => (
													<MenuItem key={number} value={number}>
														{number}
													</MenuItem>
												))}
											</TextField>
										}
										name="deviceAlignmentCode"
										margin="normal"
										label="Printer Alignment Code"
										fullWidth
										autoComplete="off"
										select
										control={control}
										ref={register}
										required
										helperText={errors?.deviceAlignmentCode?.message}
										error={errors?.deviceAlignmentCode ? true : false}
										defaultValue={
											isEdit ? menu?.obj?.deviceAlignmentCode : null
										}
									/>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												handleCloseDialog();
												setConnectionType(null);
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											variant: 'contained',
											form: 'submit-form',
											color: 'primary',
											disabled: double,

											onClick: () => {
												if (errors?.name ? false : true) {
													handleSubmit(onSubmit)();
													setDouble(true);
												}
											},
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, orderByAsc: 'name' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteReceiptPrinter}
				refetchDocument={GetReceiptPrinterDocument}
			/>

			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
