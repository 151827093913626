import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import AppContext from 'containers/App/Store/AppContext';
import {
	CommonStatus,
	KitchenConnectType,
	OrderItemStatus,
	OrderStatus,
	useGetOrderLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletQuery,
	useGetPrintDataSubscription,
	useGetPrinterOrderItemLazyQuery,
	useGetPrinterOrderItemQuery,
	useGetTableQuery,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { WSclient } from 'POSClient';
import {
	printReceipt,
	printReceiptText,
} from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';
import { printKitchenReceiptUSBPOS } from 'containers/OutletModule/Home/TableSubmenu/POSPrinter/KitchenReceipt/posKitchenReceiptPrinterUSB';

const useStyles = makeStyles({
	spaced: {
		['& > *']: {
			marginRight: '0.5rem',
		},
		['& :not(span)']: {
			width: '0.8rem',
			height: '0.8rem',
		},
	},
});

export const PrinterServerHome = (props: any) => {
	const { outletID } = useParams<Record<string, any>>();
	const classes = useStyles();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletSearch = JSON.parse(localStorage.getItem('searchFilter'));
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const history = useHistory();

	// Drawer Toggler
	const handleDrawer = () => {
		localStorage.removeItem('searchFilter');
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const { data: { getOutlet } = { getOutlet: [] } } = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	//subscription //subscription //subscription

	const {
		loading: getPrintDataLoading,
		data: { getPrintData } = { getPrintData: {} as any },
	} = useGetPrintDataSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	const {
		data: { getPrinterOrderItem } = { getPrinterOrderItem: [] },
		loading: getPrinterOrderItemLoading,
	} = useGetPrinterOrderItemQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: getPrintData.orderID,
			orderItemIDs: getPrintData?.orderItemIDs,
		},
		onCompleted: () => {
			generateReceiptData();
		},
	});

	const {
		data: { getOrder } = { getOrder: [] },
		loading: orderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: getPrintData?.orderID,
			status: OrderStatus.Open,
			outletID,
		},
	});

	const [uniqueKpArray, setUniqueKpArray] = useState([]);
	const [uniqueKpArrayInfo, setUniqueKpArrayInfo] = useState([]);

	const [kpObj, setKpObject] = useState({});
	const [printerName, setPrinterName] = useState(null);
	const [kitchenPrinterObj, setKitchenPrinterObj] = useState([]);
	const [print, setPrint] = useState(false);

	// useEffect(() => {
	// 	console.log('1');
	// 	if (getPrintData?.outletName !== undefined) {
	// 		console.log('2');
	// 		loadOrder({
	// 			variables: { ID: getPrintData?.orderID },
	// 		});
	// 		loadPrinterOrderItem({
	// 			variables: {
	// 				orderID: getPrintData.orderID,
	// 				orderItemIDs: getPrintData?.orderItemIDs,
	// 			},
	// 		});
	// 	}
	// }, [getPrintData]);

	useEffect(() => {
		if (getPrinterOrderItem?.length > 0) {
			generateReceiptData();
		}
	}, [getPrinterOrderItem]);

	const generateReceiptData = () => {
		let uniqueprinterName = new Set(
			getPrinterOrderItem
				?.filter(oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined))
				?.map(oi => oi?.menuItem?.kitchenPrinter?.name),
		);
		let uniqueprinterInfo = new Set(
			getPrinterOrderItem
				?.filter(oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined))
				?.map(oi => oi?.menuItem?.kitchenPrinter),
		);
		// Convert the Set to an array, then filter out duplicates by the 'name' property
		const uniqueKpArrayInfo = [
			...new Map(
				[...uniqueprinterInfo].map(printer => [printer.name, printer]),
			).values(),
		];
		setUniqueKpArrayInfo(uniqueKpArrayInfo);
		setUniqueKpArray([...uniqueprinterName!]);
		setPrint(true);

		let temporaryObject = {};

		getPrinterOrderItem?.map(oi => {
			if (!(oi?.menuItem?.kitchenPrinter?.name in temporaryObject)) {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name] = [oi];
			} else {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name].push(oi);
			}
		});
		setKpObject(temporaryObject);
	};

	console.log('getPrintData', getPrintData);
	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const [connectedPrinter, setConnectedPrinter] = useState<any | null>(null);
	const [loading, setLoading] = useState(false);
	const [printerServer, setPrinterServer] = useState(null);

	// Retrieve the printer data from localStorage
	useEffect(() => {
		const storedPrinter = printerServer?.deviceInfo;
		if (storedPrinter !== null) {
			connectToPrinter(storedPrinter);
		}
	}, [printerServer?.ID]);

	console?.log(printerServer, connectedPrinter, 'printerServer');

	const connectToPrinter = async (printerData: any) => {
		console?.log(printerData, 'printerData');
		try {
			setLoading(true);

			// Get the list of devices that match the vendorId and productId
			const devices = await navigator.usb.getDevices();
			const matchedDevice = devices.find(
				device =>
					device.vendorId === printerData.vendorId &&
					device.productId === printerData.productId,
			);

			if (matchedDevice) {
				console.log('Printer found:', matchedDevice);

				// Open the device and select configuration
				await matchedDevice.open();
				await matchedDevice.selectConfiguration(1);
				console.log('Configuration selected:', matchedDevice.configuration);

				if (
					!matchedDevice.configuration ||
					!matchedDevice.configuration.interfaces
				) {
					throw new Error('No interfaces found for this configuration');
				}

				const interfaces = matchedDevice.configuration.interfaces;
				console.log('Interfaces:', interfaces);

				// Claim the first interface
				await matchedDevice.claimInterface(0);
				console.log('Interface claimed');

				// Store the device object for later use
				setConnectedPrinter(matchedDevice);
				console.log('Printer connected successfully!');
			} else {
				console.error('Printer not found in the list of connected devices');
				console.error('No matching printer found');
			}
		} catch (err) {
			console.error('Error connecting to the printer:', err.message);
			console.error('Failed to connect to the printer');
		} finally {
			setLoading(false);
		}
	};

	let obj;
	const handlePrinting = async () => {
		if (uniqueKpArrayInfo?.length > 0) {
			await uniqueKpArrayInfo?.map(async (printerName, index) => {
				obj = kpObj[printerName?.name];
				setKitchenPrinterObj(obj);
				setPrinterName(printerName?.name);
				setPrinterServer(printerName);
				printReceiptText(
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					printerName?.name,
					'server',
					null,
					'order-receipt',
					null,
					getPrintData.outletName,
					getOrder[0],
					getOrder[0]?.table,
					obj,
					getPrintData.orderMode,
					null,
					getPrintData.voidQuantity,
					getPrintData.reasonCode,
					getPrintData.remark,

					null,
					null,
					null,
					null,
					null,
					getUsersByAccountAndSoftware,
					null,
					null,
					null,
				);

				if (
					printerName?.connectType === KitchenConnectType.Usb &&
					print === true
				) {
					if (!connectedPrinter) {
						console.error(
							'No printer connected. Please connect a printer first.',
						);
						return;
					}

					console.log('Printer connected, starting receipt print process...');

					setLoading(true);

					try {
						console.log('Calling printReceipt...');
						await printKitchenReceiptUSBPOS(
							connectedPrinter,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							null,
							printerName?.name,
							'server',
							null,
							'order-receipt',
							null,
							getPrintData.outletName,
							getOrder[0],
							getOrder[0]?.table,
							obj,
							getPrintData.orderMode,
							null,
							getPrintData.voidQuantity,
							getPrintData.reasonCode,
							getPrintData.remark,
							null,
							null,
							null,
							null,
							null,
							getUsersByAccountAndSoftware,
							null,
							null,
							null,
							printerName?.deviceAlignmentCode,
							null,
						);
						console.log('Receipt printed and paper cut successfully!');
						console.log(
							'%cReceipt printed successfully!',
							'color: #12850b;',
							JSON.stringify(
								{
									productName: JSON.parse(
										localStorage.getItem('connectedPrinter'),
									)?.productName,
									productId: JSON.parse(
										localStorage.getItem('connectedPrinter'),
									)?.productId,
									vendorId: JSON.parse(localStorage.getItem('connectedPrinter'))
										?.vendorId,
								},
								null,
								'\t',
							),
						);
					} catch (error) {
						console.error('Failed to send data to the printer:', error);
						// alert('Failed to print receipt!');
					} finally {
						setLoading(false);
					}
				}
			});
		}
		return true;
	};

	useEffect(() => {
		if (uniqueKpArray?.length > 0 && getOrder?.length > 0) {
			// printReceipt(getPrintData.dataURL, getPrintData.printerName, 'server');
			handlePrinting();
			setPrint(false);
		}
	}, [uniqueKpArray, getOrder, connectedPrinter]);

	console?.log(uniqueKpArray, uniqueKpArrayInfo, 'uniqueKpArray');

	return (
		<>
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Print Server"
				title={getOutlet[0]?.name}
				routeSegments={[{ name: 'Server', current: true }]}
			/>

			<ContentWrapper float>
				<EmptyList title="Listening to print requests..." />
			</ContentWrapper>
		</>
	);
};
